import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { PageContainer } from "src/components";
import TablePage from "./TablePage";
import getDonationList from "./hooks/getDonationList";
import useDonations from "./hooks/useDonations";
import FormPage from "./FormPage";
import { DEFAULT_FORM_STATE, USER_GROUP, API_ERROR_FIELD, ERROR_MESSAGES } from "src/constants/donations";
import { Toast } from "src/components";
import DeleteDialog from "./components/DeleteDialog";

const Donation = ({ user }) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [formData, setFormData] = useState(DEFAULT_FORM_STATE);
    const [updatingUuid, setUpdatingUuid] = useState(null);
    const { donationList, setDonationList, isFetchingDonationList, fetchDonationList } = getDonationList(user.token);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const { saveDonationRequest, requestS3PresignedUrl, uploadToS3, deleteMultipleDonation } = useDonations(user.token);
    const [formErrors, setFormErrors] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [allDonationsChecked, setAllDonationsChecked] = useState(false);
    const [checkedDonations, setCheckedDonations] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    const [selectedImage, setSelectedImage] = useState({
        readerResult: null,
        hasBeenUploaded: false,
        s3Url: null
    });

    const handleFormVisible = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            return { 
                ...prevFormData, 
                [name]: value 
            };
        });
    };

    const handleChangeUserGroup = value => {
        setFormData({
            ...formData,
            userGroupType: value
        });
    };

    const userGroupTypeValue = donation => {
        if (donation.displayToEmployees && donation.displayToClients) {
            return USER_GROUP.BOTH;
        } else if (donation.displayToEmployees) {
            return USER_GROUP.EMPLOYEES;
        } else if (donation.displayToClients) {
            return USER_GROUP.CLIENTS;
        }
    };
    
    const handleEditDonation = donation => {
        const userType = userGroupTypeValue(donation);

        setSelectedImage({ //reset to default
            readerResult: null,
            hasBeenUploaded: false,
            s3Url: null
        });

        setFormData({
            imageUrl: donation.image.imageUrl,
            userGroupType: userType,
            organisationName: donation.organisationName,
            headline: donation.headline,
            description: donation.description,
            donationPageUrl: donation.donationPageUrl,
        });
        setFormErrors([]);
        setUpdatingUuid(donation.uuid);
        setIsFormVisible(true);
    };

    const handleCreateDonation = () => {
        setFormData(DEFAULT_FORM_STATE);
        setSelectedImage({ //reset to default
            readerResult: null,
            hasBeenUploaded: false,
            s3Url: null
        });
        setFormErrors([]);
        setUpdatingUuid(null);
        setIsFormVisible(true);
    };

    const dataURLtoFile = (dataUrl, filename) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
  
    const handleFormSubmit = async () => {
        try {
            setFormErrors([]);
            setIsFormSubmitting(true);
            
            if (hasFormErrors(formData)) {
                return;
            }

            await handleCreateOrUpdate();
        } catch (e) {
            console.warn("An error occured while submitting the form: ", e);
            Toast.error(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const hasFormErrors = (formData) => {
        let newErrors = [];

        if (!formData.userGroupType) {
            const err = { input: API_ERROR_FIELD.USER_GROUP_TYPE, message: ERROR_MESSAGES[API_ERROR_FIELD.USER_GROUP_TYPE] };
            newErrors = [...newErrors, err];
        }

        if (!formData.organisationName) {
            const err = { input: API_ERROR_FIELD.ORGANISATION_NAME, message: ERROR_MESSAGES[API_ERROR_FIELD.ORGANISATION_NAME] };
            newErrors = [...newErrors, err];
        }

        if (!formData.headline) {
            const err = { input: API_ERROR_FIELD.HEADLINE, message: ERROR_MESSAGES[API_ERROR_FIELD.HEADLINE] };
            newErrors = [...newErrors, err];
        }

        if (!formData.description) {
            const err = { input: API_ERROR_FIELD.DESCRIPTION, message: ERROR_MESSAGES[API_ERROR_FIELD.DESCRIPTION] };
            newErrors = [...newErrors, err];
        }

        if (!formData.donationPageUrl) {
            const err = { input: API_ERROR_FIELD.PAGE_URL, message: ERROR_MESSAGES[API_ERROR_FIELD.PAGE_URL] };
            newErrors = [...newErrors, err];
        }

        //validate image
        if (!formData.imageUrl && !selectedImage.readerResult) {
            newErrors = [...newErrors, API_ERROR_FIELD.IMAGE];
            const err = { input: API_ERROR_FIELD.IMAGE, message: ERROR_MESSAGES[API_ERROR_FIELD.IMAGE] };
            newErrors = [...newErrors, err];
        }

        setFormErrors(newErrors);
        return newErrors.length > 0;
    };

    const handleCreateOrUpdate = async () => {

        async function processImageUpload(selectedImage) {
            const imageFile = dataURLtoFile(selectedImage.readerResult, "donation-organisation-image");
            const imageFileType = imageFile.type.replace("image/", "");
            const donationImagePresignedUrl = await requestS3PresignedUrl(imageFileType);
            
            const uploadedS3Url = await uploadToS3(donationImagePresignedUrl, imageFile);

            setSelectedImage(prev => {
                return { 
                    ...prev, 
                    hasBeenUploaded: true,
                    s3Url: uploadedS3Url
                };
            });

            return uploadedS3Url;
        }

        let requestData = formData;
        if (selectedImage.readerResult) {
            let uploadedS3Url = selectedImage.s3Url;
            if (!selectedImage.hasBeenUploaded) {
                uploadedS3Url = await processImageUpload(selectedImage);
            }

            requestData = { //can't use setFormData for imageUrl, having issue from race-condition
                ...formData, 
                imageUrl: uploadedS3Url
            };
        }

        try {
            const responseData = await saveDonationRequest(requestData, updatingUuid);
            if (updatingUuid) {
                setDonationList(prevDonations => {
                    const updatedDonations = prevDonations.map(donation => {
                        if (donation.uuid === updatingUuid) {
                            return responseData;
                        } else {
                            return donation;
                        }
                    });
                    return updatedDonations;
                });
            } else {
                setDonationList(prevDonations => [...prevDonations, responseData]);
            }

            setUpdatingUuid(null);
            setFormData(DEFAULT_FORM_STATE);
            setIsFormVisible(false);
            const action = updatingUuid ? "updated" : "created";
            Toast.success(`Donation Organisation has been successfully ${action}.`);
        } catch (errors) {
            if (typeof errors === "string") {
                throw errors; //rethrow;
            }

            let { fields, messages } = errors;
            let formErrors = [];
            for (let i = 0; i < messages.length; i++) {
                formErrors = [...formErrors, {
                    input: fields[i],
                    message: messages[i]
                }];
            }
            setFormErrors(formErrors);
        }
    };

    const handleCheckAllDonations = () => {
        const checked = !allDonationsChecked;
        setAllDonationsChecked(checked);
        const checkedDonations = checked ? donationList.map(donationList => donationList.uuid) : [];
        setCheckedDonations(checkedDonations);
    };

    const handleCheckDonation = (e, donationUuid) => {
        const exist = checkedDonations.findLast(uuid => uuid === donationUuid);
        
        setCheckedDonations(() => {
            if (!exist) {
                const newDonationList = [...checkedDonations, donationUuid];
                newDonationList.length === donationList.length && setAllDonationsChecked(true);
                return newDonationList;
            } else {
                const filtered = checkedDonations.filter(uuid => uuid !== donationUuid);
                filtered.length !== donationList.length && setAllDonationsChecked(false);
                return filtered;
            }
        });

    };

    const handleShowDeleteDialog = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleMultipleDelete = async () => {
        try {
            setIsDeleting(true);
            const uuidsToDelete = checkedDonations;
            const successMessage = await deleteMultipleDonation(uuidsToDelete);
            Toast.success(successMessage);
            fetchDonationList();
        } catch (error) {
            Toast.error("Unable to delete the selected donations. Please try again later.");
        } finally {
            setAllDonationsChecked(false);
            setCheckedDonations([]);
            setIsDeleting(false);
            handleCloseDeleteDialog();
        }
    };
    
    return (
        <PageContainer>
            { !isFormVisible && (
                <TablePage
                    user={user}
                    donationList={donationList}
                    isFetchingDonationList={isFetchingDonationList}
                    handleFormVisible={handleFormVisible}
                    handleEditDonation={handleEditDonation}
                    handleCreateDonation={handleCreateDonation}
                    handleShowDeleteDialog={handleShowDeleteDialog}
                    handleCheckAllDonations={handleCheckAllDonations}
                    handleCheckDonation={handleCheckDonation}
                    allDonationsChecked={allDonationsChecked}
                    checkedDonations={checkedDonations}
                />
            )}
            { isFormVisible && (
                <FormPage
                    handleFormVisible={handleFormVisible}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleChangeUserGroup={handleChangeUserGroup}
                    handleFormSubmit={handleFormSubmit}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    updatingUuid={updatingUuid}
                    isFormSubmitting={isFormSubmitting}
                    formErrors={formErrors}
                />
            )}

            {showDeleteDialog && (
                <DeleteDialog
                    showDeleteDialog={showDeleteDialog}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                    isDeleting={isDeleting}
                    handleMultipleDelete={handleMultipleDelete}
                />
            )}
        </PageContainer>
    );
};

export default withRouter(Donation);