import React from "react";
import { useRoomTimeline } from "../../hooks";

const ChatRoomTimeline = (props) => {
    const { roomTimeline } = props;
    const { renderTimelineEvent } = useRoomTimeline(roomTimeline);

    const renderRoomTimeline = () => {
        try
        {
            return (
                roomTimeline.map((matrixEvent, index) => renderTimelineEvent(matrixEvent, index))
            );
        }
        catch (e) {
            console.warn("Unable to render the messages for this room", e);
        }
    };
    return (
        <React.Fragment>
            { roomTimeline && roomTimeline.length > 0 && renderRoomTimeline() }
        </React.Fragment>
    );
};

export default ChatRoomTimeline;