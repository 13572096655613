import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import Select from "react-select";
import {
    FlexContainer, FormField, ErrorText,
    Text, Form, Toast,
    PageContainer, PageHeadingLabel, Checkbox
} from "src/components";

import styled from "styled-components";

import { CancelButton, SaveButton,
    EditQuestionContainer, FormBackground, FormSectionHeader,
    LoadingIndicatorButton, LoadingIndicatorIcon
} from "../components/Utils";
import { ErrorStrings, ErrorList, surveyTypeOptions, surveyUserGroupOptions,
    surveyShowInOptions, surveyFrequencyOptions
} from "../components/Enums";
import SurveyDurationFormField from "../components/SurveyDurationInput";
import { customStyles, customDisabledStyles, IconOption, SingleValue } from "./components/DropdownCustomStyles";
import { SurveyStatusType } from "src/constants/survey";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const DisabledStyledCheckbox = styled.div`
  background-clip: content-box;
  background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
  border: ${p => p.border || "1px solid #2D3037"};
  box-sizing: border-box;
  height: ${p => p.height || "20px"};
  padding: ${p => p.padding || "5px"};
  border-radius: ${ p => p.radius || "0"};
  transition: all 150ms;
  width: ${p => p.width || "20px"};
  cursor: not-allowed;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

class EditForm extends Component {
    state = {
        fetching: 0,
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                0: { uuid: "", question: "" },
                1: {},
                2: {}
            },
            showIn: "",
            showFrequency: "",
            anonymousSurvey: false
        },
        currentSavedStartDate: "",
        message: "",
        inputErrors: [],
        setInputTypeAs: "text",
        loading: false
    };
    
    componentDidMount() {
        this.setState(state => ({
            fetching: state.fetching + 1
        }), () => this.safeToInit());
    }

    safeToInit = () => {
        this.populateSurvey();

        this.setState(state => ({
            fetching: state.fetching - 1
        }));
    };

    populateSurvey = () => {
        const { surveyUuid } = this.props;
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: { ...surveyResponse, anonymousSurvey: res.data.survey.isAnonymous },
                currentSavedStartDate: surveyResponse.startDate,
            }));
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    formSubmission = (hideForm) => {
        this.setState({
            loading: true,
            inputErrors: [],
        });

        const { surveyUuid } = this.props;
        const surveyData = this.state.survey;
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        let surveyQuestionItems = {};
        let questionOrder = 1;
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
            {
                surveyData.surveyQuestions[questionKey].questionOrder = questionOrder;
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
                questionOrder++;
            }
        });

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = surveyData.startDate ? formatter.format(new Date(surveyData.startDate)) : "";
        const formattedFinishDate = surveyData.finishDate ? formatter.format(new Date(surveyData.finishDate)) : "";

        let errors = this.getErrors();
        
        if (errors.length > 0) {
            this.setState({
                loading: false,
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }

        axios.put(`${API_URL}/survey/company/${surveyUuid}`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? formattedFinishDate + " 00:00:00" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency,
            isAnonymous: surveyData.anonymousSurvey
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const surveyResponse = res.data.survey;
            this.setState(prevState => ({
                survey: surveyResponse,
            }));
            hideForm();
            return Toast.info("Survey updated successfully");
        }).catch(error => {
            if (!error.response)
                return Toast.error(error.message);

            let errors = [];
            let dataErrorFields = error.response.data.error.fields;
            
            dataErrorFields.forEach(function(fieldKey) {
                switch (fieldKey) {
                case "startDateInvalid":
                    errors.push(ErrorList.DATE_START_DATE_INVALID);
                    return;
   
                case "finishDateInvalid":
                    errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                    return;

                case "startDateGreaterThanFinishDate":
                    errors.push(ErrorList.DATE_RANGE_INVALID);
                    return;

                case "surveyQuestionEmpty":
                    errors.push(ErrorList.EMPTY_QUESTION_ONE);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            this.setState({
                inputErrors: errors,
            });

            return Toast.error("Please make sure your entries are correct");
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };


    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        if (survey.name === "") {
            errors.push(ErrorList.EMPTY_SURVEY_NAME);
        }
    
        if (survey.startDate === "") {
            errors.push(ErrorList.EMPTY_START_DATE);
        }

        if (survey.finishDate === "") {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        }

        if (survey.surveyQuestions[0] === undefined || (survey.surveyQuestions[0] !== undefined && survey.surveyQuestions[0].question === "")) {
            errors.push(ErrorList.EMPTY_QUESTION_ONE);
        }

        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;
        
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (event) => {
        const startDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: startDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (event) => {
        const finishDate = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: finishDate // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionNumber]: {
                        ...prevState.survey.surveyQuestions[questionNumber],
                        question: questionString
                    }
                }        
            }
        }));
    }; 

    selectShowIn = (event) => {
        const showIn = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    setSurveyAnonymous = (event) => {
        this.setState(prevState => ({
            survey: {
                ...prevState.survey,
                anonymousSurvey: event
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };

    render() {
        const { hideEditForm } = this.props;
        const { fetching, inputErrors, survey, loading, setInputTypeAs } = this.state;

        if (fetching > 0) {
            return (
                <PageContainer>
                    <PageHeadingLabel color="#23262D" size="40px" weight="500">
                        Edit Survey
                    </PageHeadingLabel>
                    <div className="row" style={{ maxWidth: "43.125rem" }}>
                        <div className="description" style={{ marginBottom: "1em" }}>
                            Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability
                            to create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards<br/>
                            employees, clients, or both, ensuring you reach the right audience every time.
                        </div>
                    </div>
                    <LoadingIndicatorIcon />
                </PageContainer>
            );
        }
        
        return (
            <PageContainer>
                <PageHeadingLabel color="#23262D" size="40px" weight="500">
                    Edit Survey
                </PageHeadingLabel>
                <div className="row" style={{ maxWidth: "43.125rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability
                        to create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards<br/>
                        employees, clients, or both, ensuring you reach the right audience every time.
                    </div>
                </div>

                <FormBackground>
                    <Form width="inherit" paddingBottom="0" onSubmit={(e) => {e.preventDefault();}} style={{ padding: "2.5rem 0" }} >
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                            <div style={{ width: "45%" }}>
                                <FormSectionHeader style={{ fontWeight: "600" }}>
                                    Survey Information
                                </FormSectionHeader>
                                <SurveyNameField survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} />
                                <TypeOfSurveyField survey={survey} inputErrors={inputErrors} />
                                <PageSurveyDuration survey={survey} inputStartDate={this.inputStartDate}
                                    inputFinishDate={this.inputFinishDate}
                                    inputErrors={inputErrors}
                                    setInputTypeAs={setInputTypeAs}
                                    currentSavedStartDate={this.state.currentSavedStartDate}
                                    changeScheduleStartDateSetInputTypeAs={this.changeScheduleStartDateSetInputTypeAs}
                                    changeScheduleFinishDateSetInputTypeAs={this.changeScheduleFinishDateSetInputTypeAs} /> 
                                <UserGroupField survey={survey} inputErrors={inputErrors} />
                                <PageSurveyFrequency survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} /> 
                            </div>
                            <div style={{ width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <FormSectionHeader style={{ fontWeight: "600" }}>
                                        Survey Questions
                                    </FormSectionHeader>
                                    <PageSurveyQuestions survey={survey} inputQuestion={this.inputQuestion} inputErrors={inputErrors} />
                                </div>
                                <div style={{ margin: "0rem 0rem 2.5rem 0rem" }}>
                                    <SurveySubmissionAnonymousField isSurveyAnonymous={this.state.survey.anonymousSurvey} surveyStatus={survey.status} setSurveyAnonymous={this.setSurveyAnonymous} />
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "50px 3.75rem 22px 3.75rem" }}>
                            <hr/>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                            {
                                loading ? 
                                    <LoadingIndicatorButton height="38px" style={{ marginLeft: "auto" }}>
                                        <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                    </LoadingIndicatorButton>
                                    :
                                    <>
                                        {/* <RemoveButton backgroundColor="#ff6464" onClick={(e) => { this.confirmDelete(surveyUuid); } }>
                                            <span>Permanently Remove Survey </span>
                                        </RemoveButton> */}
                                        <CancelButton style={{ marginLeft: "auto" }} borderRadius="7px" onClick={(e) => {hideEditForm();}}>Cancel</CancelButton>
                                        <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => {this.formSubmission(hideEditForm);}}>Save</SaveButton>
                                    </>
                            }
                        </div>
                    </Form>
                </FormBackground>
                
            </PageContainer>
        );
    }
}

class SurveyNameField extends Component {
    render() {
        const { survey, inputSurveyName, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text align="left" size="16px" weight="bold">
                    Change Survey Name
                </Text>
                <FormField borderRadius="12px" size="16px" height="50px"
                    placeholderColor="#000000" border="1px solid #E9EBEF"
                    align="left"
                    width="100%"
                    type="text"
                    placeholder="Enter your survey name here"
                    onChange={(event) => {inputSurveyName(event);} }
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                >
                </FormField>
            </>
        );
    }
}

class PageSurveyDuration extends Component {
    state = {
        startDate: this.props.survey.startDate ? new Date(this.props.survey.startDate) : new Date(),
        finishDate: this.props.survey.finishDate ? new Date(this.props.survey.finishDate) : new Date(),
    };

    setStartDate = (date) => {
        this.setState({
            startDate: date
        });
    };

    setFinishDate = (date) => {
        this.setState({
            finishDate: date
        });
    };

    checkDateIsFuture = (date) => {
        const compareDate = new Date(date);
        const dateToday = new Date;
        if (compareDate > dateToday) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { inputErrors, currentSavedStartDate } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);
        
        const currentStartDate = currentSavedStartDate;

        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

        const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

        return (
            <>
                <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }} >                    
                    <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                        <Text color="#000" align="left" size="16px" weight="bold" margin="16px 0 16px 0">
                            { this.checkDateIsFuture(currentStartDate) ? "Change " : "" }Start Date
                        </Text>
                        <SurveyDurationFormField 
                            placeholder="Choose date..."
                            onChange={(event) => {this.setFinishDate(event);} }
                            errors={surveyStartDateError}
                            value={this.state.startDate}
                            disabled={true}
                            showErrors={surveyStartDateError.length > 0}
                        >
                        </SurveyDurationFormField>
                    </div>

                    <div style={{ textAlign: "center", marginRight: "0px", flex: "1 1 45%" }}>
                        <Text color="#000" align="left" size="16px" weight="bold" margin="16px 0 16px 0">
                        Change Finish Date
                        </Text>
                        <SurveyDurationFormField 
                            placeholder="Choose date..."
                            onChange={(event) => {this.setFinishDate(event);} }
                            errors={surveyFinishDateError}
                            value={this.state.finishDate}
                            showErrors={surveyFinishDateError.length > 0}
                        >
                        </SurveyDurationFormField>
                    </div>
                </FlexContainer>

                { hasAnySurveyDurationErrors &&
                    <ErrorText>
                        { startDateInvalid.length > 0 ? startDateInvalid : null }
                        { finishDateInvalid.length > 0 ? finishDateInvalid : null }
                        { dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null }
                        { dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null }
                        { dateRangeInvalid.length > 0 ? dateRangeInvalid : null }
                    </ErrorText>
                }
            </>
        );
    }
}

class PageSurveyQuestions extends Component {
    render() {
        const { inputQuestion, inputErrors, survey } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);   

        let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
        let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
        let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

        let surveyQuestionOne = (survey.surveyQuestions[0]) ? survey.surveyQuestions[0].question : "";
        let surveyQuestionTwo = (survey.surveyQuestions[1]) ? survey.surveyQuestions[1].question : "";
        let surveyQuestionThree = (survey.surveyQuestions[2]) ? survey.surveyQuestions[2].question : "";
        return (
            <div style={{ width: "90%" }}>
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={1} questionValue={surveyQuestionOne} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} />
                        </div>
                    </div>
                }
           
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={2} questionValue={surveyQuestionTwo} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} />
                        </div>
                    </div>
                }
          
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer questionNumber={3} questionValue={surveyQuestionThree} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class PageSurveyFrequency extends Component {
    render() {
        const { survey, inputErrors, selectShowIn } = this.props;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (

            <>
                <FlexContainer>
                    <Text color="#000" align="left" margin="16px 0 16px 0" size="16px" weight="500" >
                    Show Survey In:
                    </Text>

                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        value={ surveyShowInOptions.find(obj => obj.value == survey.showIn) }
                        components={{ SingleValue: SingleValue }}
                    />

                    <ErrorText>
                        {showInError}
                    </ErrorText>

                    <Text color="#000" align="left" margin="16px 0 16px 0" size="16px" weight="500">
                    Select Survey Frequency?
                    </Text>
                
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        value={ surveyFrequencyOptions.find(obj => obj.value == survey.showFrequency) }
                        components={{ SingleValue: SingleValue }}
                    />
                
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>

                    <br/>
               
                </FlexContainer>
            </>
        );
    }
}

class TypeOfSurveyField extends Component {
    render() {
        const { inputErrors, survey } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Survey Type
                </Text>
                <Select
                    isDisabled={true}
                    value={ surveyTypeOptions.find(obj => obj.value === survey.surveyType) }
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    styles={customDisabledStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />
                { surveyTypeError.length > 0 &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {surveyTypeError}
                    </ErrorText>
                }
            </>
        );
    }
}

class UserGroupField extends Component {
    render() {
        const { survey, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);

        const hasAnyUserGroupErrors = userGroupError.length > 0;
        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Select User Group
                </Text>

                <Select
                    isDisabled={true}
                    value={ surveyUserGroupOptions.find(obj => obj.value == survey.userGroup) }
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Select a user group"
                    options={ surveyUserGroupOptions }
                    styles={customDisabledStyles}
                />

                { hasAnyUserGroupErrors &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {userGroupError.length > 0 ? userGroupError : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveySubmissionAnonymousField extends Component {
    render() {
        const { isSurveyAnonymous, surveyStatus, setSurveyAnonymous } = this.props;
        return (
            <>
                <Text align="left" size="16px" weight="500" color={surveyStatus === SurveyStatusType.SCHEDULED ? "#000" : "#8291B2"} >
                    Make Survey Submission Anonymous (Optional)
                </Text>
                <Text align="left" size="14px" color={surveyStatus === SurveyStatusType.SCHEDULED ? "#000" : "#8291B2"} maxWidth="540px" wordBreak="break-word">
                    By checking this box, all survey responses will be collected anonymously,
                    allowing users to share their thoughts freely without any personal identifiers.
                    Once the survey becomes active, this setting cannot be changed,
                    so make sure to decide before proceeding to promote candid and unbiased feedback.
                </Text>
                <CheckboxContainer>
                    <label>
                        { surveyStatus !== SurveyStatusType.SCHEDULED &&
                            <DisabledStyledCheckbox
                                border="1px solid #8291B2"
                                color={isSurveyAnonymous === "1" ? "#8291B2" : "#36be24" }
                                height="32px"
                                width="32px"
                                padding="7px"
                                radius="10px"
                                value={true}
                                checked={isSurveyAnonymous === "1"}
                            />
                        }
                        { surveyStatus === SurveyStatusType.SCHEDULED &&
                            <Checkbox
                                color="#36be24"
                                height="32px"
                                width="32px"
                                padding="7px"
                                radius="10px"
                                value={true}
                                checked={isSurveyAnonymous}
                                onChange={ (e) => setSurveyAnonymous(e.target.checked) }
                            />
                            // <StyledCheckbox
                            //     border="1px solid #8291B2"
                            //     color={isSurveyAnonymous === "1" ? "#8291B2" : "#36be24" }
                            //     height="32px"
                            //     width="32px"
                            //     padding="7px"
                            //     radius="10px"
                            //     value={true}
                            //     checked={isSurveyAnonymous === "1"}
                            // />
                        }
                    </label>
                </CheckboxContainer>
            </>
        );
    }
}

export default EditForm;