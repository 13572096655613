import React from "react";
import { components } from "react-select";
import { surveyTypeOptions } from "../../components/Enums";
const { Option } = components;

export const customStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "15px",
        fontWeight: "300 !important",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        overflow: "visible",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "15px",
        fontWeight: "300 !important",
        justifyContent: "center",
        verticalAlign: "middle",
        color: "#808080",
        overflow: "visible",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        marginTop: "0.5rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        overflow: "visible",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
        overflow: "visible",
    }),
    valueContainer: (base) => ({
        ...base,
        overflow: "visible",
        marginLeft: "10px",
    })
};

export const surveyUserSubmissionsModalStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "7px",
        border: "1px solid #000000",
        boxShadow: "0px 3px 6px #00000029",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "15px",
        fontWeight: "700 !important",
        fontFamily: "Roboto, Helvetica, sans-serif",
        appearance: "none",
        overflow: "visible",
        placeholderAlignment: "middle",
        "&:hover": {
            outline: "none"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "15px",
        fontWeight: "700 !important",
        justifyContent: "center",
        verticalAlign: "middle",
        color: "#808080",
        overflow: "visible",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "7px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        overflow: "visible",
        "&hover": {
            borderRadius: "7px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "7px",
        overflow: "visible",
    }),
    valueContainer: (base) => ({
        ...base,
        overflow: "visible",
        marginLeft: "10px",
    })
};

export const customDisabledStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "12px",
        border: "1px solid #E9EBEF",
        textDecoration: "none",
        color: "white",
        height: "50px",
        background: "#F0F0F0 0% 0% no-repeat padding-box",
        fontSize: "16px",
        fontFamily: "Roboto, Helvetica, sans-serif",
        fontWeight: "300 !important",
        appearance: "none",
        placeholderAlignment: "middle",
        "&:hover": {
            borderColor: "#E9EBEF",
            outline: "#E9EBEF",
            boxShadow: "0 0 1px #E9EBEF"
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "16px",
        fontWeight: "300 !important",
        justifyContent: "center",
        verticalAlign: "middle"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    menu: (provided) => ({
        marginTop: "1rem",
        borderRadius: "12px",
        border: ".5px solid black",
        fontSize: "16px",
        backgroundColor: "white",
        position: "absolute",
        zIndex: "10",
        width: "100%",
        "&hover": {
            borderRadius: "12px"
        }
    }),
    option: (base) => ({
        ...base,
        borderRadius: "12px",
    }),
    singleValue: (base) => ({
        color: "#000",
        paddingLeft: "10px",
    })
};

export const IconOption = (props) => {
    const labelIsYesNo = (props.data.value === (surveyTypeOptions.find((i) => i.value === "Yes/No").value));
    return (
        <Option { ...props }>
            { props.data.label }
            { (props.data.icon || labelIsYesNo) &&
                <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle", marginRight: "10px", marginLeft: "17px" }}>
                    { labelIsYesNo ?
                        <div style={{ fontFamily: "Roboto", fontSize: "18px", fontStyle: "500" }}>
                            Yes/No
                        </div> :
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25 }}
                            alt={ props.data.label }
                        />
                    }
                    { (props.data.value === "Rating" || props.data.value === "Satisfaction") &&
                    <>
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                    </>
                    }
                </div>
            }
        </Option>
    );
};

export const SingleValue = ({ children, ...props }) => {
    const labelIsYesNo = (props.data.value === (surveyTypeOptions.find((i) => i.value === "Yes/No").value));
    return (
        <components.SingleValue {...props}>
            { props.data.label }
            { (props.data.icon || labelIsYesNo) &&
                <div style={{ display: "inline-flex", justifyContent: "center", verticalAlign: "middle", height: "100%", marginLeft: "17px" }}>
                    { labelIsYesNo ?
                        <div style={{ fontFamily: "Roboto", fontSize: "18px", fontStyle: "500" }}>
                            Yes/No
                        </div> :
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25 }}
                            alt={ props.data.label }
                        />
                    }
                    { (props.data.value === "Rating" || props.data.value === "Satisfaction") &&
                    <>
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                        <img 
                            src={ props.data.icon }
                            style={{ height: 25, marginLeft: "5px" }}
                            alt={ props.data.label }
                        />
                    </>
                    }
                </div>
            }
        </components.SingleValue>
    );
};