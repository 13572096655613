import React, { useState } from "react";
import { useRef } from "react";
import { Text } from "src/components";

const ImageUpload = (props) => {
    const { setFormImage, setImageChanged } = props;
    const [image, setImage] = useState(props.image ? props.image : null);
    const fileInputRef = useRef(null);

    const handleFiles = (files) => {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    setImage(reader.result);
                    setFormImage(reader.result);
                    setImageChanged(true);
                } else {
                    alert("Invalid file type. Please select an image or video.");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleInputChange = (event) => {
        handleFiles(event.target.files);
    };

    const removeImage = () => {
        setImage(null);
    };

    return (
        <>
            <Text size="16px" align="left" weight="700">
                Upload Poll Image (Optional)
            </Text>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "30px" }}>
                {image ? (
                    <div
                        style={{
                            width: "275px",
                            height: "155px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E0E0E0",
                            borderRadius: "36px",
                            backgroundColor: "#E8E8E8",
                            cursor: "pointer",
                        }}
                        onClick={handleDropzoneClick}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleInputChange}
                            style={{ display: "none" }}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <img
                            src={image}
                            alt="Preview"
                            style={{
                                width: "275px",
                                height: "155px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #E0E0E0",
                                borderRadius: "36px",
                                backgroundColor: "#E8E8E8",
                                cursor: "pointer",
                                objectFit: "cover",
                                objectPosition: "center"
                            }}
                        />

                    </div>
                ) : (
                    <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        style={{
                            width: "275px",
                            height: "155px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E0E0E0",
                            borderRadius: "36px",
                            backgroundColor: "#E8E8E8",
                            cursor: "pointer",
                        }}
                        onClick={handleDropzoneClick}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleInputChange}
                            style={{ display: "none" }}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                            <p style={{ fontSize: "28px", color: "#888", height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "white", textAlign: "center", boxShadow: "0px 3px 6px #00000029" }}>
                                +
                            </p>
                        </label>
                    </div>
                )}
                {image &&
                    <button
                        onClick={removeImage}
                        style={{
                            background: "white",
                            border: "1px solid #E0E0E0",
                            borderRadius: "14px",
                            padding: "5px",
                            cursor: "pointer",
                            boxShadow: "0px 5px 5px #0000001A",
                            width: "139px",
                            height: "40px",
                            font: "normal normal medium 14px/19px Roboto",
                            fontSize: "500",
                            letterSpacing: "0px",
                            marginLeft: "20px"
                        }}
                    >
                        Remove Image
                    </button>
                }
            </div>
        </>
    );
};

export default ImageUpload;
