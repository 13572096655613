import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const getDonationClickVisitors = (token, donation) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const [donationClickVisitors, setDonationClickVisitors] = useState([]);
    const [isFetchingVisitors, setIsFetchingVisitors] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setIsFetchingVisitors(true);
            try {
                const config = {
                    signal: controller.signal,
                    headers: {
                        Authorization: "Bearer " + token
                    }
                };

                const response = await axios.get(`${API_URL}/donation/company/${donation.uuid}/visitors`, config);
                
                if (isMountedRef.current) {
                    setDonationClickVisitors(response.data.visitors);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    Toast.error("Unable to fetch donations. Please try again later or contact support if the issue persists.");
                }
            } finally {
                if (isMountedRef.current) {
                    setIsFetchingVisitors(false);
                }
            }
        };

        fetch();

        return () => {
            controller.abort();
            isMountedRef.current = false;
        };

    }, [token]);

    return { donationClickVisitors, isFetchingVisitors };
};

export default getDonationClickVisitors;