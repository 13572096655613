import { Text } from "src/components";
import styled from "styled-components";

export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

export const MainContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 1.5rem 5% 0 5%;

    background: #FFF;
    width: 100%;
    
    flex-direction: row;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        background: #F9FAFC;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }
`;

export const LeftContainer = styled.div`
    width: 23.375rem;
    border: 1px solid #DBE5ED;
    border-radius: 20px;
    overflow: hidden;
`;

export const RightContainer = styled.div`
    flex: 1;
    border: 1px solid #DBE5ED;
    border-radius: 20px;
    overflow: hidden;
`;