import React from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { Box, Dialog } from "@material-ui/core/";
import { useState } from "react";
import { Password, NewPassword, ConfirmNewPassword, CancelButton, SaveButton, ErrorList, ErrorStrings } from "./ChangePasswordFields";
import {
    Toast, Text
} from "src/components";
const ChangePasswordForm = (props) => {
    const { showDialog, handleClose, token, isAccountOwner } = props;
    const [inputErrors, setInputErrors] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    const handleInputChange = (event, key) => {
        const updatedFormData = { ...formData, [key]: filterInputValue(event.target.value) };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = () => {
        if (formLoading) {
            return;
        }

        setInputErrors([]);
        const errors = getErrors();

        if (errors.length > 0) {
            setInputErrors(errors);
            setFormLoading(false);
            return;
        }

        savePassword(formData, setFormLoading, setInputErrors);
    };

    const savePassword = async (formData, setSavePasswordLoading, setInputErrors) => {
        setSavePasswordLoading(true);
        
        const route = isAccountOwner ? "changeAccountPassword" : "changePassword";
        axios.post(`${API_URL}/company/${route}`, formData, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            setSavePasswordLoading(false);
            handleClose(false);
            return Toast.info("Your password was updated.");
        }).catch(error => {
            if (error.message) {
                Toast.error(error.message.toString());
                return;
            }

            let errors = [];
            let dataErrorFields = error;
            
            Object.keys(dataErrorFields).forEach(fieldKey => {
                const responseMessage = "* " + dataErrorFields[fieldKey];
                switch (fieldKey) {
                case "passwordInvalid":
                    ErrorStrings[ErrorList.PASSWORD_INVALID] = responseMessage;
                    errors.push(ErrorList.PASSWORD_INVALID);
                    return;

                case "newPasswordInvalid":
                    ErrorStrings[ErrorList.NEW_PASSWORD_INVALID] = responseMessage;
                    errors.push(ErrorList.NEW_PASSWORD_INVALID);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            setInputErrors(errors);
            setSavePasswordLoading(false);
        });
    };

    // prevents console warns when one of the input values are null
    const filterInputValue = (value) => [null].includes(value) ? "" : value;

    const getErrors = () => {
        let errors = [];
        
        if (formData.oldPassword === "" || formData.oldPassword === null) {
            errors.push(ErrorList.EMPTY_PASSWORD);
        }

        if (formData.newPassword === "" || formData.newPassword === null) {
            errors.push(ErrorList.EMPTY_NEW_PASSWORD);
        } else {
            //confirm password
            if (formData.confirmNewPassword === "" || formData.confirmNewPassword === null) {
                errors.push(ErrorList.EMPTY_CONFIRM_NEW_PASSWORD);
            } else if (formData.newPassword !== formData.confirmNewPassword) {
                errors.push(ErrorList.CONFIRM_NEW_PASSWORD_MISMATCHED);
            }
        }

        return errors;
    };
    
    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={() => handleClose(false) }
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 900,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto"
                    },
                }}
            >
                <Box sx={{ margin: "40px 55px 20px 55px" }}>
                    <Text color="#11141A" size="25px" align="left" weight="700" margin="0">
                        Change Password
                    </Text>
                    <div style={{ marginBottom: "1.5rem" }}></div>
                </Box>

                <Box sx={{ margin: "25px 55px 30px 55px" }}>
                    <Password placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                    <NewPassword placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                    <ConfirmNewPassword placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                
                </Box>

                <Box sx={{ margin: "40px 55px 40px 55px" }} >
                    <div style={{ textAlign: "right" }}>
                        <CancelButton onClick={ (e) => {handleClose(false); } }>Cancel</CancelButton>
                        <SaveButton disabled={formLoading} onClick={ (e) => handleFormSubmit(e) }>{formLoading ? "Saving..." : "Save"}</SaveButton>
                    </div>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}; 

export default ChangePasswordForm;