import styled from "styled-components";
import SearchIcon from "src/img/new/search-gray.svg";
import { Text } from "src/components";


export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

export const Container = styled.div`
    height: calc(100vh - 135px);
    display: flex;
    flex-direction: column;
    flex-grow: 0;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        background: #F9FAFC;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }
`;

export const StyledInput = styled.input`
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: right 1rem center;
    border: 1px solid #DBE5ED;
    height: 2.5rem;
    font-family: SofiaPro;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    margin: 1.4375rem 0 0.8rem 0;    
    background-color: #F9FAFC;
    outline: none;
    width: 100%;
    text-align: left;
    padding: 0 1.25rem;
    max-width: 21.5rem !important;
    box-sizing: border-box;

    ::placeholder {
        color: #AFBBC6;
        text-align: left;
        font-family: Roboto;
        font-weight: 700;
        font-size: 0.875rem;
    }
`;

export const ChatListScrollableContainer = styled.ul`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;
    height: auto;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        background: #F9FAFC;
        margin-left: 1.875rem;
        margin-top: 0.3125rem;
        margin-bottom: 12px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
        
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }
`;

export const CompanyLogoContainer = styled.div`
    width: 100%;
    height: 5.125rem;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: ${p => p.backgroundColor || "#F4F5F8"};
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #DBE5ED;
`;

export const CompanyLogo = styled.img`
    height: 3.75rem;
    width: 9rem;
    object-fit: contain;
`;

export const Icon = styled.img`
    height: ${p => p.height};
    width: ${p => p.width};
    color: ${p => p.color};
    cursor: pointer;
`;

export const CreateChatButtons = styled.button`
    background-color: ${p => p.isAllowed ? "#006CFF" : "#DBE5ED"};
    height: 2.5rem;
    width: 2.6875rem !important;
    border-radius: 0.5rem !important;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    cursor: pointer;
    pointer-events: ${p => p.isAllowed ? "auto" : "none"};
    margin-left: 0.6rem;
`;
