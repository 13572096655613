import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const getPerkInsights = (token, perkUuid) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const [perkInsights, setPerkInsights] = useState([]);
    const [isFetchingInsights, setIsFetchingInsights] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setIsFetchingInsights(true);

            try {
                const config = {
                    signal: controller.signal,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${API_URL}/perks/${perkUuid}/insights`, config);

                if (isMountedRef.current) {
                    setPerkInsights(response.data.recipientsAndClicks);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    Toast.error("Unable to fetch donations. Please try again later or contact support if the issue persists.");
                }
            } finally {
                if (isMountedRef.current) {
                    setIsFetchingInsights(false);
                }
            }
        };

        fetch();

        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, [token]);

    return { perkInsights, isFetchingInsights };
};

export default getPerkInsights;