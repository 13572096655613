import React, { useRef, useState } from "react";
import { 
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    styled,
} from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { API_URL } from "src/scenes/App";

const StyledDropDownButton = styled(Button)({
    color: "white",
    backgroundColor: "black",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "Roboto, Helvetica, sans-serif",
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: "#000f",
    },
    "& .MuiButton-label": {
        transform: "scaleX(1.5)",
    },
});

export default function StatisticsDownloadButton(props) {
    const { fileName, dateRange, type, user, isDataLoading } = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [isCSVDownloading, setIsCSVDownloading] = useState(false);
    const [isPDFDownloading, setIsPDFDownloading] = useState(false);
    
    const printDocument = (fileName, dateRange) => {
        setIsPDFDownloading(true);
        const input = document.getElementById("divToPrint");
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                const pdf = new jsPDF();
                
                const a4Width = pdf.internal.pageSize.getWidth();
                const a4Height = pdf.internal.pageSize.getHeight();
        
                const canvasWidth = canvas.width;
                const canvasHeight = canvas.height;
        
                const scaleFactor = Math.min(a4Width / canvasWidth, a4Height / canvasHeight);
        
                const imgWidth = canvasWidth * scaleFactor;
                const imgHeight = canvasHeight * scaleFactor;

                const xOffset = 0;
                const yOffset = 0;

                pdf.addImage(imgData, "JPEG", xOffset, yOffset, imgWidth, imgHeight);
                const formatText = (text) => text.replace(/ /g, "");

                const formattedFileName = formatText(fileName);
                const formattedDateRange = formatText(dateRange);
                
                const [year, month, day, hours, minutes, seconds] = new Date().toISOString()
                    .split(/[-T:.]/)
                    .slice(0, 6)
                    .map((val, index) => (index === 1 ? String(Number(val)).padStart(2, "0") : val));

                pdf.save(`${formattedFileName}-${formattedDateRange}-${year}${month}${day}-${hours}${minutes}${seconds}.pdf`);
                setIsPDFDownloading(false);
            });
    };

    const handleCSVDownload = async (fileName) => {
        setIsCSVDownloading(true);
        try {
            const result = await fetch(`${API_URL}/company/statisticsDownload?filter=${dateRange}&type=${type}`, {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            });
            const blob = await result.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.target = "_blank";
            link.download = `${fileName.replace(/ /g, "_")}_${dateRange.replace(/ /g, "_")}.csv`;
            console.log("link", link);
            document.body.appendChild(link);
            link.click();
            window.setTimeout(function() {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }, 0);
        } catch (error) {
            console.error("Error downloading the CSV:", error);
        } finally {
            setIsCSVDownloading(false);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            { !isDataLoading ?
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    style={{
                        borderRadius: "10px"
                    }}
                >
                    <Button onClick={(e) => {
                        e.preventDefault();
                        printDocument(fileName, dateRange);
                    }}
                    style={{
                        color: "white",
                        backgroundColor: "black",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        "&:hover": {
                            backgroundColor: "#000f",
                        },
                        fontSize: "14px",
                        fontWeight: "700",
                        fontFamily: "Roboto, Helvetica, sans-serif",
                        textTransform: "capitalize"
                    }}
                    disabled={isPDFDownloading}
                    >{isPDFDownloading ? "Downloading..." : "Download Report"}</Button>
                    <StyledDropDownButton
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                    ▼
                    </StyledDropDownButton>
                </ButtonGroup>
                :
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    style={{
                        borderRadius: "10px"
                    }}
                >
                    <Button
                        style={{
                            color: "white",
                            backgroundColor: "black",
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            "&:hover": {
                                backgroundColor: "#000f",
                            },
                            fontSize: "14px",
                            fontWeight: "700",
                            fontFamily: "Roboto, Helvetica, sans-serif",
                        }}
                        disabled={true}
                    >Generating Report...</Button>
                    <Button
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        disabled={true}
                        style={{
                            color: "white",
                            backgroundColor: "black",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            "&:hover": {
                                backgroundColor: "#000f",
                            },
                            fontSize: "14px",
                            fontWeight: "700",
                            fontFamily: "Roboto, Helvetica, sans-serif",
                        }}
                    >
                        <span style={{ transform: "scaleX(1.5)" }}>▼</span>
                    </Button>
                </ButtonGroup>
            }
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
               
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper style={{ color: "white", backgroundColor: "black", marginLeft: "0.5rem", width: "90%" }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem style={{ width: "100%", color: "white", backgroundColor: "black" }}>
                                    <MenuItem
                                        key={1}
                                        disabled={isCSVDownloading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleCSVDownload(fileName);
                                        }}
                                        style={{ width: "100%",
                                            color: "white",
                                            backgroundColor: "black",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            fontFamily: "Roboto, Helvetica, sans-serif"
                                        }}
                                    >
                                        {isCSVDownloading ? "Downloading..." : "Download as .csv"}
                                    </MenuItem>
                                    <MenuItem
                                        key={2}
                                        disabled={isCSVDownloading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            printDocument(fileName, dateRange);
                                        }}
                                        style={{ width: "100%",
                                            color: "white",
                                            backgroundColor: "black", 
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            fontFamily: "Roboto, Helvetica, sans-serif"
                                        }}
                                    >
                                        {isPDFDownloading ? "Downloading..." : "Download as .pdf"}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
