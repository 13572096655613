import { EventType } from "matrix-js-sdk";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";

export const generateNameInitials = (firstName, lastName) => {
    const firstNameInitial = firstName ? firstName[0].toUpperCase() : "" ;
    const lastNameInitial = lastName ? lastName[0].toUpperCase() : "" ;
    return firstNameInitial + lastNameInitial;
};
// TODO: update all other components doing capitalization
export const capitalizeString = (string) => {
    if (typeof string !== "string" || string.length === 0) {
        console.error("Invalid type or empty string passed to capitalizeString()");
        return string;
    }

    return string.length > 1 ? string.charAt(0).toUpperCase() + string.slice(1) : string.charAt(0).toUpperCase();
};

export const isPositiveNumber = (value) => {
    const num = Number(value);
    return !isNaN(num) && num >= 0;
};

export const isANonEmptyString = (string) => {
    return string && string.trim() !== "";
};

export const calculatePoints = (data) => {
    if (data.length === 0) {
        return data; // Return the same array if it's empty
    }
    // Find the highest count in the array
    let newData = data.sort(function(a, b) {
        const dateA = new Date(a["date"]);
        const dateB = new Date(b["date"]);
        return dateA - dateB;
    });
    const highestCount = newData.reduce((max, obj) => (obj.value > max ? obj.value : max), newData[0].value);
    // Calculate points for each object
    newData.map(item => {
        let row = item;
        if (row.value !== 0) {
            const percentage = (row.value / highestCount) * 100;
            // Map the percentage to a point between 1 and 10
            const point = (percentage / 10) + 0.1;
            row.point = point + 1;
            return row;
        } else {
            row.point = 1;
            return row;
        }
        // item.point = point;
    });

    return newData;
};

/**
 * find and get an object from an array of objects
 * @param {array} array
 * @param {string} propertyName
 * @param {*} value
 */
export const getObjectByPropertyValue = (arrayOfObjects, propertyName, value) => {
    return arrayOfObjects.find(obj => obj[propertyName] === value);
};

export const doesObjectPropertyExist = (object, propertyName) => {
    return Object.prototype.hasOwnProperty.call(object, propertyName);
};

export const isListTypeEmployeeOrClient = (type) => {
    return [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(type);
};


/**
 * making this a helper since this is probably going to be used in several places
 * @param {array} onlineUsers 
 * @param {string} userToCheck 
 * @returns {boolean}
 */

export const hasRoomMessages = (chat, roomEvents) => {
    const chatRoom = checkRoomForEventsFromList(chat, roomEvents);

    if (roomEvents.length === 0 || !chatRoom) {
        return false;
    }
    
    const roomMessages = chatRoom.timeline.filter((ev) => ev.type === EventType.RoomMessageEncrypted);
    return roomMessages.length > 0;
};

const checkRoomForEventsFromList = (chat, roomEvents) => {
    const chatRoom = roomEvents.find((roomEventChat) => roomEventChat.chat.matrixRoomId === chat.matrixRoomId);
    return chatRoom;
};

export const checkIfUserIsOnline = (onlineUsers, userIdToCheck) => {
    if (!Array.isArray(onlineUsers) || onlineUsers.length === 0) {
        return false;
    }

    return onlineUsers.includes(userIdToCheck);
};

export const downloadUserKeysForDecryption = (matrixClient, room) => {
    const userIds = room.getMembers().map((i) => { 
        return i.userId; 
    });
    matrixClient.getCrypto().getUserDeviceInfo(userIds, true);
};