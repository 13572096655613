import React from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { Box, Dialog } from "@material-ui/core/";
import { useState } from "react";
import { Password, Email, ConfirmEmail, CancelButton, SaveButton, ErrorList, ErrorStrings } from "../components/ChangeEmailFields";
import {
    Toast, Text
} from "src/components";

const ChangeEmailForm = (props) => {
    const { showDialog, handleClose, token, setEmail, isAccountOwner } = props;
    const [inputErrors, setInputErrors] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        email: "",
        confirmEmail: "",
    });

    const handleInputChange = (event, key) => {
        const updatedFormData = { ...formData, [key]: filterInputValue(event.target.value) };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = () => {
        if (formLoading) {
            return;
        }

        setInputErrors([]);
        const errors = getErrors();

        if (errors.length > 0) {
            setInputErrors(errors);
            setFormLoading(false);
            return;
        }

        saveEmail(formData, setFormLoading, setInputErrors);
    };

    const saveEmail = async (formData, setSaveEmailLoading, setInputErrors) => {
        setSaveEmailLoading(true);
  
        const route = isAccountOwner ? "updateCompanyAccountEmail" : "updateEmployeeEmail";
        axios.put(`${API_URL}/company/${route}`, formData, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(() => {
            setEmail(formData.email);
            setSaveEmailLoading(false);
            handleClose(false);
            return Toast.info("Your email was updated.");
        }).catch(error => {
            if (error.message) {
                Toast.error(error.message.toString());
                return;
            }

            let errors = [];
            let dataErrorFields = error;
            
            Object.keys(dataErrorFields).forEach(fieldKey => {
                const responseMessage = "* " + dataErrorFields[fieldKey];
                switch (fieldKey) {
                case "passwordInvalid":
                    ErrorStrings[ErrorList.PASSWORD_INVALID] = responseMessage;
                    errors.push(ErrorList.PASSWORD_INVALID);
                    return;

                case "emailInvalid":
                    ErrorStrings[ErrorList.EMAIL_INVALID] = responseMessage;
                    errors.push(ErrorList.EMAIL_INVALID);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            setInputErrors(errors);
            setSaveEmailLoading(false);
        });
    };

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    };

    // prevents console warns when one of the input values are null
    const filterInputValue = (value) => [null].includes(value) ? "" : value;

    const getErrors = () => {
        let errors = [];
        
        if (formData.password === "" || formData.password === null) {
            errors.push(ErrorList.EMPTY_PASSWORD);
        }

        if (formData.email === "" || formData.email === null) {
            errors.push(ErrorList.EMPTY_EMAIL);
        } else if (!validateEmail(formData.email)) {
            errors.push(ErrorList.EMAIL_INVALID_FORMAT);
        } else {
            //confirm email
            if (formData.confirmEmail === "" || formData.confirmEmail === null) {
                errors.push(ErrorList.EMPTY_CONFIRM_EMAIL);
            } else if (formData.email !== formData.confirmEmail) {
                errors.push(ErrorList.CONFIRM_EMAIL_MISMATCHED);
            }
        }

        return errors;
    };
    
    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={() => handleClose(false) }
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 900,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto"
                    },
                }}
            >
                <Box sx={{ margin: "40px 55px 20px 55px" }}>
                    <Text color="#11141A" size="25px" align="left" weight="700" margin="0">
                        Change Account Email 
                    </Text>
                    <div style={{ marginBottom: "1.5rem" }}></div>
                </Box>

                <Box sx={{ margin: "25px 55px 30px 55px" }}>
                    <Password placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                    <Email placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                    <ConfirmEmail placeholderDisabled={true} isEditable={!formLoading} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                </Box>

                <Box sx={{ margin: "40px 55px 40px 55px" }} >
                    <div style={{ textAlign: "right" }}>
                        <CancelButton onClick={ (e) => {handleClose(false); } }>Cancel</CancelButton>
                        <SaveButton disabled={formLoading} onClick={ (e) => handleFormSubmit(e) }>{formLoading ? "Saving..." : "Save"}</SaveButton>
                    </div>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}; 

export default ChangeEmailForm;