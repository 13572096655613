import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Box, Dialog } from "@material-ui/core/";
import { ChatContext } from "../ChatContext";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import {
    FlexContainer, Text, FormField, Button, LoadingIndicator
} from "src/components";
import SearchIcon from "src/img/new/search-gray.svg";
import { FORM_TYPE_CREATE, GROUP_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import Single from "./create/Single";
import Group from "./create/Group";
import TypeSelection from "./create/TypeSelection";
import LeadershipRoleToUseSelection from "./create/LeadershipRoleToUseSelection";
import { useFetchUsersList } from "../hooks";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";

export const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
    border-bottom: 1px solid #DBE5ED;
    border-radius: 1.25rem 1.25rem 0 0;
`;

export const SearchInput = styled(FormField)`
    position: relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 0.9375rem) center;
    border: 1px solid #DBE5ED;
    width: 100%;
    max-width: 53.9375rem;
    height: 2.5rem;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0.9375rem 0 0.9375rem;
    border-radius: 0.5rem !important;
    margin: ${p => p.margin};

    ::placeholder {
        text-align: left;
        color: #AFBBC6;
        font-size: 0.875rem;
    }
`;

export const StyledButton = styled(Button)`
    width: 11.5rem;
    height: 3.4375rem;
    border-radius: 0.625rem !important;
    font-size: 1rem;
    font-weight: 700;
    box-sizing: border-box;
    background-color: ${ p => p.backgroundColor || "" };
    color: ${ p => p.color || "" };
`;

const GridContainer = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    gap: 1.25rem;
    width: 100%;
    position: relative;
`;

const CreateChatModal = (props) => {
    
    /** Start of Initial functions - //TODO: create another file for this */

    const currentUser = useContext(UserContext);
    const leadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];
    const permissions = currentUser[UserKeys.PERMISSIONS];
    const { chatToCreate, selectedChatListTypeOrLeadershipUuid } = useContext(ChatContext);
    
    /**
    * @param {string} currentSelectedLeadershipUuid
    * @param {string} checkerType
    * @param {array} leadershipRoles
    * @returns {boolean}
    */
    const checkLeadershipRoleVisibility = (currentSelectedLeadershipUuid, checkerType, leadershipRoles) => {
        if (currentSelectedLeadershipUuid === undefined || currentSelectedLeadershipUuid === "") {
            return true;
        }

        const selectedLeadershipRole = leadershipRoles.find((leadershipRole) => leadershipRole.uuid === currentSelectedLeadershipUuid);
        if (selectedLeadershipRole && currentSelectedLeadershipUuid === selectedLeadershipRole.uuid) {
            if (checkerType === USER_TYPE_EMPLOYEES) {
                return selectedLeadershipRole.isVisibleToEmployees;
            } else if (checkerType === USER_TYPE_CLIENTS) {
                return selectedLeadershipRole.isVisibleToClients;
            }
        }
    };

    const hasAnyChatPermission = isChatActionAllowed(MESSAGE_EMPLOYEES, permissions) || isChatActionAllowed(MESSAGE_CLIENTS, permissions);
    const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
    const isEmployeeTypeOptionAllowed = () => isNonLeadership ? hasAnyChatPermission : checkLeadershipRoleVisibility(selectedChatListTypeOrLeadershipUuid, USER_TYPE_EMPLOYEES, leadershipRoles);
    const isClientTypeOptionAllowed = () => isNonLeadership ? hasAnyChatPermission : checkLeadershipRoleVisibility(selectedChatListTypeOrLeadershipUuid, USER_TYPE_CLIENTS, leadershipRoles);
    const selectedUserListDefault = (preSelectedOption = null) => {
        const userListDefault = () => {
            //usually `preSelectedOption` can be a leadershipRole because we want to auto select the userlist from the Active List.
            //but when the userlist is already loaded, user list can only choose between Employee and Client.
            if (![USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(preSelectedOption)) {
                if (isEmployeeTypeOptionAllowed())
                    return USER_TYPE_EMPLOYEES;
        
                if (isClientTypeOptionAllowed())
                    return USER_TYPE_CLIENTS;

            } else {

                if (preSelectedOption) {
                    const isPreselectedOptionAllowable = (isEmployeeTypeOptionAllowed() && preSelectedOption === USER_TYPE_EMPLOYEES) || (isClientTypeOptionAllowed() && preSelectedOption === USER_TYPE_CLIENTS);

                    if (isPreselectedOptionAllowable)
                        return preSelectedOption;
                }

                if (isEmployeeTypeOptionAllowed())
                    return USER_TYPE_EMPLOYEES;

                if (isClientTypeOptionAllowed())
                    return USER_TYPE_CLIENTS;
            }

            throw new Error("No permission found to display user list.");
        };
        const result = userListDefault();
        return result;
    };
    /** End of Initial function */
    
    const { showDialog, handleClose, handleCreateChat, isCurrentlyCreatingChat } = props;
    /** list of all company users */
    const [usersList, setUsersList] = useState([]);
    /** We don't want to change the `selectedChatListTypeOrLeadershipUuid` in here because it will trigger a re-render
     * from the Chat */
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [loadingFetchUsers, setLoadingFetchUsers] = useState(false);
    const [isUserListBeingSearched, setIsUserListBeingSearched] = useState(false);
    const [selectedUserListType, setSelectedUserListType] = useState(selectedUserListDefault(selectedChatListTypeOrLeadershipUuid));
    const [formData, setFormData] = useState({
        selectedEmployeesToChat: [],
        groupChatName: "",
        leadershipRoleUuid: isNonLeadership ? undefined : selectedChatListTypeOrLeadershipUuid
    });
    const headers = { headers: { "Authorization": `Bearer ${currentUser[UserKeys.TOKEN]}` } };
    const { fetchUsersList } = useFetchUsersList();

    const isGroupChat = chatToCreate.chatType === GROUP_CHAT;
    const hasLeadershipRoles = Array.isArray(leadershipRoles) && leadershipRoles.length > 0;
    

    const retrieveUpdatedUsersList = async (fetchUserType) => {
        setLoadingFetchUsers(true);
        setIsUserListBeingSearched(false);

        const users = await fetchUsersList(fetchUserType, headers);
        if (users) {
            setUsersList(users);
            setUsersToDisplay(users);
        }
        setLoadingFetchUsers(false);
    };

    useEffect(() => {
        if (!hasLeadershipRoles) {
            retrieveUpdatedUsersList(selectedChatListTypeOrLeadershipUuid);
        }
    }, [showDialog]);

    /** @param {array} data */
    const handleSelectedEmployeesToChat = (data) => {
        setFormData({ ...formData, selectedEmployeesToChat: data });
    };

    const handleSetGroupChatName = (name) => setFormData({ ...formData, groupChatName: name });

    const handleSubmit = () => {
        handleCreateChat({
            employeeUuidsToChat: formData.selectedEmployeesToChat,
            groupChatName: formData.groupChatName,
            leadershipRoleUuid: formData.leadershipRoleUuid,
            isClient: selectedUserListType === USER_TYPE_CLIENTS
        });
    };

    const handleSearch = (searchKey) => {
        if (searchKey.length) {
            const filteredUsers = usersList.filter(user => 
                user.firstName.toLowerCase().includes(searchKey.toLowerCase())
                || user.lastName.toLowerCase().includes(searchKey.toLowerCase())    
            );
            setIsUserListBeingSearched(true);
            setUsersToDisplay(filteredUsers);
        } else {
            setIsUserListBeingSearched(false);
            setUsersToDisplay(usersList);
        }
    };

    /**
    * @param {string} userType    
    */
    const changeSelectedType = (userType) => {
        retrieveUpdatedUsersList(userType);
    };
    
    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "90%",
                        height: "90%",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC"
                    },
                }}
                className="scroll-design"
            >
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Header>
                        <Text color="#4B5155" size="1.125rem" weight="700">{ isGroupChat ? "Create Group Chat" : "New Chat" }</Text>
                        { !hasLeadershipRoles && leadershipRoles.size > 0 && 
                            <TypeSelection changeSelectedType={changeSelectedType}
                                defaultValue={selectedChatListTypeOrLeadershipUuid}
                                width="12.5rem"
                                border="1px solid #FFF"
                                
                            />
                        }
                    </Header>
                    <Box sx={{ margin: "1.5625rem 3.0625rem 2rem 2.375rem", flex: "1" }}>
                        <FlexContainer style={{ height: "100%" }}>
                            { isCurrentlyCreatingChat 
                                ?
                                <LoadingIndicator /> 
                                :
                                <>
                                    { hasLeadershipRoles &&
                                        <GridContainer>
                                            <LeadershipRoleToUseSelection formData={formData} setFormData={setFormData} changeSelectedType={changeSelectedType} />
                                            <TypeSelection showLabel={true} changeSelectedType={changeSelectedType} 
                                                fontSize="0.875rem" fontWeight="500"
                                                color="#000"
                                                border="1px solid #000"
                                                formData={formData}
                                                setSelectedUserListType={setSelectedUserListType}
                                                selectedUserListType={selectedUserListType}
                                                selectedUserListDefault={selectedUserListDefault}
                                                isEmployeeTypeOptionAllowed={isEmployeeTypeOptionAllowed} 
                                                isClientTypeOptionAllowed={isClientTypeOptionAllowed}
                                            />
                                        </GridContainer>
                                    }
                                    <SelectUsersSection
                                        loadingFetchUsers={loadingFetchUsers}
                                        usersList={usersList}
                                        isUserListBeingSearched={isUserListBeingSearched}
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                    >
                                        { chatToCreate.chatType === GROUP_CHAT ?
                                            <Group usersToDisplay={usersToDisplay}
                                                formType={FORM_TYPE_CREATE}
                                                handleSearch={handleSearch}
                                                handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                                handleSetGroupChatName={handleSetGroupChatName}
                                                hasLeadershipRoles={hasLeadershipRoles}
                                            />
                                            :
                                            <Single usersToDisplay={usersToDisplay}
                                                handleSearch={handleSearch}
                                                handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                                hasLeadershipRoles={hasLeadershipRoles}
                                                isUserListBeingSearched={isUserListBeingSearched}
                                            />
                                        }
                                    </SelectUsersSection>
                                </>
                            }
                        </FlexContainer>
                    </Box>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

const SelectUsersSection = ({ loadingFetchUsers, usersList, handleClose, handleSubmit, children }) => {
    if (loadingFetchUsers) {
        return (
            <FlexContainer style={{ height: "70vh", justifyContent: "center", textAlign: "center" }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }

    const renderFooterAction = (isShowMessageButton = false) => {
        return (
            <FlexContainer direction="row" justifyContent="flex-end" bottom="0.5rem" height="3.5rem" right="2rem">
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <StyledButton backgroundColor="#FFF" color="#000"
                        border="1px solid #DBE5ED" onClick={handleClose}
                    >
                        Cancel
                    </StyledButton>

                    {isShowMessageButton && 
                        <StyledButton backgroundColor="#006CFF" color="#FFF"
                            marginLeft="1.3125rem" 
                            onClick={() => handleSubmit()}
                        >
                            Message
                        </StyledButton>
                    }
                </div>
            </FlexContainer>
        );
    };

    if (usersList.length === 0) {
        return (
            <>
                <FlexContainer style={{ height: "80%", justifyContent: "center", textAlign: "center" }}>
                    No users to display.
                </FlexContainer>
                {renderFooterAction(false)}
            </>
        );
    }

    return (
        <>
            {children}
            {renderFooterAction(true)}
        </>
    );
};

export default CreateChatModal;
