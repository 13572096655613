import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { FlexContainer, LottieLoadingIndicator, Text } from "src/components";

const InfoBox = styled.div`
    width: ${p => p.width || "max-content" };
    height: 50px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E9EBEF;
    border-radius: 12px;
    opacity: 1;
`;

export class SurveyInfo extends Component {
    render() {
        const { survey, fetchingSurvey } = this.props;

        return (
            <div style={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "nowrap" }} >
                { fetchingSurvey && 
                    <div style={{ width: "100%" }}>
                        <LottieLoadingIndicator/>
                    </div>
                }
                {!fetchingSurvey && <>
                    <div style={{ width: "45%", textAlign: "left", paddingRight: "1rem" }}>
                        <Text color="#000000" size="16px" weight="bold" align="left">
                            Survey Name:
                        </Text>
                        <InfoBox width="100%">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal", textOverflow: "ellipsis", overflow: "hidden", height: "50%", whiteSpace: "nowrap" }}>
                                {survey.name}
                            </div>
                        </InfoBox>

                        <Text color="#000000" size="16px" weight="bold" align="left">
                            User Group Selected:
                        </Text>
                        <InfoBox width="100%">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal", textOverflow: "ellipsis", overflow: "hidden", height: "50%", whiteSpace: "nowrap" }}>
                                {survey.userGroup}
                            </div>
                        </InfoBox>
                    </div>

                    <div style={{ width: "55%" }}>
                        <Text color="#000000" size="16px" weight="bold" align="left">
                            Survey Type:
                        </Text>
                        <InfoBox width="100%">
                            <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal", textOverflow: "ellipsis", overflow: "hidden", height: "50%", whiteSpace: "nowrap" }}>
                                {survey.surveyType}
                            </div>
                        </InfoBox>
                        
                        <Text color="#000000" size="16px" weight="bold" align="left">
                            Survey Start/Finish Date:
                        </Text>
                        <FlexContainer direction="row" style={{ gap: "16px" }}>
                            <InfoBox width="100%">
                                <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal", textOverflow: "ellipsis", overflow: "hidden", height: "50%", whiteSpace: "nowrap" }}>
                                    {survey["startDate"] ? moment(survey["startDate"]).format("Do MMMM, YYYY") : "..."}
                                </div>
                            </InfoBox>
                            <InfoBox width="100%">
                                <div style={{ margin: "14px 24px", textAlign: "left", fontWeight: "normal", textOverflow: "ellipsis", overflow: "hidden", height: "50%", whiteSpace: "nowrap" }}>
                                    {survey["finishDate"] ? moment(survey["finishDate"]).format("Do MMMM, YYYY") : "..."}
                                </div>
                            </InfoBox>
                        </FlexContainer>
                    </div>
                </>}
            </div>
        );
    }
}