import React, { Component, forwardRef } from "react";
import styled, { css } from "styled-components";
import Text from "src/components/Text";
import CalendarIcon from "src/img/survey/calendar.png";
import DatePicker from "react-datepicker";
import moment from "moment";

export const TextInput = styled.input`
    color: ${ p => p.color};
    font-family: ${p => p.fontFamily || "Roboto, Helvetica, sans-serif"};
    text-align: ${p => p.align || "center"};
    background-color: ${ p => p.backgroundColor || "white" };
    opacity: ${ p => p.opacity || "1" };
    width: ${ p => p.width || "100%" };
    height: ${ p => p.height || "2.4rem" };
    flex-basis: ${ p => p.flexBasis};
    padding: ${ p => p.padding || "10px 20px 10px 20px" };
    border-radius: ${ p => p.borderRadius || "1.2rem" };
    border-right: none !important;
    border: ${ p => p.border || "none" };
    border-bottom: ${ p => p.borderBottom };
    margin: ${ p => p.margin || "5px 0" };
    box-sizing: border-box;
    font-size: ${p => p.size || "0.8rem"};
    overflow: ${p => p.hiddenOverFlow && "hidden"};
    font-weight: ${ p => p.fontWeight || "400" };
    max-width: ${p => p.maxWidth};
    ::placeholder {
        opacity: ${ p => p.opacity || "initial" };
        color: ${ p => p.placeholderColor};
        font-weight: ${ p => p.placeholderWeight || "400" };
        text-align: ${ p => (p.align || p.placeholderAlignment) || "center" };
        font-size: ${ p => p.placeholderSize || "0.875rem"};
    };
    ${ p => p.isHighlighted && css`
        border: 1px solid red !important;
    `};
    ::-webkit-inner-spin-button { display: none; }
    ::-webkit-datetime-edit-text { 
        color: #000; 
        padding: 0 0.3rem; 
    }
    ::-webkit-datetime-edit-month-field { color: ${p => p.color || "#6A1E00"}; } 
    ::-webkit-datetime-edit-day-field { color: ${p => p.color || "#6A1E00"}; }
    ::-webkit-datetime-edit-year-field { color: ${p => p.color || "#6A1E00"}; }
    ::-webkit-calendar-picker-indicator { background: #6A1E00; }

    ${ p => p.isHideDefaultCalendarIcon && css`
        ::-webkit-calendar-picker-indicator { 
            background: none; 
            height: 25px;
            width: 25px;
        }
    `};
    
`;

export const ErrorText = styled(Text)`
    color: red;
    text-align: ${p => (p.align || p.errorTextAlign) || "left"};
    font-size: 12px;
    margin: ${p => p.errorMargin || "0 20px 5px"};
    height: 12px;
    height: ${p => (p.errorTextHeight) || "12px"};
    white-space: pre-wrap;
`;

const CustomSurveyDatePicker = styled.div`
        font-size: 1rem;
        height: 3.125rem;
        placeholder-color: #000000;
        border: 1px solid #E9EBEF;
        border-radius: 12px;
        background-color: ${p => p.backgroundColor || "#fff"};
        font-weight: 500;
        textAlign: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: ${p => p.cursor || "pointer"};
        user-select: none;
        width: 100%;
        @media (max-width: 600px) {
            font-size: 0.875rem;
        }
    `;

class SurveyDurationFormField extends Component {
    render() {
        const { errors, placeholder, onChange, showErrors, errorTextAlign, value, disabled, ...styleProps } = this.props;
        const errorsArray = errors ? errors : [];
        
        const CustomInput = forwardRef(
            ({ value, onClick, styleProps }, ref) => (
                <CustomSurveyDatePicker 
                    style={styleProps}
                    onClick={onClick}
                    ref={ref}
                    backgroundColor={disabled ? "#F0F0F0" : ""}
                    cursor={disabled ? "not-allowed" : ""}
                >
                    <span style={{ margin: "auto auto auto 1rem", flex: "1", textAlign: "left", color: value === "" ? "#808080" : "", size: "15px" }}>
                        {value === "" ? placeholder : moment(value).format("Do MMMM, YYYY")}
                    </span>
                    <i style={{
                        backgroundColor: "inherit",
                        width: "10%",
                        padding: "auto 16px auto auto",
                        borderLeft: "none !important",
                        borderRadius: "0px 10px 10px 0px",
                        display: "inline",
                        margin: "auto 16px auto auto"
                    }}>
                        <img src={CalendarIcon} alt={CalendarIcon} height="24px" width="24px" style={{ verticalAlign: "middle" }}/>
                    </i>
                </CustomSurveyDatePicker>
            ),
        );

        return (
            <>
                <DatePicker
                    selected={value}
                    max="9999-12-31"
                    onChange={(date) => {onChange(date);}}
                    disabled={disabled}
                    placeholder={placeholder}
                    customInput={<CustomInput />
                    }
                />
                { showErrors && <ErrorText errorTextAlign={errorTextAlign} {...styleProps}>{errorsArray}</ErrorText> }
            </>
        );
    }
}

export default SurveyDurationFormField;