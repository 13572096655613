import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios"; 
import Select from "react-select";
import styled from "styled-components";

import {
    Toast, FlexContainer, FormField, ErrorText,
    Text, Form, PageHeadingLabel, PageContainer,
    Checkbox
} from "src/components";

import { CancelButton, SaveButton, StyledButton, QuestionContainer,
    AddAnotherQuestionContainer, RemoveQuestionIconContainer,
    FormBackground, FormSectionHeader, LoadingIndicatorButton,
    LoadingIndicatorIcon
} from "../components/Utils";
import { EnumSurveyShowFrequency, EnumSurveyShownIn, ErrorStrings,
    ErrorList, FormStep, surveyTypeOptions, surveyUserGroupOptions,
    surveyShowInOptions, surveyFrequencyOptions
} from "../components/Enums";
import { customStyles, IconOption, SingleValue } from "./components/DropdownCustomStyles";
import { SurveyStatusType } from "src/constants/survey";
import SurveyDurationFormField from "../components/SurveyDurationInput";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;
class SurveyForm extends Component {
    state = {
        survey: {
            name: "",
            surveyType: "",
            startDate: "",
            finishDate: "",
            userGroup: "",
            surveyQuestions: {
                one: { uuid: "", question: "" },
                two: {},
                three: {}
            },
            showIn: EnumSurveyShownIn.QUOTE_SCREEN,
            showFrequency: EnumSurveyShowFrequency.EVERYTIME,
            anonymousSurvey: false
        },
        message: "",
        inputErrors: [],
        formStep: FormStep.PAGE_SURVEY_NAME,
        setInputTypeAs: "text",
        activeQuestionCount: 1,
        loading: false,
    };

    nextStep = (stepNumber) => {
        //validate the page before going to the next
        let errors = this.getErrors();
        if (errors.length > 0 && stepNumber > this.state.formStep)
            stepNumber = this.state.formStep;

        this.setState({
            formStep: stepNumber,
            inputErrors: errors
        });
    };

    getErrors() {
        let survey = this.state.survey;
        let errors = [];

        switch (this.state.formStep)
        {
        default:
        case FormStep.PAGE_SURVEY_NAME:
            if (survey.name === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_NAME);
            }
            break;
        case FormStep.PAGE_TYPE_OF_SURVEY:
            if (survey.surveyType === "")
            {
                errors.push(ErrorList.EMPTY_SURVEY_TYPE);
            }
            break;
        case FormStep.PAGE_SURVEY_DURATION:

            if (survey.startDate === "")
            {
                errors.push(ErrorList.EMPTY_START_DATE);
            }
            if (survey.finishDate === "")
            {
                errors.push(ErrorList.EMPTY_FINISH_DATE);
            }

            break;
        case FormStep.PAGE_USER_GROUP:
            if (survey.userGroup === "")
            {
                errors.push(ErrorList.EMPTY_USER_GROUP);
            }
            break;
        case FormStep.PAGE_SURVEY_QUESTIONS:
            let activeQuestionCount = this.state.activeQuestionCount;
            if (activeQuestionCount >= 1 && survey.surveyQuestions.one.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_ONE);
            }

            if (activeQuestionCount >= 2 && survey.surveyQuestions.two.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_TWO);
            }

            if (activeQuestionCount >= 3 && survey.surveyQuestions.three.question === "")
            {
                errors.push(ErrorList.EMPTY_QUESTION_THREE);
            }
            break;
        case FormStep.PAGE_SURVEY_FREQUENCY:
            if (survey.showIn === "")
            {
                errors.push(ErrorList.SHOW_IN);
            }

            if (survey.showFrequency === "")
            {
                errors.push(ErrorList.SHOW_FREQUENCY);
            }

            break;
        }

        return errors;
    }

    inputSurveyName = (event) => {
        const surveyName = event.target.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                name: surveyName // update the value of specific key
            }
        }));
    };

    inputStartDate = (selectedDate) => {
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                startDate: selectedDate // update the value of specific key
            }
        }));
    };

    inputFinishDate = (selectedDate) => {
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                finishDate: selectedDate // update the value of specific key
            }
        }));
    };

    selectSurveyType = (event) => {
        const surveyType = event.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                surveyType: surveyType // update the value of specific key
            }
        }));
    };

    inputQuestion = (questionNumber, event) => {
        const numberWord = ["", "one", "two", "three"];
        let questionElement = numberWord[questionNumber];

        const questionString = event.target.value;

        this.setState(prevState => ({
            survey: {
                ...prevState.survey,
                surveyQuestions: {
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: { uuid: "", question: questionString, questionOrder: questionNumber }
                }
            }
        }));
    };

    selectUserGroup = (event) => {
        const userGroup = event.value;
        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                userGroup: userGroup, // update the value of specific key
            },
            inputErrors: [], //reset Error

        }));
    };

    removeQuestion = (questionElement) => {
        this.setState(prevState => ({
            survey: {                  
                ...prevState.survey,   
                surveyQuestions: { 
                    ...prevState.survey.surveyQuestions,
                    [questionElement]: {}
                }
            },
            activeQuestionCount: this.state.activeQuestionCount - 1
        }));
    };

    addQuestion = () => {
        this.setState({
            activeQuestionCount: this.state.activeQuestionCount + 1
        });
    };

    selectShowIn = (event) => {
        const showIn = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showIn: showIn // update the value of specific key
            }
        }));
    };

    setSurveyAnonymous = (event) => {
        this.setState(prevState => ({
            survey: {
                ...prevState.survey,
                anonymousSurvey: event
            }
        }));
    };

    selectShowFrequency = (event) => {
        const showFrequency = event.value;

        this.setState(prevState => ({
            survey: { // object that we want to update
                ...prevState.survey, // keep all other key-value pairs
                showFrequency: showFrequency // update the value of specific key
            }
        }));
    };
    
    formSubmission = () => {
        this.setState({
            loading: true,
        });

        const surveyData = this.state.survey;
        const surveyQuestions = Object.keys(surveyData.surveyQuestions);

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = surveyData.startDate ? formatter.format(new Date(surveyData.startDate)) : "";
        const formattedFinishDate = surveyData.finishDate ? formatter.format(new Date(surveyData.finishDate)) : "";

        let surveyQuestionItems = {};
        surveyQuestions.forEach(questionKey => {
            if (surveyData.surveyQuestions[questionKey].question !== "" && typeof surveyData.surveyQuestions[questionKey].question !== "undefined")
                surveyQuestionItems[questionKey] = surveyData.surveyQuestions[questionKey];
        });

        axios.post(`${API_URL}/survey/company`, {
            name: surveyData.name,
            surveyType: surveyData.surveyType,
            startDate: surveyData.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: surveyData.finishDate ? formattedFinishDate + " 23:59:59" : null,
            userGroup: surveyData.userGroup,
            surveyQuestions: surveyQuestionItems,
            showIn: surveyData.showIn,
            showFrequency: surveyData.showFrequency,
            isAnonymous: surveyData.anonymousSurvey
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.setState({ 
                formStep: FormStep.PAGE_CONGRATULATIONS
            });
        }).catch(error => {
            if (error.message) {
                Toast.error(error.message.toString());
                return;
            }

            let errors = [];
            let dataErrorFields = error.fields;

            let formStep;

            try {
                dataErrorFields.forEach(function(fieldKey) {
                    switch (fieldKey) {
                    case "startDateInvalid":
                        errors.push(ErrorList.DATE_START_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
        
                    case "finishDateInvalid":
                        errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;

                    case "startDateGreaterThanFinishDate":
                        errors.push(ErrorList.DATE_RANGE_INVALID);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
                    case "name":
                        errors.push(ErrorList.EMPTY_SURVEY_NAME);
                        formStep = FormStep.PAGE_SURVEY_NAME;
                        return;
                    case "surveyType":
                        errors.push(ErrorList.EMPTY_SURVEY_TYPE);
                        formStep = FormStep.PAGE_SURVEY_NAME;
                        return;
                    case "userGroup":
                        errors.push(ErrorList.EMPTY_USER_GROUP);
                        formStep = FormStep.PAGE_USER_GROUP;
                        return;
                    case "startDateEmpty":
                        errors.push(ErrorList.EMPTY_START_DATE);
                        formStep = FormStep.PAGE_SURVEY_DURATION;
                        return;
                    default:
                        //NO ERROR TO CATCH
                        return;
                    }
                });

                this.setState({
                    inputErrors: errors,
                    formStep: formStep
                });

                return Toast.error("Please make sure your entries are correct");
            }
            catch (e) {
                return Toast.error(error.message); 
            }
        }).finally(error => {
            this.setState({
                loading: false,
            });
        });
    };

    render() {
        const { showSurveyForm, getSurveys } = this.props;
        const { inputErrors, formStep, survey, setInputTypeAs, activeQuestionCount } = this.state;

        if (formStep === FormStep.PAGE_CONGRATULATIONS)
            return (
                <PageContainer>
                    <PageCongratulations showSurveyForm={showSurveyForm} nextStep={this.nextStep} getSurveys={getSurveys} />
                </PageContainer>
            );

        return (
            <PageContainer>
                <PageHeadingLabel size="2.5rem">Create New Survey</PageHeadingLabel>
                <div className="row" style={{ maxWidth: "94.5rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                        create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                        employees, clients, or both, ensuring you reach the right audience every time.
                    </div>
                </div>

                <FormBackground>
                    <Form width="inherit" onSubmit={(e) => { e.preventDefault(); }} style={{ padding: "2.5rem 0" }} >
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                            <div style={{ width: "45%" }}>
                                <FormSectionHeader style={{ fontWeight: "600" }}>
                                    Survey Information
                                </FormSectionHeader>
                                <SurveyNameField survey={survey} inputSurveyName={this.inputSurveyName} inputErrors={inputErrors} />
                                <TypeOfSurveyField showSurveyForm={true} nextStep={this.nextStep} survey={survey} selectSurveyType={this.selectSurveyType} inputErrors={inputErrors} />
                                <SurveyDurationFields showSurveyForm={showSurveyForm} nextStep={this.nextStep} survey={survey} inputStartDate={this.inputStartDate} inputFinishDate={this.inputFinishDate} inputErrors={inputErrors} setInputTypeAs={setInputTypeAs}/>
                                <UserGroupField showSurveyForm={true} nextStep={this.nextStep} survey={survey} selectUserGroup={this.selectUserGroup} inputErrors={inputErrors} />
                                <SurveyFrequencyField showSurveyForm={true} nextStep={this.nextStep} survey={survey} inputErrors={inputErrors} selectShowIn={this.selectShowIn} selectShowFrequency={this.selectShowFrequency} />
                            </div>
                            <div style={{ width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <FormSectionHeader style={{ fontWeight: "600" }}>
                                        Survey Questions
                                    </FormSectionHeader>
                                    <SurveyQuestionsFields survey={survey} inputQuestion={this.inputQuestion} activeQuestionCount={activeQuestionCount} addQuestion={this.addQuestion} removeQuestion={this.removeQuestion} inputErrors={inputErrors} />
                                </div>
                                <div style={{ margin: "0 0 2.5rem 0" }}>
                                    <SurveySubmissionAnonymousField setSurveyAnonymous={this.setSurveyAnonymous} surveyIsAnonymous={this.state.survey.anonymousSurvey} />
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "50px 3.75rem 22px 3.75rem" }}>
                            <hr/>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                            {
                                this.state.loading ?
                                    <LoadingIndicatorButton height="38px">
                                        <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                    </LoadingIndicatorButton>
                                    :
                                    <>
                                        <CancelButton borderRadius="7px" onClick={(e) => { showSurveyForm(false); }}>Cancel</CancelButton>
                                        <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => { this.formSubmission(); }}>Save</SaveButton>
                                    </>
                            }
                        </div>
                    </Form>
                </FormBackground>
            </PageContainer>
        );
    }
}

class SurveyNameField extends Component {
    render() {
        const { survey, inputSurveyName, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

        return (
            <>
                <Text size="16px" align="left" weight="bold">
                    Enter Survey Name
                </Text>
                <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                    placeholderColor="#808080" border="1px solid #E9EBEF"
                    placeholderAlignment="left"
                    width="100%"
                    type="text"
                    style={{ textAlign: "left" }}
                    placeholder="Enter name here"
                    onChange={(event) => { inputSurveyName(event); }}
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                >
                </FormField>
            </>
        );
    }
}

class TypeOfSurveyField extends Component {
    render() {
        const { selectSurveyType, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Select The Survey Type
                </Text>
                <Select
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    onChange={(choice) => selectSurveyType(choice)}
                    styles={customStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />

                { surveyTypeError.length > 0 &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {surveyTypeError}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveyDurationFields extends Component {
    state = {
        startDateSetInputTypeAs: "date",
        finishDateSetInputTypeAs: "date",
        tooltipActive: false
    };

    startDateChangeInputTypeToDate = (event) => {
        this.setState({
            startDateSetInputTypeAs: "date"
        });
    };

    finishDateChangeInputTypeToDate = (event) => {
        this.setState({
            finishDateSetInputTypeAs: "date"
        });
    };

    render() {
        const { survey, inputStartDate, inputFinishDate, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
        const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);

        const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
        const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

        const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
        const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

        const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

        const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

        return (
            <>
                <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                        <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                            Start Date
                        </Text>
                        <SurveyDurationFormField
                            placeholder="Select date"
                            onChange={(event) => {inputStartDate(event);} }
                            errors={surveyStartDateError}
                            value={survey.startDate}
                            disabled={false}
                            showErrors={surveyStartDateError.length > 0}
                        />
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                        <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                            Finish Date
                        </Text>
                        <SurveyDurationFormField
                            placeholder="Select date"
                            onChange={(event) => {inputFinishDate(event);} }
                            errors={surveyFinishDateError}
                            value={survey.finishDate}
                            disabled={false}
                            showErrors={surveyFinishDateError.length > 0}
                        />
                    </div>
                </FlexContainer>
                
                { hasAnySurveyDurationErrors &&
                    <ErrorText errorMargin="0px">
                        {startDateInvalid.length > 0 ? startDateInvalid : null}
                        {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                        {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                        {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                        {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class UserGroupField extends Component {
    render() {
        const { selectUserGroup, inputErrors } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);

        const hasAnyUserGroupErrors = userGroupError.length > 0;
        return (
            <>
                <Text align="left" size="16px" weight="bold">
                    Select User Group
                </Text>

                <Select
                    isSearchable={false}
                    className="surveyTypeDropdown"
                    placeholder="Select a user group"
                    options={ surveyUserGroupOptions }
                    onChange={(choice) => selectUserGroup(choice)}
                    styles={customStyles}
                />

                { hasAnyUserGroupErrors &&
                    <ErrorText errorMargin="15px 20px 15px">
                        {userGroupError.length > 0 ? userGroupError : null}
                    </ErrorText>
                }
            </>
        );
    }
}

class SurveyQuestionsFields extends Component {
    render() {
        const { inputQuestion, inputErrors, survey, activeQuestionCount, addQuestion, removeQuestion } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
        let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
        let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

        return (
            <div style={{ width: "90%" }}>
                {activeQuestionCount >= 1 && <QuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} ></QuestionContainer>}
                {activeQuestionCount === 1 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}

                {activeQuestionCount >= 2 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} ></QuestionContainer>
                            </div>
                            {activeQuestionCount === 2 && <RemoveQuestionIconContainer display="block" position="absolute" removeQuestion={removeQuestion} questionElement={"two"} />}
                        </div>
                        {activeQuestionCount === 2 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}
                    </>
                }
                {activeQuestionCount >= 3 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} ></QuestionContainer>
                                {activeQuestionCount === 3 && <RemoveQuestionIconContainer display="block" position="absolute" removeQuestion={removeQuestion} questionElement={"three"} />}
                            </div>
                        </div>
                    </>
                }
                
            </div>
        );
    }
}

class SurveyFrequencyField extends Component {
    state = { tooltipActive: false };

    render() {
        const { inputErrors, selectShowIn, selectShowFrequency } = this.props;

        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
        const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
        const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Show Survey In:
                </Text>
                <FlexContainer>
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyShowInOptions[0]}
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />

                    <ErrorText>
                        {showInError}
                    </ErrorText>


                    <Text align="left" margin="10px 0px" size="16px" weight="500">
                        Select Survey Frequency?
                    </Text>

                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyFrequencyOptions[0]}
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowFrequency(choice)}
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />
                    <ErrorText>
                        {showFrequencyError}
                    </ErrorText>
                </FlexContainer>
            </>
        );
    }
}

class SurveySubmissionAnonymousField extends Component {
    render() {
        const { setSurveyAnonymous, surveyIsAnonymous } = this.props;

        return (
            <>
                <Text align="left" size="16px" weight="500">
                    Make Survey Submission Anonymous (Optional)
                </Text>
                <Text align="left" size="14px" color="#8291B2" maxWidth="540px" wordBreak="break-word">
                By checking this box, all survey responses will be collected anonymously,
                allowing users to share their thoughts freely without any personal identifiers.
                Once the survey becomes active, this setting cannot be changed,
                so make sure to decide before proceeding to promote candid and unbiased feedback.
                </Text>
                <CheckboxContainer>
                    <label>
                        <Checkbox
                            color="#36be24"
                            height="32px"
                            width="32px"
                            padding="7px"
                            radius="10px"
                            value={true}
                            checked={surveyIsAnonymous}
                            onChange={ (e) => setSurveyAnonymous(e.target.checked) }
                        />
                    </label>
                </CheckboxContainer>
            </>
        );
    }
}

class PageCongratulations extends Component {
    hideFormAndReloadList = () => {
        const { showSurveyForm, getSurveys } = this.props;

        showSurveyForm(false);
        getSurveys(SurveyStatusType.ACTIVE);
    };

    render() {
        return (
            <>
                <PageHeadingLabel size="2.5rem">Create New Survey</PageHeadingLabel>
                <div className="row" style={{ maxWidth: "94.5rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                        create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                        employees, clients, or both, ensuring you reach the right audience every time.
                    </div>
                </div>

                <FormBackground style={{ margin: "0 0 3rem 0",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "60rem",
                    flexGrow: "1",
                }}>
                    <div style={{ margin: "auto" }}>
                        <Text color="#000000" size="40px" style={{ marginBlockEnd: "0px" }}>
                            Congratulations!
                        </Text>
                        <Text color="#000000" size="40px" style={{ marginBlockStart: "0px" }}>
                            Your Survey Has Been Created.
                        </Text>
                        <Text color="black" size="16px" width="650px" lineHeight="25px" margin="0">
                            You can always make edits to your scheduled and active surveys via the <br/>
                            &rsquo;Surveys&rsquo; main screen and view insights once your survey has gone live.
                        </Text>

                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
                            <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "100px", height: "40px", marginTop: "65px" }}
                                onClick={() => { this.hideFormAndReloadList(); }} >
                                Close
                            </StyledButton>
                        </div>
                    </div>
                </FormBackground>
            </>
        );
    }
}

export default SurveyForm;