import React from "react";
import styled from "styled-components";
import Skeleton from "./Skeleton";
import { isPositiveNumber } from "src/utils/helpers";
import LineChart from "./LineChart";

const CardContainer = styled.div`
    min-width: 16.75rem;
    max-width: 16.75rem;
    margin: .7rem 1rem 1rem 0;
`;

const ContentContainer = styled.div`
    position: relative;
    height: 100%;
    min-height: 60px;
    margin-right: 30px;
    display: flex;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100px;
`;

const Card = styled.div`
    min-width: 16.75rem;
    max-width: 16.75rem;
    min-height: ${p => p.cardHeight || "116px"};
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: ${p => p.cardShadow || "0 5px 5px #00000026"};
    border: 1px solid #8291B2;
    border-radius: 10px;
`;

const CardHeader = styled.div`
    margin: .5rem 1rem;
    width: 10rem;
    text-align: left;
    font-size: .75rem;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
`;

const Hr = styled.div`
    border: 1px solid #EFEFEF;
`;

const TextLabel = styled.div`
    top: 538px;
    left: 350px;
    width: 268px;
    height: 38px;
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    
    letter-spacing: 0;
    color: #8291B2;
    opacity: 1;
    margin: .7rem 1rem 1rem .2rem;
`;

const Number = styled.div`
    text-align: left;
    font: normal normal bold 25px/33px Roboto;
    letter-spacing: 0;
    margin: 0.5rem 0.5rem -0.5rem 1.2rem;
    color: #000000;
    opacity: 1;
`;

const NumberPercentage = styled.div`
    text-align: left;
    font-size: .8rem;
    letter-spacing: 0;
    margin: 0.5rem 0.5rem -0.5rem 1.2rem;
    color: #02BC77;
    opacity: 1;
`;

const NegativeNumberPercentage = styled.div`
    text-align: left;
    font-size: .8rem;
    letter-spacing: 0;
    margin: 0.5rem 0.5rem -0.5rem 1.2rem;
    color: #FF2366;
    opacity: 1;
`;

const SkeletonContainer = styled.div`
    margin: .5rem 1rem;
`;

const LineChartContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

const StatisticsCard = (props, children) => {
    const { header, footer, loading, graphData, rate, total, loadingLineGraph, cardHeight, printFormat } = props;
    return (
        <>
            <CardContainer>
                <Card cardHeight={cardHeight} cardShadow={printFormat ? "unset" : "0 5px 5px #00000026;"}>
                    <CardHeader>
                        {loading ?
                            <Skeleton variant="Text" width="100px" height="20px" animation="wave" />
                            : header
                        }
                    </CardHeader>
                    <Hr />
                    {loading ?
                        <SkeletonContainer>
                            <Skeleton variant="Text" width="50px" height="33px" animation="wave" />
                        </SkeletonContainer>
                        :
                        <ContentContainer>
                            <ContentWrapper>
                                <Number>{total}</Number>
                                {(!rate && rate !== 0) ? "" : (isPositiveNumber(rate) ? <NumberPercentage>+{rate}%</NumberPercentage> : <NegativeNumberPercentage>{rate}%</NegativeNumberPercentage>) }
                            </ContentWrapper>
                            <LineChartContainer>
                                {loadingLineGraph ?
                                    <SkeletonContainer>
                                        <Skeleton variant="Text" width="60px" height="40px" animation="wave" />
                                    </SkeletonContainer>
                                    :
                                    <LineChart
                                        data={graphData}
                                        isPositive={isPositiveNumber(rate)}
                                    />}
                            </LineChartContainer>
                        </ContentContainer>
                    }
                </Card>
                <TextLabel size=".5rem">
                    {footer}
                </TextLabel>
            </CardContainer>
        </>
    );
};
export default StatisticsCard;