import { useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";

const usePolls = (token) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const config = {
        signal: controller.signal,
        headers: {
            Authorization: "Bearer " + token
        }
    };

    useEffect(() => {
        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, []);


    async function requestS3PresignedUrl(imageType) {
        try {
            // request S3 presigned Url
            const response = await axios.get(`${API_URL}/polls/generateImageUrl/${imageType}`, config);
            const s3PresignedUrl = response.data.url;
            return s3PresignedUrl;
        } catch (e) {
            console.warn("Unable to request presigned URL for polls image.", e);
            return "";
        }
    }

    async function uploadToS3(s3PresignedUrl, fileObject) {
        try {
            // upload image to S3
            await axios.put(s3PresignedUrl, fileObject, {
                headers: { "Content-Type": fileObject.type }
            });

            const storedUrl = s3PresignedUrl.substring(0, s3PresignedUrl.indexOf("?"));
            return storedUrl;
        } catch (e) {
            console.warn("Unable to upload poll image.", e);
            return "";
        }
    }
    return { 
        requestS3PresignedUrl,
        uploadToS3,
    };
};
export default usePolls;