import { EventType, RoomEvent } from "matrix-js-sdk";
import { EventStatus } from "matrix-js-sdk/lib/models/event-status";
import { KnownMembership } from "matrix-js-sdk/lib/@types/membership";
import { useCallback, useEffect, useState } from "react";
import { EVENT_ID_TO_REPLACE } from "src/constants/matrixEventTypes";
import { ADMIN_MATRIX_ID } from "src/constants/chat";

/**
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @typedef {import('matrix-js-sdk').MatrixEvent} MatrixEvent
 * @typedef {import('matrix-js-sdk').Room} Room
 * @param {MatrixClient} matrixClient
 * @param {boolean} isInitialSyncComplete
 */
export default function useTimelineListener(matrixClient, isInitialSyncComplete) {
    const [newEvent, setNewEvent] = useState(null);
    const [matrixRoomIdToRemove, setMatrixRoomIdToRemoveState] = useState(null);
    const [roomWithMemberChange, setRoomWithMemberChangeState] = useState(null);

    useEffect(() => {
        if (matrixClient !== null && isInitialSyncComplete) {
            listenToTimeline(matrixClient);
        }
    }, [matrixClient, isInitialSyncComplete]);

    const resetRoomWithMemberChange = useCallback((propertyName) => {
        setRoomWithMemberChangeState(null);
    });

    const resetMatrixRoomIdToRemove = useCallback(() => {
        setMatrixRoomIdToRemoveState(null);
    }, []);

    /**
     * @param {MatrixClient} client 
     */
    const listenToTimeline = (client) => {
        client.on(
            RoomEvent.Timeline,

            /**
             * @param {MatrixEvent} matrixEvent
             * @param {Room} room
             * @param _toStartOfTimeline
             * @returns {Promise<void>}
             */
            async function(matrixEvent, room, _toStartOfTimeline) {
                const eventType = matrixEvent.getType();

                /** Sending Message - STEP 1 */
                if (matrixEvent.status === EventStatus.SENDING) { //this will emit when sending a message
                    matrixEvent["onpoint_" + EVENT_ID_TO_REPLACE] = matrixEvent.getId();
                    setNewEvent(matrixEvent);
                    return;
                }

                switch (eventType) {
                    // This will also emit when receiving outbound message
                    case EventType.RoomMessageEncrypted:
                        setNewEvent(matrixEvent);
                        break;

                    case EventType.RoomMember:
                        const sender = matrixEvent.getSender();
                        const membership = matrixEvent.getContent()?.membership;
                        const roomId = room.roomId;
                        const clientMatrixId = client.getUserId();

                        if (sender === ADMIN_MATRIX_ID) {
                            //ignore admin (auto-join). The reason it exists is so we can manage users being added to / removed from rooms
                            return;
                        }

                        if (membership === KnownMembership.Join) {
                            setRoomWithMemberChangeState(roomId);
                            setNewEvent(matrixEvent);
                        } else if (membership === KnownMembership.Leave) {
                            const removedUserId = matrixEvent.getStateKey();
                            /**
                             * removedUserId === ADMIN_MATRIX_ID means the chat was deleted by the other user
                             * this listener and MyMembership do not receive an event specifically for a deleted chat
                             * TODO: as of writing, sometimes it removes the deleted chat, sometimes it doesn't;
                             *   attempts at opening the chat will return a graceful error of "Unable to fetch timeline"
                             */
                            if ([clientMatrixId, ADMIN_MATRIX_ID].includes(removedUserId)) {
                                return setMatrixRoomIdToRemoveState(roomId);
                            }
                            setNewEvent(matrixEvent);
                            setRoomWithMemberChangeState(roomId);
                        }
                        break;

                    default:
                }
            }
        );

        client.on(
            RoomEvent.LocalEchoUpdated,

            /**
             * a confirmation that the message have been delivered to the server
             * ChatMessages will replace the local echo with this new event.
             * @param {MatrixEvent} matrixEvent
             * @param {Room} room
             * @param {string} oldEventId
             */
            async (matrixEvent, room, oldEventId, oldStatus) => {

                /** Sending Message - STEP 2 */
                if (matrixEvent.status === EventStatus.SENT) {
                    /**
                     * TODO: Noting that appending our own custom properties to an SDK-generated object feels very error-prone 
                     * since it's difficult to reliably guarantee that it will always be there when we think it will be there. 
                     * Suggesting to look into refactoring this as a separate state later on.
                     */
                    matrixEvent["onpoint_" + EVENT_ID_TO_REPLACE] = oldEventId;
                    setNewEvent(matrixEvent);
                }
            });
    };

    return {
        newEvent,
        setNewEvent,
        matrixRoomIdToRemove,
        roomWithMemberChange,
        resetMatrixRoomIdToRemove,
        resetRoomWithMemberChange
    };
}