import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import styled from "styled-components";
import SearchIcon from "src/img/new/search.svg";
import LinkClicksModal from "./LinkClicksModal";

const TableControlsGrid = styled(Grid)`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
`;

const SearchInput = styled.input`
    position:relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    text-indent: 25px;
    background-position: 16px center;
    line-height: 100%;
    width: 100%;
    border: 1px solid #000;
    max-width: 31.75rem;
    height: 2.438rem;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    border-radius: 10px;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
        font-weight: 700;
        font-size: 0.875rem;
    }

    &:focus {
        outline: none;
    }
`;

const TableButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    width: ${p => p.width};
    color: #fff;
    background-color: ${p => p.backgroundColor};
    box-sizing: border-box;
    font-size: 0.75rem;
    font-weight: 700;
    margin-left: ${p => p.marginLeft};
    margin-right: ${p => p.marginRight};
    padding: 0 !important;
`;

const Th = styled.th`
    width: ${p => p.width};
    text-align: ${p => p.textAlign || "left"};
`;

const Td = styled.td`
    padding: 0 1em !important;
    height: 3.938rem;
    font-size: 0.75rem !important;
    white-space: normal !important;
    text-align: ${p => p.textAlign || "left"};
`;

const TdButton = styled.button`
    height: 2rem;
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    border: ${p => p.border};
    border-radius: 7px;
    width: 6.25rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.75rem;
`;

const ImgContainer = styled.div`
    width: 83px;
    height: 47px;
    display: flex;
    justify-content: center;
    border: 1px solid #8291B2;
    border-radius: 7px;
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    align-self: center;
`;

const OrganisationsTable = ({
    user,
    donationList,
    isFetching,
    allDonationsChecked,
    handleCheckAllDonations,
    checkedDonations,
    handleCheckDonation,
    handleEditDonation,
    handleCreateDonation,
    handleShowDeleteDialog
}) => {

    const [showLinkClicksModal, setShowLinkClicksModal] = useState(false);
    const [donationData, setDonationData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const filteredDonations = donationList.filter(donation => {
        const { organisationName, headline, description } = donation;
        const searchTerms = `${organisationName.toLowerCase()} ${headline.toLowerCase()} ${description.toLowerCase()}`;
        
        if (searchQuery.length > 0) {
            const isMatchingSearch = searchTerms.includes(searchQuery.toLowerCase());
            return isMatchingSearch;
        }

        return true;
    });


    const handleCloseLinkClicksModal = () => {
        setDonationData(null);
        setShowLinkClicksModal(false);
    };

    const handleShowLinkClicksModal = donation => {
        setDonationData(donation);
        setShowLinkClicksModal(true);
    };

    const trimText = text => {
        if (text && text.length > 80) {
            return text.slice(0, 80).trim() + "...";
        }
        return text ?? "";
    };

    const userGroupText = donation => {
        if (donation.displayToEmployees && donation.displayToClients) {
            return "Employees & Clients";
        } else if (donation.displayToEmployees) {
            return "Employees";
        } else if (donation.displayToClients) {
            return "Clients";
        }
    };

    const renderExtraRows = () => {
        const minRows = 10;
        const noOfRowsToRender = minRows - donationList.length;
        const extraRows = [];

        for (let i = 0; i < noOfRowsToRender; i++) {
            extraRows.push(
                <tr key={`empty-${i}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                </tr>
            );
        }

        return extraRows;
    };

    return (
        <>
            { isFetching && (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    color="#fff"
                    style={{
                        maxWidth: "94.5rem",
                        height: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    <LoadingIndicator />
                </FlexContainer>
            )}

            { !isFetching && (
                <>
                    <TableControlsGrid container>
                        <Grid item sm={12} lg={5} xl={7}>
                            <SearchInput placeholder="Search donation" type="text" value={searchQuery} onChange={handleSearchChange} />
                        </Grid>

                        <Grid item sm={12} lg={7} xl={5}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                {checkedDonations.length > 0 && (
                                    <TableButton
                                        backgroundColor="#ff7171"
                                        width="10.75rem"
                                        onClick={() => handleShowDeleteDialog()}
                                    >
                                        Delete Donations
                                    </TableButton>
                                )}
                                <TableButton
                                    backgroundColor="#006cff"
                                    width="13.125rem"
                                    marginLeft="1.4375rem"
                                    marginRight="1.4375rem"
                                    onClick={handleCreateDonation}
                                >
                                    Create Donation
                                </TableButton>
                            </Grid>
                        </Grid>
                    </TableControlsGrid>

                    <div className="scrollable-table" style={{ maxWidth: "94.5rem" }}>
                        <table>
                            <thead>
                                <tr>
                                    <Th width="4%" textAlign="center">
                                        <label>
                                            <Checkbox
                                                key="selectAll"
                                                readOnly={false}
                                                checked={allDonationsChecked}
                                                onChange={handleCheckAllDonations}
                                            />
                                        </label>
                                    </Th>
                                    <Th width="9%">Image</Th>
                                    <Th width="16%">Organisation Name</Th>
                                    <Th width="25%">Heading</Th>
                                    <Th width="10%">User Groups</Th>
                                    <Th width="9%" textAlign="center">Insights</Th>
                                    <Th width="9%" textAlign="center">Edit Donation</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredDonations.map(donation => (
                                    <tr key={donation.uuid}>
                                        <Td textAlign="center">
                                            <label>
                                                <Checkbox
                                                    readOnly={false}
                                                    checked={checkedDonations.includes(donation.uuid)}
                                                    onChange={(e) => handleCheckDonation(e, donation.uuid)}
                                                />
                                            </label>
                                        </Td>
                                        <Td>
                                            <ImgContainer>
                                                <Img src={donation.image.imageUrl} />
                                            </ImgContainer>
                                        </Td>
                                        <Td>{donation.organisationName}</Td>
                                        <Td>{trimText(donation.headline)}</Td>
                                        <Td>{userGroupText(donation) }</Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#eaf3ff"
                                                color="#006cff"
                                                border="1px solid #006cff"
                                                onClick={() => handleShowLinkClicksModal(donation) }
                                            >
                                                View
                                            </TdButton>
                                        </Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#fff"
                                                color="#000"
                                                border="1px solid #000"
                                                onClick={() => handleEditDonation(donation) }
                                            >
                                                Edit
                                            </TdButton>
                                        </Td>
                                    </tr>
                                ))}

                                {renderExtraRows()}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            { showLinkClicksModal && 
                <LinkClicksModal
                    user={user}
                    donationData={donationData}
                    showLinkClicksModal={showLinkClicksModal}
                    handleCloseLinkClicksModal={handleCloseLinkClicksModal}
                />
            }
        </>
    );
};

const LoadingIndicator = () => {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
};

export default OrganisationsTable;