import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../ChatContext";
import { Toast } from "src/components";


export const useSendMessage = () => {
    const { currentChat, matrixClient } = useContext(ChatContext);
    const [newMessage, setNewMessage] = useState("");
    const [queued, setQueued] = useState(null);

    useEffect(() => {
        sendMessage();
    }, [queued]);

    const prepareMessage = () => {
        const data = {
            message: newMessage.trim(),
            isSending: true,
            status: null,
        };

        setNewMessage("");
        setQueued(data);
    };

    const sendMessage = async () => {
        if (queued === null) {
            return;
        }

        if (queued.status !== null) {
            console.warn("Unable to send message while on queue", queued);
            return;
        }

        const message = queued.message;
        const isMessageSendable = message !== undefined && message !== null && message.trim() !== "";
        if (!isMessageSendable) {
            console.warn("Unable to send empty message", message);
            return;
        }

        try {
            /**
             * this will only return the event id but;
             * the timeline listener in `useMatrixClient` should receive the matrix event
             * Note: the timeline listener receives a local echo which is only an acknowledgement that the message was sent,
             * but does not confirm server-side delivery.
             */
            await matrixClient.sendMessage(
                currentChat.matrixRoomId,
                {
                    body: message,
                    msgtype: "m.text",
                    formatted_body: ""
                }
            );

            setQueued(null);
        } catch (err) {
            setQueued(prevQueued => ({
                ...prevQueued,
                status: "failed",
                isSending: false
            }));
            setNewMessage(queued.message);
            return Toast.error("Unable to send the message.");
        }
    };

    return {
        newMessage,
        queued,
        setNewMessage,
        prepareMessage,
    };
};