export const DEFAULT_FORM_STATE = {
    imageUrl: null,
    userGroupType: "",
    organisationName: null,
    headline: null,
    description: null,
    donationPageUrl: null,
};

export const USER_GROUP = {
    EMPLOYEES: "Employees",
    CLIENTS: "Clients",
    BOTH: "All Users"
};

export const API_ERROR_FIELD = {
    USER_GROUP_TYPE: "donationUserGroupType",
    ORGANISATION_NAME: "donationOrganisationName",
    HEADLINE: "donationHeadline",
    DESCRIPTION: "donationDescription",
    PAGE_URL: "donationPageUrl",
    IMAGE: "donationOrganisationImage",
};

export const ERROR_MESSAGES = {
    [API_ERROR_FIELD.USER_GROUP_TYPE]: "Select Donation User Group is required.",
    [API_ERROR_FIELD.ORGANISATION_NAME]: "Name Of Organisation is required.",
    [API_ERROR_FIELD.HEADLINE]: "Headline is required.",
    [API_ERROR_FIELD.DESCRIPTION]: "Donation Description is required.",
    [API_ERROR_FIELD.PAGE_URL]: "Donation Website Page is required.",
    [API_ERROR_FIELD.IMAGE]: "Organisation image is required.",
};