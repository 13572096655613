import axios from "axios";
import { useState } from "react";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

/**
 * Custom hook for deleting a chat
 * @returns {{
 *      isDeletingChat: boolean
 *      deleteChat: Function
 *      showConfirmationModal: boolean
 *      setShowConfirmationModal: Function
 * }}
 */
export const useDeleteChat = () => {
    const [isDeletingChat, setIsDeletingChat] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const deleteChat = async (currentChatUuid, headers) => {
        setIsDeletingChat(true);
        try {
            const url = `${API_URL}/chats/${currentChatUuid}`;
            return await axios.delete(url, headers);
        } catch (error) {
            const message = typeof(error) === "string" ? error : "Cannot delete chat.";
            return Toast.error(message);
        } finally {
            setShowConfirmationModal(false);
            setIsDeletingChat(false);
        }
    };

    return {
        isDeletingChat,
        deleteChat,
        showConfirmationModal,
        setShowConfirmationModal
    };
};