import React from "react";
import styled from "styled-components";
import Skeleton from "../Skeleton";

const CardContainer = styled.div`
    min-width: fit-content;
    max-width: fit-content;
    margin: 1rem 2.5rem 1rem 0;
    `;

const ContentContainer = styled.div`
        position: relative;
        height: 100%;
        /* padding: 10px; */
        /* max-height: auto; */
        min-height: 60px;
        margin-right: 30px;
        display: flex;
    `;

const ContentWrapper = styled.div`
        display: flex;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100px;
    `;

const Card = styled.div`
    min-width: 22.5rem;
    max-width: 22.5rem;
    min-height: ${p => p.cardHeight || "180px"};
    /* max-height: auto; */
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: ${p => p.cardShadow || "0 5px 5px #00000026"};
    border: 1px solid #8291B2;
    border-radius: 10px;
    `;

const CardHeader = styled.div`
    margin: .5rem 1rem;
    width: fit-content;
    /* UI Properties */
    text-align: left;
    font-size: 1.5rem ;
    font: normal normal medium 25px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    `;

const Hr = styled.div`
    border: 1px solid #EFEFEF;
    `;

const TextLabel = styled.div`
    top: 538px;
    left: 350px;
    width: fit-content;
    height: 38px;
    text-align: left;
    font: normal normal normal 21px Roboto;
    letter-spacing: 0;
    color: #8291B2;
    opacity: 1;
    margin: 1rem;
    margin-left: 0.7rem;
`;

const SkeletonContainer = styled.div`
    margin: .5rem 1rem;
    /* width: 200px; */
    `;

const MoodRatingContainer = styled.div`
    margin: 0.5rem 0.5rem -0.5rem 1.2rem;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    flex-direction: row;
    display: flex
    `;

const PrintableMoodRatingCard = (props, children) => {
    const { header, footer, loading, cardHeight, loadingLineGraph, moodData, printFormat } = props;
    const moodEmoji = {
        happy: "😁",
        content: "😊",
        neutral: "😐",
        sad: "😔",
        unhappy: "😢",
        tired: "😴",
        stressed: "😬",
    };
    return (
        <>
            <CardContainer>
                <Card cardHeight={cardHeight} cardShadow={printFormat ? "unset" : "0 5px 5px #00000026;"}>
                    <CardHeader>
                        {loading ?
                            <Skeleton variant="Text" width="100px" height="20px" animation="wave" />
                            : header
                        }
                    </CardHeader>
                    <Hr />
                    {loading ?
                        <SkeletonContainer>
                            <Skeleton variant="Text" width="50px" height="33px" animation="wave" />
                        </SkeletonContainer>
                        :
                        <ContentContainer>
                            <ContentWrapper>
                                { loadingLineGraph ?
                                    <SkeletonContainer>
                                        <Skeleton variant="Text" width="60px" height="40px" animation="wave" />
                                    </SkeletonContainer>
                                    :
                                    <MoodRatingContainer>
                                        <div style={{ margin: "0px 10px 0px 0px", textTransform: "capitalize" }}>
                                            { moodData.overAllMoodRating && moodData.overAllMoodRating !== "No Data" && !loadingLineGraph ?
                                                `${moodEmoji[moodData.overAllMoodRating]} ${moodData.overAllMoodRating}`
                                                : "No Data" 
                                            }
                                        </div>
                                    </MoodRatingContainer>
                                }
                            </ContentWrapper>
                        </ContentContainer>
                    }
                </Card>
                <TextLabel size=".5rem">
                    {footer}
                </TextLabel>
            </CardContainer>
        </>
    );
};
export default PrintableMoodRatingCard;