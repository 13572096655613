import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ChatContext } from "../../ChatContext";
import { SearchInput } from "../CreateChatModal";
import { Text } from "src/components";
import UserAvatar from "../UserAvatar";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";
import { capitalizeString, checkIfUserIsOnline } from "src/utils/helpers";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";


const UsersListContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 1rem;
    padding-right: 1.125rem;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        margin-top: 2.5rem;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }
`;

const Table = styled.table`
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background: transparent;
`;

const TH = styled.th`
    color: #4B5155;
    font-size: 1rem;
    padding: 0 0 1.1875rem 1.4375rem;
    width: 30%;
    min-width: 8rem;
`;

const TD = styled.td`
    background-color: transparent;
    min-width: 8rem;
`;

const ContentBox = styled.div`
    position: relative;
    height: 4.375rem;
    min-width: 9.375rem;
    padding: 0.75rem 1.4375rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const Row = styled.tr`
    width: 100%;
    border: ${ p => p.isSelected ? "1px solid #000000" : "1px solid #DBE5ED" };
    outline: ${ p => p.isSelected ? "1px solid #000000" : "1px solid #DBE5ED" };
    font-weight: ${ p => p.isSelected ? "600" : "" };
    background: ${p => p.isSelected ? "#F4F4F4 !important" : "white" };
    box-sizing: border-box;
    :first-child {
        border: ${p => p.isSelected ? "1px solid #000000" : "1px solid #DBE5ED" };
    }
`;

const BlankRow = styled.tr`
    width: 100%;
    border: none;
    font-weight: ${ p => p.isSelected ? "600" : "" };
    background: none;
    box-sizing: border-box;
`;

const SelectionButton = styled.div`
    cursor: pointer;
    height: 1.625rem;
    width: 1.625rem;
    box-sizing: border-box;
    border-radius: 50%;
    border:  ${p => p.checked ? "0.4375rem solid #006CFF" : "1px solid #707070"};
`;

const Single = ({ usersToDisplay, handleSearch, handleSelectedEmployeesToChat, hasLeadershipRoles }) => {
    const currentUser = useContext(UserContext);
    const createChatType = currentUser[UserKeys.IS_CLIENT] ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES;
    const { selectedChatListTypeOrLeadershipUuid, currentUserEmployeeUuid, onlineUsersMatrixId } = useContext(ChatContext);
    const [selectedUser, setSelectedUser] = useState("");

    const isEmployee = selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES;

    const handleUserSelection = (uuid) => {
        setSelectedUser(uuid);
        handleSelectedEmployeesToChat([uuid]);
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const isSelected = (uuid) => selectedUser === uuid;

    const isThisUserOnline = (userMatrixId) => checkIfUserIsOnline(onlineUsersMatrixId, userMatrixId);

    const noUsersToDisplay = () => {
        return (
            <BlankRow>
                <TD colSpan="4">
                    <ContentBox>No users to display</ContentBox>
                </TD>
            </BlankRow>
        );
    };

    const renderUsersList = () => {
        if (!usersToDisplay || usersToDisplay.length === 0) {
            return noUsersToDisplay();
        }

        return usersToDisplay.map(user => {
            if (user.uuid !== currentUserEmployeeUuid) {
                return (
                    // different details for clients
                    <Row key={user.uuid} isSelected={isSelected(user.uuid)}>
                        <TD style={{ width: "35%", borderRight: "1px solid #DBE5ED" }}>
                            <ContentBox>
                                <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                                    photo={user.profilePictureUrl}
                                    background="#D6D6D6"
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    statusBorder="1px solid #fff"
                                    hideStatus={true}
                                    withShadow={true}
                                    borderPixel="1px"
                                    showPresenceStatus={true}
                                    isOnline={ isThisUserOnline(user?.matrixUserId) }
                                />
                                { capitalizeString(user.firstName) + " " + capitalizeString(user.lastName) }
                            </ContentBox>
                        </TD>
                        <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}><ContentBox>{ user.department }</ContentBox></TD>
                        <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}><ContentBox>{ user.employmentPosition }</ContentBox></TD>
                        <TD style={{ width: "15%" }}>
                            <ContentBox className="flex-centered-content">
                                <SelectionButton 
                                    onClick={() => handleUserSelection(user.uuid)}
                                    checked={selectedUser === user.uuid}
                                />
                            </ContentBox>
                        </TD>
                    </Row>
                );
            }
        });
    };

    return (
        <React.Fragment>
            <Text align="left" color="#4B5155" weight="700" size="1rem" margin="0 0 0.8125rem 0">
                { !hasLeadershipRoles && `Select ${isEmployee ? "Employee" : "Client" }` }
            </Text>
            <SearchInput onChange={(e) => handleSearch(e.target.value)}
                placeholder={ `Search ${Capitalize(createChatType)}` }
                margin="0 0 1.5rem 0"
            />
            <UsersListContainer>
                <Table>
                    <thead style={{ width: "100%", position: "sticky", top: "0px", zIndex: "150", backgroundColor: "#F9FAFC" }}>
                        <tr style={{ width: "100%" }}>
                            <TH style={{ width: "35%" }}>Name</TH>
                            <TH style={{ width: "25%" }}>{ isEmployee ? "Department / Group" : "Client Type" }</TH>
                            <TH style={{ width: "25%" }}>{ isEmployee ? "Job Title" : "" }</TH>
                            <TH style={{ width: "15%" }}>Select User</TH>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "auto", height: "auto" }}>
                        {renderUsersList()}
                    </tbody>
                </Table>
            </UsersListContainer>
        </React.Fragment>
    );
};

export default Single;