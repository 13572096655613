import React from "react";
import { FlexContainer, LottieLoadingIndicator, Text } from "src/components";
import SurveyRatingCard from "./SurveyRatingCard";
import SurveySatisfactionCard from "./SurveySatisfactionCard";
import SurveyYesNoCard from "./SurveyYesNoCard";
import { SurveyFeedbackCard } from "./SurveyFeedbackCard";
import { EnumSurveyType } from "../Enums";
import styled from "styled-components";
import { SurveyStatusType } from "src/constants/survey";

const RealTimeDataButton = styled.button`
    height: 49px;
    width: 150px;
    color: #36BE24;
    background-color: #E6FFE3;
    border: 2px solid #36BE24;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 15px/18px Roboto, Helvetica, sans-serif;
    letter-spacing: 0;
`;

const surveyTypeIdentifier = (surveyType, survey, surveyQuestion, numberOfUsersInTargetGroup, responsesStats, fetchingResponseStats, user) => {
    if (fetchingResponseStats) {
        return <LottieLoadingIndicator/>;
    }
    switch (surveyType) {
        case EnumSurveyType.YESNO:
            return <SurveyYesNoCard surveyQuestion={surveyQuestion} numberOfUsersInTargetGroup={numberOfUsersInTargetGroup} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats}/>;
        case EnumSurveyType.FEEDBACK:
            return <SurveyFeedbackCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} user={user} survey={survey} />;
        case EnumSurveyType.RATING:
            return <SurveyRatingCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} />;
        case EnumSurveyType.SATISFACTION:
            return <SurveySatisfactionCard surveyQuestion={surveyQuestion} responsesStats={responsesStats} fetchingResponseStats={fetchingResponseStats} />;
        default:
            break;
    }
};

const SurveyQuestions = (props) => {
    const { insights, surveyType, survey, responsesStats, fetchingResponseStats, user } = props;

    return (
        <FlexContainer align="center" style={{ width: "100%" }} >
            <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="space-between" >
                <div>
                    <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                        Question 1
                    </Text>
                    <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                        {insights.surveyQuestions[0].question}
                    </Text>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    { survey.status === SurveyStatusType.ACTIVE &&
                        <RealTimeDataButton>
                            Real-Time Data
                        </RealTimeDataButton>
                    }
                </div>
            </FlexContainer>
            {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[0], insights.numberOfUsersInTargetGroup, responsesStats, fetchingResponseStats, user)}
            {
                insights.surveyQuestions[1] &&
                    <>
                        <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="space-between" >
                            <div>
                                <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                                    Question 2
                                </Text>
                                <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                                    {insights.surveyQuestions[1].question}
                                </Text>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                { survey.status === SurveyStatusType.ACTIVE &&
                                    <RealTimeDataButton>
                                        Real-Time Data
                                    </RealTimeDataButton>
                                }
                            </div>
                        </FlexContainer>
                        {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[1], insights.numberOfUsersInTargetGroup, responsesStats, fetchingResponseStats, user)}
                    </>
            }
            {
                insights.surveyQuestions[2] &&
                    <>
                        <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="space-between" >
                            <div>
                                <Text color="#000" size="20px" weight="bold" align="left" margin="20px 0 10px 0">
                                    Question 3
                                </Text>
                                <Text color="#000" weight="500" size="15px" align="left" margin="0 0 15px 0">
                                    {insights.surveyQuestions[2].question}
                                </Text>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                { survey.status === SurveyStatusType.ACTIVE &&
                                    <RealTimeDataButton>
                                        Real-Time Data
                                    </RealTimeDataButton>
                                }
                            </div>
                        </FlexContainer>
                        {surveyTypeIdentifier(surveyType, survey, insights.surveyQuestions[2], insights.numberOfUsersInTargetGroup, responsesStats, fetchingResponseStats, user)}
                    </>
            }
        </FlexContainer>
    );
};

export default SurveyQuestions;