import React, { createRef } from "react";

function AuthenticatedLink(props) {
    const { url, filename, user, children, ...styleProps } = props;
    const link = createRef();

    const handleAction = async () => {
        if (link.current.href) { return; }
    
        const result = await fetch(url, {    
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
        
        const blob = await result.blob();

        const href = window.URL.createObjectURL(blob);
        
        link.current.download = filename;
        link.current.href = href;
        
        link.current.click();
    };

    return (
        <>
            <a role="button" {...styleProps} ref={link} onClick={handleAction}>{children}</a>
        </>
    );
}

export default AuthenticatedLink;