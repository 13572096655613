import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { calculateTimelineMessageDate } from "src/utils/dates";
import UserAvatar from "./UserAvatar";
import { useState } from "react";
import { UserKeys } from "src/constants/userDetails";
import { UserContext } from "src/scenes/App/UserContext";

const RowContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${p => p.isChatByCurrentUser ? "row-reverse" : "row"};
    box-sizing: border-box;
    margin-bottom: 0.4375rem;
`;

const Bubble = styled.div`
    position: relative;
    box-shadow: 0rem 0.0625rem 0.3125rem #0000000D;
    background: ${p => p.isChatByCurrentUser ? "#001191" : "#FFFFFF"};
    color: ${p => p.isChatByCurrentUser ? "#FFFFFF" : "#000000"};
    border-radius: ${p => p.borderRadius};
    height: auto;
    width: fit-content;
    max-width: 28.1875rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.7rem 1.25rem;
    box-sizing: border-box;
    line-height: 1.5;
    align-items: center;
    margin: 0;
`;

const AvatarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${p => p.isChatByCurrentUser ? "row-reverse" : "row"};
    align-items: center;
    margin: 0.75rem 0 0.625rem 0;
`;

const AvatarName = styled.div`
    font-weight: 500;
    font-size: 0.8125rem;
    color: #000;
    margin: 0 0.5625rem;
`;

const TimeStamp = styled.div`
    font-size: 0.8125rem;
    color: #AFBBC6;
    font-weight: 400;
    margin: 0.4375rem 0 1rem 0;
    display: ${p => p.display};
    flex-direction: ${p => p.isChatByCurrentUser ? "row-reverse" : "row"};
    text-align: right;
`;

const ClickableResendText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const ChatBubble = (props) => {
    const { message, timestamp, photo, firstName, lastName, isChatByCurrentUser, 
        isFirstOfConsecutive, isLastOfConsecutive, 
        isSending, showTimeStamp
    } = props;
    const currentUser = useContext(UserContext);

    const notDelivered = props.status === "failed" || props.isSending;
    const [messageToDisplay, setMessageToDisplay] = useState(message ?? "...");

    const determineBorderRadius = () => {
        let radius = "0.625rem";
        // Stand-alone message (both previous and next message is sent by another user)
        if (isFirstOfConsecutive && isLastOfConsecutive) {
            radius = isChatByCurrentUser ? "0.625rem 0.625rem 0 0.625rem" : "0.625rem 0.625rem 0.625rem 0";
        }
        // conditions below are for messages thread consecutively sent by the same user
        // the first of the messages thread
        else if (isFirstOfConsecutive && !isLastOfConsecutive) {
            radius = isChatByCurrentUser ? "0.625rem 0rem 0.625rem 0.625rem" : "0rem 0.625rem 0.625rem 0.625rem";
        }
        // the last message of the thread
        else if (isLastOfConsecutive && !isFirstOfConsecutive) {
            radius = isChatByCurrentUser ? "0.625rem 0.625rem 0 0.625rem" : "0.625rem 0.625rem 0.625rem 0rem";
        }
        // for threads that are between the first and last message
        else if (!isFirstOfConsecutive && !isLastOfConsecutive) {
            radius = isChatByCurrentUser ? "0.625rem 0rem 0rem 0.625rem" : "0rem 0.625rem 0.625rem 0rem";
        }
        /**
         * Note: these bubbles are displayed flex, column-reverse - see ChatMessages <MessagesContainer>
         * the first of the thread are at the bottom while the last are somewhere up top
         */
        return radius;
    };

    const renderTimeStampContent = () => {
        if (isSending) {
            return "Sending message...";
        }
        else if (!isSending && notDelivered) {
            return <div>
                Message not delivered.&nbsp;
                <ClickableResendText onClick={() => props.resendMessage()}>Resend</ClickableResendText>
                &nbsp;|&nbsp;
                <ClickableResendText onClick={() => props.deleteUndeliveredMessage()}>Delete</ClickableResendText>
            </div>;
        } // above conditions handles UI for new message and a failed message 
        else {
            return timestamp ? calculateTimelineMessageDate(timestamp)["time"] : "";
        }
    };

    useEffect(() => {
        if (message !== undefined) {
            setMessageToDisplay(message);
        }
    }, [message]);

    return (
        <React.Fragment>
            <RowContainer isChatByCurrentUser={isChatByCurrentUser}>
                <div style={{ 
                    display: "flex", flexDirection: "column",
                    margin: "0 0.9375rem", padding: 0
                }}>
                    { isLastOfConsecutive && 
                        <AvatarContainer isChatByCurrentUser={isChatByCurrentUser}>
                            <UserAvatar width="2.75rem" height="2.75rem" hideInfo={true}
                                photo={isChatByCurrentUser ? currentUser[UserKeys.PROFILE_PICTURE_URL] : photo}
                                firstName={firstName}
                                lastName={lastName}
                                statusBorder="2px solid #fff"
                                withShadow={false}
                                borderPixel="2px"
                                margin="0 0 0 0"
                                showPresenceStatus={false}
                            />
                            <AvatarName>{ isChatByCurrentUser ? "Me" : firstName + " " + lastName }</AvatarName>
                        </AvatarContainer>
                    }
                    
                    <Bubble isChatByCurrentUser={isChatByCurrentUser}
                        borderRadius={determineBorderRadius()}
                        style={{
                            opacity: notDelivered ? 0.5 : 1,
                            alignSelf: notDelivered ? "end" : 
                                isChatByCurrentUser ? "end" : "start"
                        }}
                    >
                        { messageToDisplay }
                    </Bubble>
                    <TimeStamp
                        isChatByCurrentUser={isChatByCurrentUser}
                        display={showTimeStamp ? "flex" : "none"}
                    >
                        { renderTimeStampContent() }
                    </TimeStamp>
                </div>
            </RowContainer>
        </React.Fragment>
    );
};

export default ChatBubble;