import React, { useContext, useEffect, useState } from "react";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { UserContext } from "src/scenes/App/UserContext";
import { Select, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { FlexContainer, Text } from "src/components";
import { ChatContext } from "../../ChatContext";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
            background: "none"
        },
    }
}));

const selectStyle = {
    height: "2.5625rem",
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "0.875rem",
    background: "#FFFFFF",
    border: "1px solid #000000",
    color: "#4B5155",
    paddingLeft: "0.5rem",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&:hover": {
        backgroundColor: "transparent",
    },
};

const LeadershipRoleToUseSelection = (props) => {
    const { leadershipRoles } = useContext(UserContext);
    const userContext = useContext(UserContext);
    const { selectedChatListTypeOrLeadershipUuid } = useContext(ChatContext);
    
    const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
    const useLoggedInUserTypeForStartChatAs = userContext.isClient === selectedChatListTypeOrLeadershipUuid ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES; 
    const [selectedLabel, setSelectedLabel] = useState(isNonLeadership ? useLoggedInUserTypeForStartChatAs : leadershipRoles[0].name);

    const classes = useStyles();

    const isListTypeLeadershipRoleUuid = listType => {
        return listType && listType !== USER_TYPE_EMPLOYEES && listType !== USER_TYPE_CLIENTS;
    };

    useEffect(() => {
        const selectedLabel = isNonLeadership ? useLoggedInUserTypeForStartChatAs : leadershipRoles[0].name;
        selectLeadershipRole(selectedLabel);
    }, [leadershipRoles]);

    const selectLeadershipRole = (optionValue) => {

        setSelectedLabel(optionValue);
        if (!isListTypeLeadershipRoleUuid(optionValue)) {
            props.setFormData({ ...props.formData, leadershipRoleUuid: undefined });
        } else {
            const lrItem = leadershipRoles && leadershipRoles.find((item) => item.name === optionValue);
            if (lrItem) {
                props.setFormData({ ...props.formData, leadershipRoleUuid: lrItem.uuid });
            }
        }
    };

    const renderSelectionItems = () => {
        if (leadershipRoles.length > 0) {
            return leadershipRoles.map(role =>
                <MenuItem key={role.uuid} value={role.name}
                >
                    Start chat as &quot;{ role.name }&quot;
                </MenuItem>
            );
        }
        return "";
    };

    const chatAsEmployee = !userContext.isClient;
    const chatAsClient = userContext.isClient; 

    return (
        <FlexContainer>
            <Text color="#4B5155" size="1rem" align="left" margin="0 0 0.8125rem 0" weight="700">
                Select how you will appear in this chat:
            </Text>
            <Select onChange={(e) => selectLeadershipRole(e.target.value) } value={selectedLabel} variant="outlined" style={selectStyle} classes={{ outlined: classes.outlined }} placeholder={`Start chat as &quot;${selectedLabel}&quot;`}>
                { chatAsEmployee && <MenuItem value={USER_TYPE_EMPLOYEES}>Start chat as &quot;Employee&quot;</MenuItem> }
                { chatAsClient && <MenuItem value={USER_TYPE_CLIENTS}>Start chat as &quot;Client&quot;</MenuItem> }
                { renderSelectionItems() }
            </Select>
        </FlexContainer>
    );
};

export default LeadershipRoleToUseSelection;