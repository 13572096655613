import React, { useState, useEffect } from "react";
import { Button, Text, LoadingIndicator } from "src/components";
import { Dialog, Box, Divider, Grid } from "@material-ui/core";
import { generateNameInitials } from "src/utils/helpers";
import styled from "styled-components";
import getDonationClickVisitors from "../hooks/getDonationClickVisitors";
import { USER_GROUP } from "src/constants/donations";
import DropdownIcon from "src/img/new/drop-down.svg";

const ModalFilter = styled.select`
    height: 2.438rem;
    width: 11.125rem;
    border-radius: 7px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        outline: none;
    }
`;

const LinkClicksModal = ({ donationData, showLinkClicksModal, handleCloseLinkClicksModal, user }) => {
    const { donationClickVisitors, isFetchingVisitors } = getDonationClickVisitors(user.token, donationData);
    
    const userTypeDefault = () => {
        if (donationData.displayToEmployees && donationData.displayToClients) {
            return USER_GROUP.EMPLOYEES; //default to employees
        } else if (donationData.displayToEmployees) {
            return USER_GROUP.EMPLOYEES;
        } else if (donationData.displayToClients) {
            return USER_GROUP.CLIENTS;
        }
    };

    const filterVisitorsByUserType = (userType) => {
        return userType === USER_GROUP.EMPLOYEES ? 
            donationClickVisitors.employees : 
            donationClickVisitors.clients;
    };

    const [userType, setUserType] = useState(userTypeDefault());
    const [viewersList, setViewersList] = useState(filterVisitorsByUserType(userTypeDefault()));

    const handleUserTypeChange = (e) => {
        const value = e.target.value;
        setUserType(value);
    };
    
    useEffect(() => {
        const filteredList = filterVisitorsByUserType(userType);
        setViewersList(filteredList);
    }, [userType, isFetchingVisitors]);

    const getTotalLinkClicks = (viewersList) => {
        if (viewersList.length === 0)
            return 0;

        return viewersList.map(employeeDetails => employeeDetails.numberOfClicks).reduce((total, numberOfClicks) => total + numberOfClicks, 0);
    };
    
    const clickedCount = viewersList ? getTotalLinkClicks(viewersList) : 0;
    const viewsCountFormatted = `${parseInt(clickedCount).toLocaleString("en-us")} ${clickedCount > 1 || clickedCount === 0 ? "clicks" : "click"}`;

    return (
        <Dialog
            open={showLinkClicksModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 980,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Grid container>
                    <Grid item md={6}>
                        <Text size="2.313rem" weight="700" align="left" margin="0">
                            Link Clicks
                        </Text>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: "right" }}>
                        { 
                            !isFetchingVisitors &&
                            <ModalFilter value={userType} onChange={handleUserTypeChange}>
                                {donationData.displayToEmployees && <option value={USER_GROUP.EMPLOYEES}>Employees</option>}
                                {donationData.displayToClients && <option value={USER_GROUP.CLIENTS}>Clients</option>}
                            </ModalFilter>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <Text size="1.25rem" weight="700" align="left">Donation Engagement Stats</Text>
                
                <Grid container>
                    <Grid item md={6}>
                        <Text size="1rem" align="left">
                            Track the performance of your donations by viewing the 
                            number of link clicks each cause has received.
                        </Text>
                    </Grid>
                    <Grid item md={6}>
                        { !isFetchingVisitors && <Text size="2.313rem" weight="700" align="right" margin="0">{viewsCountFormatted}</Text>}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <>  
                    { isFetchingVisitors 
                        ? <LoadingIndicator height="1.563rem" width="1.563rem" containerHeight="0" /> 
                        : <ViewersListPreview viewersList={viewersList} isReaderTypeEmployee={userType === USER_GROUP.EMPLOYEES} />
                    }   
                </>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ textAlign: "right" }}>
                        <>
                            <Button
                                onClick={handleCloseLinkClicksModal}
                                size="0.875rem"
                                border="1px solid #000"
                                color="#000"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                            >
                                Cancel
                            </Button>
                        </>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

const ContainerOverflow = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 1em;

    ::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #174A84;
        border-radius: 6px;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #D1DBE6;
        border-radius: 6px;
        margin-right: 30px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #D1DBE6;
        border-radius: 6px;
    }
`;

const TablePreview = styled.div`
    .thead * {
        font-weight: 700;
    }
    .tbody * {
        font-weight: 400;
    }
    .tbody, .thead{
        display: table;
        width: 100%;
    }

    .tbody > *, .thead > * {
        display: table-row;
    }

    .tbody .col, .thead .col {
        display: table-cell;
        padding: 12px 16px 12px 16px;
        width: calc(100% / 4); /* Assuming 3 columns */
    }

    .col-fullname-width {
        width: calc(100% / 2) !important;
    }

    .tbody .col {
        border-left: 1px solid #8291B2;
        border-top: 1px solid #8291B2;
    }

    .tbody .lastcol {
        border-right: 1px solid #8291B2;
    }

    .tbody .lastrow > .col {
        border-bottom: 1px solid #8291B2;
    }
`;

const CircledInitials = styled.div`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 42px;
    height: 42px;
    background-color: #D6D6D6;
    color: #6F6F6F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
`;

const UserRow = ({ index, maxIndex, uuid, nameInitials, fullName, department, jobTitle, isReaderTypeEmployee }) => {
    const isFirstRow = index === 0;
    const isLastRow = index === maxIndex;
    const isOnlyOneRow = isFirstRow && isLastRow;

    const topLeftRadius = "8px 0px 0px 0px";
    const topRightRadius = "0px 8px 0px 0px";
    const bottomLeftRadius = "0px 0px 0px 8px";
    const bottomRightRadius = "0px 0px 8px 0px";

    let divStyleFirstColumn = isFirstRow ? { borderRadius: topLeftRadius } : isLastRow ? { borderRadius: bottomLeftRadius } : {};
    let divStyleLastColumn = isFirstRow ? { borderRadius: topRightRadius } : isLastRow ? { borderRadius: bottomRightRadius } : {};

    if (isOnlyOneRow) {
        divStyleFirstColumn = { borderRadius: "8px 0px 0px 8px" };
        divStyleLastColumn = { borderRadius: "0px 8px 8px 0px" };
    }

    return (
        <div className={`row ${isLastRow ? "lastrow" : ""}`} key={uuid + "_" + index}>
            <div className="col col-fullname-width" style={ divStyleFirstColumn }>
                <div style={{ display: "inline-block" }}><CircledInitials>{nameInitials}</CircledInitials></div> {fullName}
            </div>
            {isReaderTypeEmployee && <div className="col">{department}</div>}
            <div className={"col lastcol"} style={ divStyleLastColumn }>{jobTitle}</div>
        </div>
    );
};

const ViewersListPreview = ({ viewersList, isReaderTypeEmployee }) => {

    if (!viewersList || viewersList.length === 0) {
        return ("No user clicks found");
    }

    return (
        <TablePreview>
            <div className="thead ">
                <div className="row" key="theadrow">
                    <div className="col col-fullname-width">{isReaderTypeEmployee ? "Employee" : "Client" } Name</div>
                    {isReaderTypeEmployee && <div className="col">Department / Group</div>}
                    <div className="col">Job Title</div>
                </div>
            </div>

            <ContainerOverflow>
                <div className="tbody">
                    {
                        viewersList.map((employee, index) => {
                            const nameInitials = generateNameInitials(employee.firstName, employee.lastName);
                            const fullName = employee.firstName + " " + employee.lastName;
                            return (
                                <UserRow 
                                    key={index + fullName}
                                    index={index}
                                    maxIndex={viewersList.length - 1}
                                    uuid={employee.uuid}
                                    nameInitials={nameInitials}
                                    fullName={fullName}
                                    department={employee.department} 
                                    jobTitle={employee.employmentPosition} 
                                    isReaderTypeEmployee={isReaderTypeEmployee}
                                />
                            );
                        })
                    }
                </div>
            </ContainerOverflow>
        </TablePreview>
    );
};

export default LinkClicksModal;