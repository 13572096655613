export const SortableColumns = {
    SURVEY_NAME: 0,
    SURVEY_TYPE: 1,
    USER_GROUP: 2,
    START_DATE: 3,
    FINISH_DATE: 4,
    STATUS: 5
};

export const SortablePollColumns = {
    POLL_QUESTION: 0,
    NUMBER_OF_OPTIONS: 1,
    POLL_VISIBILITY: 2,
    START_DATE: 3,
    FINISH_DATE: 4,
    STATUS: 5
};

export const SortableColumnState = {
    INACTIVE: 0,
    DESCENDING: 1,
    ASCENDING: 2,
};


export const PageType = {
    ACTIVE: 1,
    SCHEDULED: 2,
    COMPLETE: 3,
    RECENT: 4,
};

export const SurveyStatusType = {
    ACTIVE: "Active",
    SCHEDULED: "Scheduled",
    COMPLETE: "Complete",
};

export const SurveyType = {
    RATING: "Rating",
    SATISFACTION: "Satisfaction",
    YESNO: "Yes/No",
    FEEDBACK: "Feedback",
};

export const InsightSortableColumns = {
    NAME: 0,
    EMAIL: 1,
    QUESTION_ONE: 2,
    QUESTION_TWO: 3,
    QUESTION_THREE: 4,
    STATUS: 5
};

export const TableType = {
    Surveys: "Surveys",
    Polls: "Polls"
};
