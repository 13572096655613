import React from "react";
import styled from "styled-components";
import { PageHeadingLabel, ExplainParagraphLabel } from "src/components";
import OrganisationsTable from "./components/OrganisationsTable";

const HeaderTwoColumns = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 94.5rem;
    margin: 0 0 1.4rem 0 !important;
`;

const TablePage = ({
    user,
    donationList,
    isFetchingDonationList,
    handleEditDonation,
    handleCreateDonation,
    handleShowDeleteDialog,
    handleCheckDonation,
    handleCheckAllDonations,
    allDonationsChecked,
    checkedDonations
}) => {

    return (
        <>
            <PageHeadingLabel>
                Donations
            </PageHeadingLabel>

            <HeaderTwoColumns className="row">
                <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                    Create and manage donation opportunities for your business within the Me Business app. Upload an 
                    organisation’s logo, provide details about the cause, and include a link or button to direct users to the 
                    donation page. Engage your users in supporting meaningful causes through your platform.
                </ExplainParagraphLabel>
            </HeaderTwoColumns>

            <OrganisationsTable
                user={user}
                donationList={donationList}
                isFetching={isFetchingDonationList}
                allDonationsChecked={allDonationsChecked}
                handleCheckAllDonations={handleCheckAllDonations}
                checkedDonations={checkedDonations}
                handleCheckDonation={handleCheckDonation}
                handleEditDonation={handleEditDonation}
                handleCreateDonation={handleCreateDonation}
                handleShowDeleteDialog={handleShowDeleteDialog}
            />
        </>
    );
};

export default TablePage;