import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { PageContainer, Toast } from "src/components";
import TablePage from "./TablePage";
import usePerks from "./hooks/usePerks";
import FormPage from "./FormPage";
import { DEFAULT_FORM_STATE, USER_GROUP, PERK_TYPE, PERK_STATUS, API_ERROR_FIELD, PROMO_CODE_TYPE } from "src/constants/perks";
import { API_URL } from "src/scenes/App";
import moment from "moment";
import DeleteDialog from "./components/DeleteDialog";

const Perk = ({ user }) => {
    const [filterType, setFilterType] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [filterStatus, setFilterStatus] = useState(PERK_STATUS.ACTIVE);
    const [formData, setFormData] = useState(DEFAULT_FORM_STATE);
    const [updatingUuid, setUpdatingUuid] = useState(null);
    const { perks, setPerks, isFetching, refetchPerks } = usePerks(user.token);
    const [allPerksChecked, setAllPerksChecked] = useState(false);
    const [checkedPerks, setCheckedPerks] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isFormSaving, setIsFormSaving] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [promoCodeType, setPromoCodeType] = useState(PROMO_CODE_TYPE.TEXT);

    useEffect(() => {
        if (formData.promoCode) {
            setPromoCodeType(PROMO_CODE_TYPE.TEXT);
        } else if (formData.promoCodeImageUrl) {
            setPromoCodeType(PROMO_CODE_TYPE.IMAGE);
        }
    }, [formData]);

    const handleCheckAllPerks = () => {
        const checked = !allPerksChecked;
        setAllPerksChecked(checked);
        const checkedPerks = checked ? perks.map(perk => perk.uuid) : [];
        setCheckedPerks(checkedPerks);
    };

    const handleCheckPerk = (e, perkUuid) => {
        const { checked } = e.target;
        setCheckedPerks(prevCheckedPerks => {
            if (checked) {
                return [...prevCheckedPerks, perkUuid];
            } else {
                return prevCheckedPerks.filter(uuid => uuid !== perkUuid);
            }
        });
    };

    const handleFormVisible = () => {
        setUpdatingUuid(null);
        setIsFormVisible(!isFormVisible);
        setFormData(DEFAULT_FORM_STATE);
        setFormErrors([]);
    };

    const handleChangePerkType = (perkType) => {
        if (perkType === PERK_TYPE.GENERAL) {
            setFormData({
                ...formData,
                type: perkType,
                selectedCompanyRecipients: []
            });
        } else if (perkType === PERK_TYPE.GIFT) {
            setFormData({
                ...formData,
                type: perkType,
                allEmployeesInPerk: false,
                allClientsInPerk: false
            });
        }
    };

    const handleInputChange = e => {
        const { name, value } = e.target;

        removeErrorMessage(name);

        setFormData(prevFormData => {
            return { ...prevFormData, [name]: value };
        });
    };

    const handleChangeUserGroup = value => {
        removeErrorMessage(API_ERROR_FIELD.RECIPIENTS);

        if (value === USER_GROUP.BOTH) {
            setFormData({
                ...formData,
                allEmployeesInPerk: true,
                allClientsInPerk: true,
            });
        } else if (value === USER_GROUP.EMPLOYEES) {
            setFormData({
                ...formData,
                allEmployeesInPerk: true,
                allClientsInPerk: false
            });
        } else if (value === USER_GROUP.CLIENTS) {
            setFormData({
                ...formData,
                allEmployeesInPerk: false,
                allClientsInPerk: true
            });
        }
    };

    const handlePromoCodeChange = (e) => {
        const { name, value } = e.target;

        removeErrorMessage(name);

        if (value === PROMO_CODE_TYPE.TEXT) {
            setPromoCodeType(PROMO_CODE_TYPE.TEXT);
            setFormData({
                ...formData,
                promoCodeImageUrl: null
            });
        } else if (value === PROMO_CODE_TYPE.IMAGE) {
            setPromoCodeType(PROMO_CODE_TYPE.IMAGE);
            setFormData({
                ...formData,
                promoCode: ""
            });
        }
    };

    const dataUrlToFile = (dataUrl, fileName) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };

    const handleEditPerk = perk => {
        setUpdatingUuid(perk.uuid);
        setIsFormVisible(true);
        setFormData({
            imageUrl: perk.image.imageUrl,
            type: perk.type,
            allEmployeesInPerk: perk.allEmployeesInPerk,
            allClientsInPerk: perk.allClientsInPerk,
            selectedCompanyRecipients: perk.selectedCompanyRecipients,
            startDate: perk.startDate,
            endDate: perk.endDate,
            companyName: perk.companyName,
            title: perk.title,
            description: perk.description,
            website: perk.website,
            promoCode: perk.promoCode,
            promoCodeImageUrl: perk.promoCodeImageUrl
        });
    };

    const handleCreateOrUpdateWithImages = async (perkImage, promoCodeImage) => {
        try {
            setIsFormSaving(true);

            if (perkImage) {
                const image = dataUrlToFile(perkImage, "perk-image");
                const fileExtension = image.type.split("/").pop();
                const s3Response = await axios.get(`${API_URL}/perks/generateImageUrl/${fileExtension}`, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });

                const fullUrl = s3Response.data.url;
                const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
                formData.imageUrl = storedUrl;

                await axios.put(fullUrl, image, {
                    headers: { "Content-Type": image.type }
                });
            }

            if (promoCodeImage) {
                const image = dataUrlToFile(promoCodeImage, "promo-code-image");
                const fileExtension = image.type.split("/").pop();
                const s3Response = await axios.get(`${API_URL}/perks/generateImageUrl/${fileExtension}`, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });

                const fullUrl = s3Response.data.url;
                const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
                formData.promoCodeImageUrl = storedUrl;

                await axios.put(fullUrl, image, {
                    headers: { "Content-Type": image.type }
                });
            }

            if (updatingUuid) {
                const response = await axios.put(`${API_URL}/perks/${updatingUuid}`, formData, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });
                const updatedPerk = response.data.perk;
                setPerks(prevPerks => {
                    const updatedPerks = prevPerks.map(perk => {
                        if (perk.uuid === updatingUuid) {
                            return updatedPerk;
                        } else {
                            return perk;
                        }
                    });
                    return updatedPerks;
                });
                setUpdatingUuid(null);
            } else {
                const response = await axios.post(`${API_URL}/perks`, formData, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });
                const newPerk = response.data.perk;
                setPerks(prevPerks => [...prevPerks, newPerk]);
            }

            setFormData(DEFAULT_FORM_STATE);
            setIsFormVisible(false);

            const action = updatingUuid ? "updated" : "created";
            Toast.success(`Perk has been successfully ${action}.`);
        } catch (errors) {
            let { fields, messages } = errors;
            let formErrors = [];
            for (let i = 0; i < messages.length; i++) {
                formErrors = [...formErrors, {
                    input: fields[i],
                    message: messages[i]
                }];
            }
            setFormErrors(formErrors);
        } finally {
            setIsFormSaving(false);
        }
    };

    const handleShowDeleteDialog = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleMultipleDelete = async () => {
        try {
            setIsDeleting(true);

            await axios.delete(`${API_URL}/perks`, {
                data: { perkUuids: checkedPerks },
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            refetchPerks();
        } catch (error) {
            Toast.error("Unable to delete the selected perks. Please try again later or contact support if the issue persists.");
        } finally {
            setAllPerksChecked(false);
            setCheckedPerks([]);
            setIsDeleting(false);
            handleCloseDeleteDialog();
        }
    };

    const removeErrorMessage = key => {
        switch (key) {
            case "promoCode":
                key = API_ERROR_FIELD.CODE_TYPE;
                break;
            case "startDate":
                key = API_ERROR_FIELD.DATES;
                break;
            case "endDate":
                key = API_ERROR_FIELD.DATES;
                break;
        }
        setFormErrors(prevErrors => prevErrors.filter(error => error.input !== key));
    };

    const handleFilterByType = e => {
        setFilterType(e.target.value);
        // reset selected perks (checkboxes)
        setAllPerksChecked(false);
        setCheckedPerks([]);
    };

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleFilterByStatus = e => {
        setFilterStatus(e.target.value);
    };

    const filteredPerks = perks.filter(perk => {
        const { companyName, title, description, endDate, type } = perk;
        const searchTerms = `${companyName.toLowerCase()} ${title.toLowerCase()} ${description.toLowerCase()}`;
        const isMatchingSearch = searchTerms.includes(searchQuery.toLowerCase());

        if (!isMatchingSearch) return false;

        const perkEndDate = moment(endDate);
        const currentMoment = moment();

        // Check perk type
        if (filterType === PERK_TYPE.GENERAL && type !== PERK_TYPE.GENERAL) {
            return false;
        } else if (filterType === PERK_TYPE.GIFT && type !== PERK_TYPE.GIFT) {
            return false;
        }

        // Check perk status
        switch (filterStatus) {
            case PERK_STATUS.EXPIRED:
                return currentMoment.isAfter(perkEndDate);
            case PERK_STATUS.ACTIVE:
                return currentMoment.isBefore(perkEndDate);
            default:
                return true;
        }
    });

    return (
        <PageContainer>
            { !isFormVisible && (
                <TablePage
                    user={user}
                    perks={filteredPerks}
                    handleFilterByType={handleFilterByType}
                    handleSearchChange={handleSearchChange}
                    handleFilterByStatus={handleFilterByStatus}
                    filterType={filterType}
                    searchQuery={searchQuery}
                    filterStatus={filterStatus}
                    isFetching={isFetching}
                    allPerksChecked={allPerksChecked}
                    handleCheckAllPerks={handleCheckAllPerks}
                    checkedPerks={checkedPerks}
                    handleCheckPerk={handleCheckPerk}
                    handleShowDeleteDialog={handleShowDeleteDialog}
                    handleFormVisible={handleFormVisible}
                    handleEditPerk={handleEditPerk}
                />
            )}

            { isFormVisible && (
                <FormPage
                    handleFormVisible={handleFormVisible}
                    handleChangePerkType={handleChangePerkType}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleCreateOrUpdateWithImages={handleCreateOrUpdateWithImages}
                    handlePromoCodeChange={handlePromoCodeChange}
                    promoCodeType={promoCodeType}
                    updatingUuid={updatingUuid}
                    handleChangeUserGroup={handleChangeUserGroup}
                    isFormSaving={isFormSaving}
                    formErrors={formErrors}
                />
            )}

            {showDeleteDialog && (
                <DeleteDialog
                    showDeleteDialog={showDeleteDialog}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                    handleMultipleDelete={handleMultipleDelete}
                    isDeleting={isDeleting}
                />
            )}
        </PageContainer>
    );
};

export default withRouter(Perk);