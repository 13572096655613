import React from "react";
import styled from "styled-components";
import UserAvatar from "../../../../Chat/components/UserAvatar";
import { capitalizeString } from "src/utils/helpers";
import { EnumUserGroup } from "src/scenes/Survey/components/Enums";
import BlueCheck from "src/img/survey/blue-check.svg";

const UsersListContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 1.125rem;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        margin-top: 2.5rem;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }
`;

const Table = styled.table`
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background: transparent;
`;

const TH = styled.th`
    color: #4B5155;
    font-size: 1rem;
    padding: ${p => p.padding || "0 0 1.25rem 1.25rem"};
    width: 30%;
    min-width: 8rem;
`;

const TD = styled.td`
    background-color: transparent;
    min-width: 8rem;
`;

const ContentBox = styled.div`
    position: relative;
    height: 4.375rem;
    min-width: 9.375rem;
    padding: 0.75rem 1.4375rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const Row = styled.tr`
    width: 100%;
    border: 1px solid #DBE5ED;
    outline: 1px solid #DBE5ED;
    background: white;
    box-sizing: border-box;
    :first-child {
        border: 1px solid #DBE5ED;
    }
`;

const BlankRow = styled.tr`
    width: 100%;
    border: none;
    font-weight: ${p => p.isSelected ? "600" : ""};
    background: none;
    box-sizing: border-box;
`;

const SelectionButton = styled.div`
    cursor: pointer;
    height: 1.625rem;
    width: 1.625rem;
    box-sizing: border-box;
    border-radius: 50%;
    border:  1px solid #707070;
`;

const PollsUsersList = ({ usersToDisplay, handleSelectUser, selectedUserGroupType, selectedUsers }) => {

    const isSelected = (uuid) => selectedUsers.some((selectedItem) => selectedItem === uuid);

    const noUsersToDisplay = () => {
        return (
            <BlankRow>
                <TD colSpan="4">
                    <ContentBox>No users to display</ContentBox>
                </TD>
            </BlankRow>
        );
    };

    const renderClientsList = () => {
        if (!usersToDisplay || usersToDisplay.length === 0) {
            return noUsersToDisplay();
        }

        return usersToDisplay.map(user => {
            return (
                <Row key={user.uuid} >
                    <TD style={{ width: "35%", borderRight: "1px solid #DBE5ED" }}>
                        <ContentBox>
                            <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                                photo={user.profilePictureUrl}
                                background="#D6D6D6"
                                firstName={user.firstName}
                                lastName={user.lastName}
                                statusBorder="1px solid #fff"
                                hideStatus={true}
                                withShadow={true}
                                borderPixel="1px"
                            />
                            {capitalizeString(user.firstName) + " " + capitalizeString(user.lastName)}
                        </ContentBox>
                    </TD>
                    <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}><ContentBox>{user.employmentPosition || "-"}</ContentBox></TD>
                    <TD style={{ width: "15%" }}>
                        <ContentBox className="flex-centered-content">
                            {isSelected(user.uuid) ?
                                <div style={{ cursor: "pointer" }}>
                                    <img src={BlueCheck} height="26" width="26" onClick={() => handleSelectUser(user.uuid)} />
                                </div>
                                :
                                <>
                                    <SelectionButton
                                        onClick={() => handleSelectUser(user.uuid)}
                                    />
                                </>
                            }
                        </ContentBox>
                    </TD>
                </Row>
            );
        });
    };

    const renderEmployeesList = () => {
        if (!usersToDisplay || usersToDisplay.length === 0) {
            return noUsersToDisplay();
        }

        return usersToDisplay.map(user => {
            return (
                <Row key={user.uuid} isSelected={isSelected(user.uuid)}>
                    <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}>
                        <ContentBox>
                            <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                                photo={user.profilePictureUrl}
                                background="#D6D6D6"
                                firstName={user.firstName}
                                lastName={user.lastName}
                                statusBorder="1px solid #fff"
                                hideStatus={true}
                                withShadow={true}
                                borderPixel="1px"
                            />
                            {capitalizeString(user.firstName) + " " + capitalizeString(user.lastName)}
                        </ContentBox>
                    </TD>
                    <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}><ContentBox>{user.department || "-"}</ContentBox></TD>
                    <TD style={{ width: "25%", borderRight: "1px solid #DBE5ED" }}><ContentBox>{user.employmentPosition || "-"}</ContentBox></TD>
                    <TD style={{ width: "25%" }}>
                        <ContentBox className="flex-centered-content">
                            {isSelected(user.uuid) ?
                                <div style={{ cursor: "pointer" }}>
                                    <img src={BlueCheck} height="26" width="26" onClick={() => handleSelectUser(user.uuid)} />
                                </div>
                                :
                                <>
                                    <SelectionButton
                                        onClick={() => handleSelectUser(user.uuid)}
                                    />
                                </>
                            }
                        </ContentBox>
                    </TD>
                </Row>
            );
        });
    };

    const renderClientsTableHeader = () => {
        return (
            <thead style={{ width: "100%", position: "sticky", top: "0px", zIndex: "150", backgroundColor: "#F9FAFC" }}>
                <tr style={{ width: "100%" }}>
                    <TH style={{ width: "50%" }}>Name</TH>
                    <TH style={{ width: "25%" }}>Client Type</TH>
                    <TH style={{ width: "25%", textAlign: "center" }} padding="0 0 1.25rem 0">Select User</TH>
                </tr>
            </thead>
        );
    };

    const renderEmployeesTableHeader = () => {
        return (
            <thead style={{ width: "100%", position: "sticky", top: "0px", zIndex: "150", backgroundColor: "#F9FAFC" }}>
                <tr style={{ width: "100%" }}>
                    <TH style={{ width: "25%" }}>Name</TH>
                    <TH style={{ width: "25%" }}>Department / Group</TH>
                    <TH style={{ width: "25%" }}>Job Title</TH>
                    <TH style={{ width: "25%", textAlign: "center" }} padding="0 0 1.25rem 0">Select User</TH>
                </tr>
            </thead>
        );
    };

    return (
        <React.Fragment>
            <UsersListContainer>
                <Table>
                    {selectedUserGroupType === EnumUserGroup.CLIENTS ? renderClientsTableHeader() : renderEmployeesTableHeader()}
                    <tbody style={{ overflow: "auto", height: "auto" }}>
                        {selectedUserGroupType === EnumUserGroup.CLIENTS ? renderClientsList() : renderEmployeesList()}
                    </tbody>
                </Table>
            </UsersListContainer>
        </React.Fragment>
    );
};

export default PollsUsersList;