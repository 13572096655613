import React from "react";
import {
    PageContainer, PageHeadingLabel
} from "src/components";

import { FormBackground, LoadingIndicatorIcon } from "../../components/Utils";

const LoadingForm = (props) => {
    return (
        <PageContainer>
            <PageHeadingLabel size="2.5rem" margin="0 0 0 0">Edit  Poll</PageHeadingLabel>
            <div className="row" style={{ maxWidth: "37.5rem", marginTop: "13px", marginBottom: "33px" }}>
                <div className="description">
                    Easily set up a new poll to gather quick feedback or opinions. Customise your poll question, add answer options, and share it directly with your team or clients through the Me Business app. Get real-time results to make informed decisions.
                </div>
            </div>
            <FormBackground style={{ height: "35rem" }}>
                <LoadingIndicatorIcon />
            </FormBackground>
        </PageContainer>
    );
};

export default LoadingForm;