import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import styled from "styled-components";
import axios from "axios";
import { Box, Dialog, Divider } from "@material-ui/core/";
import {
    Button, Text, FlexContainer,
    LottieLoadingIndicator
} from "src/components";
import { surveyUserGroupOptions, EnumSurveyType, EnumUserGroup } from "../components/Enums";
import { Toast } from "src/components";
import { SurveyStatusType, InsightSortableColumns } from "src/constants/survey";
import { surveyUserSubmissionsModalStyles, SingleValue } from "../forms/components/DropdownCustomStyles";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";

//assets
import ArrowDown from "src/img/ArrowDown.svg";
import ArrowUp from "src/img/ArrowDown.svg";
import IconExcellent from "src/img/survey/updatedIcons/Excellent@2x.png";
import IconGreat from "src/img/survey/updatedIcons/Great@2x.png";
import IconOk from "src/img/survey/updatedIcons/ok@2x.png";
import IconPoor from "src/img/survey/updatedIcons/poor@2x.png";
import IconBad from "src/img/survey/updatedIcons/bad@2x.png";

const Th = styled.th`
    font-weight: 500;
    width: ${p => p.width};
    min-width: ${p => p.minWidth };
    text-align: ${p => p.textAlign};
    flex-direction: row;
    justify-content: left;
    font-size: 16px;
    user-select: none;
`;

const Td = styled.td`
    text-align: center;
    font-size: 16px;
    font-weight: ${p => p.fontWeight};
    padding: ${p => p.padding};
`;

const Tr = styled.tr`
    width: 100%;
    height: 70px;
    text-align: center;
    font-size: 16px;
    font-weight: ${p => p.fontWeight};
    border: 1px solid #8291B2;
`;

const UserInitials = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d0d7de;
    color: #4f4f4f;
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
    cursor: default;
    user-select: none;
}
`;

const UserPhoto = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url(${p => p.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10px;
    cursor: none;
`;

const SurveyUserSubmissionsModal = (props) => {
    const { showDialog, handleClose, insights, survey } = props;
    const [selectedSurveyStatusType, setSelectedSurveyStatusType] = useState(survey.userGroup);
    const [surveyUserSubmissionList, setSurveyUserSubmissionList] = useState([]);
    const [sortColumn, setSortColumn] = useState(InsightSortableColumns.STATUS);
    const [sortDirection, setSortDirection] = useState("asc");
    const numberOfQuestions = insights.surveyQuestions.length ?? 0;
    const [isSurveyResponsesFetched, setIsSurveyResponsesFetched] = useState(false);
    const currentUser = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };

    const sortData = (column) => {
        const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        setSortColumn(column);
        setSortDirection(direction);

        const sortByName = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

        const sortByColumn = (a, b) => {
            let aValue = "";
            let bValue = "";

            switch (column) {
                case InsightSortableColumns.NAME:
                    aValue = a.name;
                    bValue = b.name;
                    break;
                case InsightSortableColumns.EMAIL:
                    aValue = a.email;
                    bValue = b.email;
                    break;
                case InsightSortableColumns.QUESTION_ONE:
                    aValue = a.questionOneResponse;
                    bValue = b.questionOneResponse;
                    break;
                case InsightSortableColumns.QUESTION_TWO:
                    aValue = a.questionTwoResponse;
                    bValue = b.questionTwoResponse;
                    break;
                case InsightSortableColumns.QUESTION_THREE:
                    aValue = a.questionThreeResponse;
                    bValue = b.questionThreeResponse;
                    break;
                case InsightSortableColumns.STATUS:
                    aValue = a.status;
                    bValue = b.status;
                    if (aValue === bValue) {
                        return sortByName(a, b);
                    }
                    break;
                default:
                    return 0;
            }

            if (!aValue) return direction === "asc" ? 1 : -1;
            if (!bValue) return direction === "asc" ? -1 : 1;

            if (aValue < bValue) {
                return direction === "asc" ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === "asc" ? 1 : -1;
            }
            return 0;
        };

        if (column === InsightSortableColumns.STATUS) {
            const usersWithResponses = surveyUserSubmissionList.filter(user =>
                user.questionOneResponse || user.questionTwoResponse || user.questionThreeResponse
            );
    
            const usersWithoutResponses = surveyUserSubmissionList.filter(user =>
                !user.questionOneResponse && !user.questionTwoResponse && !user.questionThreeResponse
            );
    
            const sortedWithResponses = [...usersWithResponses].sort(sortByColumn);
            const sortedWithoutResponses = [...usersWithoutResponses].sort(sortByColumn);
    
            const sortedData = direction === "asc" 
                ? [...sortedWithResponses.sort(sortByName), ...sortedWithoutResponses.sort(sortByName)] 
                : [...sortedWithoutResponses.sort(sortByName), ...sortedWithResponses.sort(sortByName)];
            setSurveyUserSubmissionList(sortedData);
        } else {
            const sortedData = [...surveyUserSubmissionList].sort(sortByColumn);
            setSurveyUserSubmissionList(sortedData);
        }
    };

    const renderSortIndicator = (column) => {
        if (sortColumn === column) {
            return (sortDirection === "asc" ? (
                <img src={ArrowUp} alt="SortArrow" style={{ transform: "rotate(180deg)", filter: "brightness(0) saturate(100%)", width: "10px" }} />
            ) : (
                <img src={ArrowDown} alt="SortArrow" style={{ filter: "brightness(0) saturate(100%)", width: "10px" }} />
            ));
        }
        return null;
    };

    const handleSurveyTypeIcons = (surveyType, response) => {
        const satisfactionIconsList = {
            excellent: IconExcellent,
            great: IconGreat,
            ok: IconOk,
            poor: IconPoor,
            bad: IconBad,
        };
    
        if (surveyType === EnumSurveyType.SATISFACTION) {
            const Icon = satisfactionIconsList[response];
            return Icon ? <img src={Icon} alt={Icon} height="36px" width="36px" /> : null;
        }
    
        return response || "-";
    };

    const emptyList = (numberOfQuestions) => {
        const emptyRows = 10 - surveyUserSubmissionList.length;

        if (emptyRows < 1)
            return;

        return Array.from({ length: emptyRows }, (_, i) => (
            <Tr key={"empty_" + i}>
                <td className="change-color-on-select">&nbsp;</td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                {numberOfQuestions >= 2 && <td className="change-color-on-select"></td>}
                {numberOfQuestions >= 3 && <td className="change-color-on-select"></td>}
                <td className="change-color-on-select"></td>
            </Tr>
        ));
    };

    const surveyUserSubmissionListItems = (surveyUserSubmissionList, numberOfQuestions) => surveyUserSubmissionList.map((surveyUserResponse, index) => {
        return index === 0 ? 
            <>
            </>
            :
            <Tr key={index}>
                <Td style={{ textAlign: "left", paddingLeft: "20px", width: "30%" }} fontWeight="300 !important">
                    {
                        surveyUserResponse["profilePicture"] === "" ?
                            <>
                                <UserInitials>
                                    { surveyUserResponse["firstName"][0] }
                                    { surveyUserResponse["lastName"][0] }
                                </UserInitials>
                            </> :
                            <>
                                <UserPhoto image={surveyUserResponse["profilePicture"]} >
                                    &nbsp;
                                </UserPhoto>
                            </>

                    }
                    <span style={{ margin: "auto" }}>
                        {surveyUserResponse["name"]}
                    </span>
                </Td>
                <Td style={{ textAlign: "left", paddingLeft: "20px", width: "20%" }} fontWeight="300 !important">
                    {surveyUserResponse["email"]}
                </Td>
                <Td style={{ textAlign: "left", paddingLeft: "20px", width: "10%", textTransform: "capitalize" }} fontWeight="300 !important">
                    {handleSurveyTypeIcons(survey.surveyType, surveyUserResponse["questionOneResponse"])}
                </Td>
                { numberOfQuestions >= 2 &&
                    <Td style={{ textAlign: "left", paddingLeft: "20px", width: "10%", textTransform: "capitalize" }} fontWeight="300 !important">
                        {handleSurveyTypeIcons(survey.surveyType, surveyUserResponse["questionTwoResponse"])}
                    </Td>
                }
                { numberOfQuestions >= 3 &&
                    <Td style={{ textAlign: "left", paddingLeft: "20px", width: "10%", textTransform: "capitalize" }} fontWeight="300 !important">
                        {handleSurveyTypeIcons(survey.surveyType, surveyUserResponse["questionThreeResponse"])}
                    </Td>
                }
                <Td style={{ textAlign: "left", paddingLeft: "20px", width: "20%" }} fontWeight="300 !important">
                    {
                        surveyUserResponse["questionOneResponse"] === null || surveyUserResponse["questionTwoResponse"] === null || surveyUserResponse["questionThreeResponse"] === null
                            ?
                            survey.status === SurveyStatusType.COMPLETE ? (<div style={{ fontWeight: "500", color: "#006CFF" }}>Not Submitted</div>) : (<div style={{ fontWeight: "500", color: "#006CFF" }}>Pending</div>)
                            :
                            <div style={{ fontWeight: "500", color: "#36BE24" }}>Submitted</div>
                    }
                </Td>

            </Tr>;
    });

    const getSurveyUserResponses = (surveyUuid, filter) => {
        setIsSurveyResponsesFetched(false);
        let URL = `${API_URL}/survey/company/responsesStats/${surveyUuid}/users`;
        if (filter) {
            URL = URL + "?filter=" + filter;
        }
        axios.get(URL, headers).then(res => {
            const responseData = res.data;
            const usersWithResponses = responseData.filter((responseItem) => 
                responseItem.questionOneResponse ||
                responseItem.questionTwoResponse ||
                responseItem.questionThreeResponse
            );

            const usersWithoutResponses = responseData.filter((responseItem) => 
                !responseItem.questionOneResponse &&
                !responseItem.questionTwoResponse &&
                !responseItem.questionThreeResponse
            );

            const sortByName = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

            const organizedList = [
                ...usersWithResponses.sort(sortByName),
                ...usersWithoutResponses.sort(sortByName),
            ];
            setSurveyUserSubmissionList(organizedList);
        }).finally(() => {
            setIsSurveyResponsesFetched(true);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        });
    };

    const filterUserSurveyResponses = (userGroup) => {
        setSelectedSurveyStatusType(userGroup.value);
        getSurveyUserResponses(survey.uuid, userGroup.value);
    };

    useEffect(() => {
        if (survey) {
            getSurveyUserResponses(survey.uuid, survey.userGroup);
        }
    }, []);

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="xl"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 1500,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto",
                    },
                }}
            >
                <Box sx={{ margin: "40px 55px 10px 55px" }}>
                    <FlexContainer direction="row" tDirection="row" mDirection="row" justifyContent="space-between" >
                        <Text color="#11141A" align="left" size="2.5rem" weight="700" margin="0" width="fit-content">
                            Survey User Submissions
                        </Text>
                        { survey.userGroup === EnumUserGroup.ALL_USERS &&
                            <FlexContainer direction="row" justifyContent="flex-end" width="fit-content">
                                <FlexContainer direction="unset">
                                    <Text size="14px" weight="700" margin="auto 16px auto 0" display="inline-flex">
                                        Filter:
                                    </Text>
                                    <div style={{ width: "270px", margin: "auto 0" }}>
                                        <Select
                                            isSearchable={false}
                                            options={ surveyUserGroupOptions }
                                            onChange={(choice) => filterUserSurveyResponses(choice)}
                                            styles={surveyUserSubmissionsModalStyles}
                                            value={ surveyUserGroupOptions.find((obj) => obj.value === selectedSurveyStatusType) }
                                            components={{ SingleValue: SingleValue }}
                                        />
                                    </div>
                                </FlexContainer>
                            </FlexContainer>
                        }
                    </FlexContainer>
                </Box>
                <Divider />
                <div style={{ maxWidth: "94.5rem", padding: "30px 55px 0 55px" }}>
                    <FlexContainer direction="row" marginBottom="27px" justifyContent="space-between" wrap="nowrap">
                        <div >
                            <FlexContainer direction="column">
                                <Text color="#000" size="20px" align="left" weight="700" margin="0.5rem 0px">
                                    Number of Submitted Surveys:&nbsp;{insights.numberOfTotalSurveysSubmitted}/{insights.numberOfUsersInTargetGroup}
                                </Text>
                                <Text color="#000" size="16px" weight="300" align="left" margin="0.5rem 0px">
                                    This is a list of Employees, Clients or All Users who have, and have not, submitted their survey responses.
                                </Text>
                            </FlexContainer>
                            <FlexContainer direction="column">
                                <FlexContainer direction="row">
                                    <Text color="#000" size="16px" align="left" weight="700" margin="0.5rem 0px" style={{ display: "inline-flex" }}>
                                        Question 1:&nbsp;<span style={{ fontWeight: "300" }}>{survey.surveyQuestions[0].question}</span>
                                    </Text>
                                </FlexContainer>
                                { survey.surveyQuestions[1] &&
                                    <FlexContainer direction="row">
                                        <Text color="#000" size="16px" align="left" weight="700" margin="0.5rem 0px" style={{ display: "inline-flex" }}>
                                            Question 2:&nbsp;<span style={{ fontWeight: "300" }}>{survey.surveyQuestions[1].question}</span>
                                        </Text>
                                    </FlexContainer>
                                }
                                { survey.surveyQuestions[2] &&
                                    <FlexContainer direction="row">
                                        <Text color="#000" size="16px" align="left" weight="700" margin="0.5rem 0px" style={{ display: "inline-flex" }}>
                                            Question 3:&nbsp;<span style={{ fontWeight: "300" }}>{survey.surveyQuestions[2].question}</span>
                                        </Text>
                                    </FlexContainer>
                                }
                            </FlexContainer>
                        </div>
                    </FlexContainer>

                    { !isSurveyResponsesFetched &&
                        <div style={{ height: "24rem" }}>
                            <LottieLoadingIndicator/>
                        </div>
                    }
                </div>
                <Divider />
                <div style={{ maxWidth: "94.5rem", margin: "20px 55px 30px 55px" }}>
                    { isSurveyResponsesFetched &&
                        <div className="survey-scrollable-div" style={{ height: "24rem", padding: "0", overflowY: "auto", borderBottom: "1px solid #8291B2", borderRadius: "8px" }}> 
                            <table className="survey-scrollable-table">
                                <thead style={{ width: "100%", position: "sticky", top: "0px", zIndex: "150", backgroundColor: "#fff" }}>
                                    <tr style={{ width: "100%" }}>
                                        <Th style={{ width: "30%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.NAME)}
                                        >
                                            Name&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.NAME)}
                                        </Th>
                                        <Th style={{ width: "20%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.EMAIL)}
                                        >
                                            Email&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.EMAIL)}
                                        </Th>
                                        <Th style={{ width: "10%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.QUESTION_ONE)}
                                        >
                                            Question 1&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.QUESTION_ONE)}
                                        </Th>
                                        { numberOfQuestions >= 2 && <Th style={{ width: "10%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.QUESTION_TWO)}
                                        >
                                            Question 2&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.QUESTION_TWO)}
                                        </Th>}
                                        { numberOfQuestions >= 3 && <Th style={{ width: "10%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.QUESTION_THREE)}
                                        >
                                            Question 3&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.QUESTION_THREE)}
                                        </Th>}
                                        <Th style={{ width: "20%", paddingLeft: "20px", paddingBottom: "20px" }}
                                            onClick={() => sortData(InsightSortableColumns.STATUS)}
                                        >
                                            Status&nbsp;&nbsp;&nbsp;{renderSortIndicator(InsightSortableColumns.STATUS)}
                                        </Th>
                                    </tr>
                                </thead>

                                <thead className="first-data">
                                    <tr style={{ width: "100%" }}>
                                        <th style={{ width: "30%", paddingLeft: "20px" }}>
                                            {
                                                surveyUserSubmissionList[0]["profilePicture"] === "" ?
                                                    <>
                                                        <UserInitials>
                                                            { surveyUserSubmissionList[0]["firstName"][0] }
                                                            { surveyUserSubmissionList[0]["lastName"][0] }
                                                        </UserInitials>
                                                    </> :
                                                    <>
                                                        <UserPhoto image={surveyUserSubmissionList[0]["profilePicture"]} >
                                                            &nbsp;
                                                        </UserPhoto>
                                                    </>

                                            }
                                            <span style={{ margin: "auto" }}>
                                                {surveyUserSubmissionList[0]["name"]}
                                            </span>
                                        </th>
                                        <th style={{ width: "20%", paddingLeft: "20px" }}>
                                            {surveyUserSubmissionList[0]["email"]}
                                        </th>
                                        <th style={{ width: "10%", paddingLeft: "20px" }}>
                                            {handleSurveyTypeIcons(survey.surveyType, surveyUserSubmissionList[0]["questionOneResponse"])}
                                        </th>
                                        { numberOfQuestions >= 2 && 
                                            <th style={{ width: "10%", paddingLeft: "20px" }}>
                                                {handleSurveyTypeIcons(survey.surveyType, surveyUserSubmissionList[0]["questionTwoResponse"])}
                                            </th>
                                        }
                                        { numberOfQuestions >= 3 &&
                                            <th style={{ textAlign: "left", paddingLeft: "20px", width: "10%", textTransform: "capitalize" }}>
                                                {handleSurveyTypeIcons(survey.surveyType, surveyUserSubmissionList[0]["questionThreeResponse"])}
                                            </th>
                                        }
                                        <th style={{ width: "20%", paddingLeft: "20px" }}>
                                            {
                                                surveyUserSubmissionList[0]["questionOneResponse"] === null || surveyUserSubmissionList[0]["questionTwoResponse"] === null || surveyUserSubmissionList[0]["questionThreeResponse"] === null
                                                    ?
                                                    <div style={{ fontWeight: "500", color: "#006CFF" }}>Pending</div>
                                                    :
                                                    <div style={{ fontWeight: "500", color: "#36BE24" }}>Submitted</div>
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{ overflow: "auto", height: "auto", borderRadius: "8px" }}>
                                    {surveyUserSubmissionListItems(surveyUserSubmissionList, numberOfQuestions)}
                                    {emptyList(numberOfQuestions)}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <Divider />

                <Box display="flex" justifyContent="center" margin="auto">
                    <Button 
                        border="1px solid #000"
                        color="#000"
                        marginRight="15px"
                        width="140px"
                        onClick={handleClose}
                        style={{ borderRadius: "7px", boxShadow: "none", margin: "30px" }}
                        className="form-btns disabled"
                    >
                        Close
                    </Button>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}; 

export default SurveyUserSubmissionsModal;