import React, { useState, useRef } from "react";
import { Box, Grid, Dialog, Divider } from "@material-ui/core/";
import { Button, Text, LoadingIndicator } from "src/components";
import styled, { css } from "styled-components";
import { CSVReader } from "react-papaparse";
import EmployeeTemplate from "src/template/EmployeeTemplate.csv";
import ClientTemplate from "src/template/ClientTemplate.csv";
import CloseButton from "src/img/employeeList/CloseButton.png";
import { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import { getExistingEmployeeAndClientEmails } from "./EditUserList";

const DirtyState = {
    FALSE: 0,
    TRUE: 1,
    UNKNOWN: 2,
};

const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 700;
    color: #000;
    margin: 0;
`;

const RadioButton = styled.button`
    position: relative;
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: center;
    font-weight: 400;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
          background-color: #000000;
          color: #fff !important;
          font-weight: 700;
    `}
    ${(props) =>
        props.disabled &&
        css`
          pointer-events: none;
          color: #000;
    `}
`;

const Circle = styled.div`
    position: absolute;
    right: 0;
    bottom: 9px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #76C00D;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;

    ${(props) =>
        props.active &&
        css`
            visibility: visible;
    `}
    ${(props) =>
        props.disabled &&
        css`
          pointer-events: none;
    `}
`;

const CheckMark = styled.span`
    color: white;
    font-weight: 700;
    font-size: 10px;
`;

const UploadButton = {
    opacity: 0,
    position: "absolute",
    top: "-40px",    
    width: "180px",
    height: "40px",
    cursor: "pointer"
};

const ClearUpload = styled.span`
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url(${CloseButton}) no-repeat right transparent;
    background-size: 1.25rem;
    position: absolute;
    top: 8px;
    margin-left: 5px;
    cursor: pointer;
    right: 10px;
`;

export function getCountryCallingCodeWithPlusSign(countryCode) {
    try {
        return "+" + getCountryCallingCode(countryCode);
    }
    catch (e) {
        return "";
    }
}

const UploadUserListModal = (props) => {
    const { showDialog, handleClose, openEditUserListModal, setUploadUsers, isClient, setIsClient, countryCode, token } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const fileInput = useRef(null);
    const [items, setItems] = useState(null);
    const [selectedUserTypeIsClient, setSelectedUserTypeIsClient] = useState(isClient);

    // Related to file uploads
    const [errorList, setErrorList] = useState([]);

    // Used by CSVReader
    const upload = (items) => {
        setErrorList([]);
        let file = fileInput.current.value;
        const truncatedFilename = truncateFilename(file.split("\\").pop(), 25);
        setFileName(truncatedFilename);
        setItems(items);
    };   

    function truncateFilename(filename, maxLength) {
        const parts = filename.split(/\.(?=[^.]+$)/); //added regex to only get the last `dot` in the string.
        const name = parts[0];
        const extension = parts[1];
        
        if (name.length > maxLength) {
            const truncatedName = name.slice(0, maxLength - 3) + "...";
            return truncatedName + "." + extension;
        } else {
            return filename;
        }
    }

    const clearUpload = (e) => {
        fileInput.current.value = null;
        setFileName(null);
        setItems(null);
        setErrorList([]);
    };

    const finish = (e) => {      
        setErrorList([]);

        if (!fileName) {
            setErrorList((prevErrorList) => [...prevErrorList, "* Please upload a csv file to continue."]);
            return;
        }
        
        const templateCheckerResult = templateChecker(items);
        if (!templateCheckerResult.isValid) {
            const fieldString = templateCheckerResult.headers.length > 1 ? "fields" : "field";
            setErrorList((prevErrorList) => [...prevErrorList, `* Please upload a CSV file that uses the ${selectedUserTypeIsClient ? "Client" : "Employee"} List Template. Missing "${templateCheckerResult.headers}" ${fieldString}`]);
            return;
        }

        submitFile(items);
    };

    const templateChecker = (items) => {
        const csvHeader = items.meta.fields;
        const templateForClient = ["Client First Name", "Last Name", "Email Address"]; 
        const templateForEmployee = ["Employee First Name", "Last Name", "Email Address"];

        const missingHeaders = findMissingValues(selectedUserTypeIsClient ? templateForClient : templateForEmployee, csvHeader);
        return { "isValid": missingHeaders.length === 0, "headers": missingHeaders };
    };

    const findMissingValues = (templateArray, uploadedCsvArray) => {
        const loweredCaseArr2 = uploadedCsvArray.map(value => value.toLowerCase()); //lowercase elements of arr2 for case-insensitive lookups
        const arr2Set = new Set(loweredCaseArr2); // Create a Set for faster lookups
        const missingValues = [];
      
        for (const value of templateArray) {
            const lowerValue = value.toLowerCase(); // Convert current value to lowercase
            if (!arr2Set.has(lowerValue)) { // Check if value exists in the Set
                missingValues.push(value);
            }
        }
      
        return missingValues;
    };

    const submitFile = async (items) => {
        
        let inputs = items.data;

        //Required. make sure email is filled
        inputs = inputs.filter(input => {
            const email = input["Email Address"]?.toLowerCase(); // Safely access and lowercase email
            return email && email.length > 0;
        });

        let data = [];
        
        inputs.forEach(element => {
            let display = {};

            for (const key in element) {
                if (key.toLowerCase() === "Employee First Name".toLowerCase()) { //Required. For Employee
                    display["firstName"] = element[key]; 
                }

                if (key.toLowerCase() === "Client First Name".toLowerCase()) { //Required. For Client
                    display["firstName"] = element[key]; 
                }

                if (key.toLowerCase() === "Last Name".toLowerCase()) { //Required
                    display["lastName"] = element[key]; 
                }

                if (key.toLowerCase() === "Email Address".toLowerCase()) { //Required
                    display["email"] = element[key]; 
                }

                if (key.toLowerCase() === "Mobile Number (Optional)".toLowerCase()) {
                    display["phoneNumber"] = element[key]; 
                }

                if (!selectedUserTypeIsClient) {
                    if (key.toLowerCase() === "Job Title (Optional)".toLowerCase()) {
                        display["employmentPosition"] = element[key]; 
                    }

                    if (key.toLowerCase() === "Department/Group (Optional)".toLowerCase()) {
                        display["department"] = element[key]; 
                    }
                } else {
                    if (key.toLowerCase() === "Client Type (Optional)".toLowerCase()) {
                        display["employmentPosition"] = element[key]; 
                    }
                }
            }

            display["newUser"] = true;
            display["isClient"] = selectedUserTypeIsClient;
            display["isDirty"] = DirtyState.TRUE;
            display["invalidData"] = {};
            data.push(display);
        });

        if (data.length === 0) {
            setErrorList((prevErrorList) => [...prevErrorList, "* Csv file is empty."]);
            return;
        }

        setIsLoading(true);
        const axiosResponse2 = await getExistingEmployeeAndClientEmails(token);
        setIsLoading(false);
        const existingUserEmailsResponse = axiosResponse2.data;
        insertDataToForm(data, existingUserEmailsResponse);
        handleClose();
        openEditUserListModal(existingUserEmailsResponse);
    };

    const insertDataToForm = (data, existingEmails) => {
        //manipulate onload data here
        data.forEach(element => {
            const email = element["email"];
            const isExistingEmployee = existingEmails["existingEmployeeEmails"].includes(email);
            const isExistingClient = existingEmails["existingClientEmails"].includes(email);
            const isNewUser = !isExistingEmployee && !isExistingClient;
            if (!isNewUser) { //use phoneNumber from database and not from CSV
                element["phoneNumber"] = getPhoneByMail(existingEmails.allUsersEmailAndPhone, email);
            }

            element["phoneNumber"] = autoAssignCountryCode(countryCode, element["phoneNumber"]);
        });

        setUploadUsers(data);
    };

    function getPhoneByMail(userList, targetEmail) {
        // Check if userList exists and targetEmail is a string
        if (!userList || typeof targetEmail !== "string") {
            return null;
        }
      
        // Loop through each user object in the list
        for (const userObject of userList) {
            // Access the email property within userObject
            const userEmail = userObject.email;

            // Check if emails match (case-insensitive)
            if (userEmail.toLowerCase() === targetEmail.toLowerCase()) {
                return userObject.phoneNumber; // Return phone number if email matches
            }
        }

        return null; // Return null if email not found in any userObject
    }

    function autoAssignCountryCode(defaultCountry, value) {
        if (typeof value === "string") {
            const phoneNumber = parsePhoneNumber(value);
            const countryCode = getCountryCallingCodeWithPlusSign(defaultCountry);

            if (!phoneNumber) {
                return countryCode + value;
            }
        }

        return value;
    }

    const downloadCsvTemplate = (event) => {
        event.preventDefault(); 
        window.location.href = selectedUserTypeIsClient ? ClientTemplate : EmployeeTemplate;
    };

    const renderErrors = errorList.map((error, index) => {
        return errorList.length ? <div key={index} style={{ color: "red" }}>{ error }</div>
            : "";
    });

    const handleRadioOnClick = bool => {
        setSelectedUserTypeIsClient(bool);
        setIsClient(bool);
    };

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 900,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto",
                    },
                }}
            >
                <div style={{ padding: "60px 55px 0 55px" }}>
                    <Box>
                        <Text color="#11141A" size="2.5rem" align="left" weight="700" margin="0">
                            Upload User List
                        </Text>

                        <Text color="#000000" align="left" size="0.875rem" padding="0 0rem 1.25rem 0rem">
                            Upload multiple users with our handy template, available for download below. After uploading the .csv file, you’ll see a populated list of employees or clients. Review and edit your list before saving.
                            <br/><br/>
                            <b>Note:</b> <br/>
                            - Each email address and mobile number should be unique. <br/>
                            - Only 3 essentials: First name, last name and email address. 
                        </Text>
                    </Box>

                    <Divider />
                    
                    <Grid container spacing={4} style={{ marginTop: "1rem" }}>

                        <Grid item sm={12} md={4}>
                            <Label>Select User Type</Label>
                            <div className="radio" >
                                <input type="radio" name="userType" value="employee" style={{ visibility: "hidden" }} />
                                <RadioButton active={!selectedUserTypeIsClient}
                                    style={{ margin: "-0.75rem 0 0 0" }}
                                    onClick={() => handleRadioOnClick(false)}
                                >
                                    Employees
                                    <Circle active={!selectedUserTypeIsClient}><CheckMark>&#x2713;</CheckMark></Circle>
                                </RadioButton>
                                
                            </div>
                        </Grid>

                        <Grid item sm={12} md={4}>
                            <Label>&nbsp;</Label>
                            <div className="radio" >
                                <input type="radio" name="userType" value="client" style={{ visibility: "hidden" }} />
                                <RadioButton active={selectedUserTypeIsClient}
                                    style={{ margin: "-0.75rem 0 0 0" }}
                                    onClick={() => handleRadioOnClick(true)}
                                    
                                >
                                    Clients
                                    <Circle active={selectedUserTypeIsClient}><CheckMark>&#x2713;</CheckMark></Circle>
                                </RadioButton>
                            </div>
                            
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>

                        <Grid item sm={12} md={3}>
                            <Label>Upload CSV File</Label>
                            
                            <Button backgroundColor="#006CFF" color="#fff"
                                border="1px solid #006CFF"
                                width="180px"
                                style={{ borderRadius: "12px", boxShadow: "none", marginTop: "0.75rem" }}
                            >
                                    Browse File
                            </Button>
                            <div style={{ position: "relative" }}>
                                <CSVReader
                                    onFileLoaded={upload}
                                    inputRef={fileInput}
                                    style={UploadButton}                        
                                    configOptions={{ header: true }}
                                    id="csvReader"
                                /> 
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Label>{fileName && "File Name"} &nbsp;</Label>
                            {fileName && <div style={{ marginTop: "0.75rem", border: "1px solid #000000", borderRadius: "12px", position: "relative", color: "#000000", width: "250px", lineHeight: "2.25rem", paddingLeft: "10px", fontSize: "14px" }}><span>{fileName}</span><ClearUpload onClick={clearUpload}></ClearUpload></div>}
                            {renderErrors && <div style={{ marginTop: !fileName && "1.45rem", fontSize: "0.8750em" }}>{renderErrors}</div>}
                        </Grid>
                    </Grid>

                    <div style={{ margin: "1rem 0 2rem 0" }}>
                        <Label>Download CSV Template</Label><br/>
                        <Button backgroundColor="#8551AE" color="#fff"
                            border="1px solid #8551AE"
                            width="245px"
                            style={{ borderRadius: "12px", boxShadow: "none", marginTop: "0.75rem" }}
                            onClick={downloadCsvTemplate}
                        >
                            {selectedUserTypeIsClient ? "Client List Template" : "Employee List Template"}
                        </Button>
                    </div>

                    <Divider />
                    
                    <Box display="flex" justifyContent="right" margin="auto">
                        <div style={{ textAlign: "right", margin: "30px" }}>
                            <Button 
                                border="1px solid #000"
                                color="#000"
                                marginRight="15px"
                                width="140px"
                                onClick={handleClose}
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                                className="form-btns disabled"
                            >
                                Cancel
                            </Button>

                            { isLoading && <LoadingIndicator style={{ display: "inline-block", width: "10px", position: "relative", top: "2px" }} width="10px" height="10px" containerHeight="10" />}
                            { !isLoading &&
                                <Button backgroundColor="#006CFF" color="#fff"
                                    border="1px solid #006CFF"
                                    width="140px"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                    type="submit"
                                    onClick={(e) => finish(e)}
                                    className="form-btns disabled"
                                >
                                    Preview CSV
                                </Button>
                            }
                        </div>
                    </Box>
                </div>
            </Dialog>
        </React.Fragment>
    );
}; 

export default UploadUserListModal;