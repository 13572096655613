import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LottieLoadingIndicator, Text } from "src/components";
import IconExcellent from "src/img/survey/updatedIcons/Excellent@2x.png";
import IconGreat from "src/img/survey/updatedIcons/Great@2x.png";
import IconOk from "src/img/survey/updatedIcons/ok@2x.png";
import IconPoor from "src/img/survey/updatedIcons/poor@2x.png";
import IconBad from "src/img/survey/updatedIcons/bad@2x.png";
import RatingGauge from "src/img/survey/updatedIcons/Gauges/Gauge 1@2x.png";
import GaugeNeedle from "src/img/survey/updatedIcons/Gauges/needle@2x.png";
import { countSurveyResponseValue, evaluateAverageCategory } from "../Utils";
import { surveyRatingRotation } from "../Enums";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import { useSpring, animated } from "@react-spring/web";

const OverviewCard = styled.div`
    width: 589px;
    height: 333px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
`;

const RatingRow = styled.div`
    width: 100%;
    height: 100%;
    border-bottom: ${p => p.borderRowBottom || "1px solid #8291B2"};
    display: flex;
    flex-direction: row;
`;

const RatingIconCell = styled.div`
    display: flex;
    width: 70%;
    justify-content: start;
    margin-left: 20px;
`;

const RatingNumberCell = styled.div`
    display: flex;
    width: 30%;
    justify-content: end;
    margin-right: 20px;
    border-left: 1px solid #8291B2;
`;

const SurveySatisfactionCard = (props) => {
    const { surveyQuestion, responsesStats, fetchingResponseStats } = props;
    const [excellentCount, setExcellentCount] = useState(0);
    const [greatCount, setGreatCount] = useState(0);
    const [okCount, setOkCount] = useState(0);
    const [poorCount, setPoorCount] = useState(0);
    const [badCount, setBadCount] = useState(0);
    const [averageRating, setAverageRating] = useState(0);
    const [surveyNeedleRotation, setSurveyNeedleRotation] = useState("");
    const [gaugeLoading, setGaugeLoading] = useState(true);

    const [needleSpring, api] = useSpring(() => ({
        angle: 0,
        config: { tension: 150, friction: 10 },
    }));
    useEffect(() => {
        if (responsesStats) {
            const starValues = ["bad", "poor", "ok", "great", "excellent"];
            const starSetters = [setBadCount, setPoorCount, setOkCount, setGreatCount, setExcellentCount];

            const starCounts = starValues.map((value, index) => {
                const count = countSurveyResponseValue(responsesStats, value, surveyQuestion.uuid);
                starSetters[index](count);
                return count;
            });
            setAverageRating(evaluateAverageCategory(starCounts));
        }
    }, [responsesStats]);

    useEffect(() => {
        const clamped = Math.min(Math.max(averageRating, 1), 5); // Clamp within [1, 5]
        const angle = surveyRatingRotation.One + (surveyRatingRotation.Five - surveyRatingRotation.One) * (clamped - 1) / 4;
        setSurveyNeedleRotation(angle);
        setGaugeLoading(false);
    }, [averageRating]);

    useEffect(() => {
        api.start({ angle: surveyNeedleRotation });
    }, [surveyNeedleRotation, api]);

    const needleTransform = needleSpring.angle.to(angle => `rotate(${angle}deg)`);

    return (
        <>
            { fetchingResponseStats &&
                <LottieLoadingIndicator />
            }
            { !fetchingResponseStats &&
                <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <OverviewCard>
                        <div style={{ margin: "50px 35px", textAlign: "center", position: "relative" }}>
                            { gaugeLoading &&
                                <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                                    <Skeleton height="200px" width="415px" animation="wave" variant="rectangle"/>
                                </div>
                            }
                            { !gaugeLoading &&
                                <div style={{ position: "relative" }}>
                                    <img src={RatingGauge} alt={RatingGauge} height="200px" />
                                    <animated.img
                                        src={GaugeNeedle}
                                        alt={GaugeNeedle}
                                        style={{
                                            height: "50px",
                                            position: "absolute",
                                            bottom: "8px",
                                            left: "180px",
                                            transformOrigin: "75% 50%",
                                            transform: needleTransform,
                                        }}
                                    />
                                </div>
                            }
                            <Text> 
                                This guage represents the total average results of users answers for <b>Question {surveyQuestion.questionOrder}</b>.
                            </Text>
                        </div>
                    </OverviewCard>

                    <OverviewCard style={{ width: "265px", display: "flex", flexDirection: "column" }}>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconExcellent} alt={IconExcellent} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    Excellent
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" weight="700" color="#8291B2" style={{ margin: "auto 0" }}>
                                    { excellentCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconGreat} alt={IconGreat} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    Great
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" weight="700" color="#8291B2" style={{ margin: "auto 0" }}>
                                    { greatCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconOk} alt={IconOk} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    Ok
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" weight="700" color="#8291B2" style={{ margin: "auto 0" }}>
                                    { okCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow>
                            <RatingIconCell>
                                <img src={IconPoor} alt={IconPoor} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    Poor
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" weight="700" color="#8291B2" style={{ margin: "auto 0" }}>
                                    { poorCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                        <RatingRow borderRowBottom="unset !important">
                            <RatingIconCell>
                                <img src={IconBad} alt={IconBad} height="36px" width="36px" style={{ margin: "auto 10px auto 0" }} />
                                <Text color="#8291B2" size="15px" margin="auto 0">
                                    Bad
                                </Text>
                            </RatingIconCell>
                            <RatingNumberCell>
                                <Text size="20px" weight="700" color="#8291B2" style={{ margin: "auto 0" }}>
                                    { badCount }
                                </Text>
                            </RatingNumberCell>
                        </RatingRow>
                    </OverviewCard>
                </div>
            }
        </>
    );
};
export default SurveySatisfactionCard;