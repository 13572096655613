import { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as sdk from "matrix-js-sdk";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";
import bootstrapOlm from "../../Olmbootstrapper";
import { Buffer } from "buffer";
import { LocalStorageCryptoStore, ClientEvent } from "matrix-js-sdk";
import { Toast } from "src/components";

window.Buffer = Buffer;

export const useMatrixClient = (headers) => {
    const userContext = useContext(UserContext);
    const [matrixClient, setMatrixClient] = useState(null);
    const [isInitialSyncComplete, setIsInitialSyncComplete] = useState(false);

    useEffect(() => {
        const initializeMatrixClient = async () => {
            try {
                const storage = new LocalStorageCryptoStore("matrix_storage");
                const session = await registerClient(headers);
                const client = createClient(
                    API_URL,
                    userContext.token,
                    session.userId, 
                    session.deviceId,
                    storage,
                );

                await initializeOlm();
                await client.initCrypto();

                client.setGlobalErrorOnUnknownDevices(false);

                await client.startClient({ initialSyncLimit: 10 });
                setMatrixClient(client);
                initialSync(client);
            } catch (error) {
                return Toast.error("Failed to initialize Matrix client.");
            }
        };

        initializeMatrixClient();
    }, []);

    const initialSync = async (client) => {
        client.once(ClientEvent.Sync, (state, prevState, res) => {
            if (state === "PREPARED") {
                setIsInitialSyncComplete(true);
            }
            else {
                process.exit(1);
            }
        });
    };


    return { 
        matrixClient,
        isInitialSyncComplete
    };
};

const registerClient = async (headers) => {
    const response = await axios.get(`${API_URL}/chats/registration`, headers);
    return response.data.session;
};

const createClient = (baseUrl, token, userId, deviceId, storage) => {
    return sdk.createClient({
        baseUrl: baseUrl,
        accessToken: token,
        userId: userId,
        deviceId: deviceId,
        storage,
        cryptoCallbacks: {
            setup: true,
        }
    });
};

const initializeOlm = async () => {
    return bootstrapOlm().then(() => console.log("Olm initialized."))
        .catch(err => console.error("Olm failed to initialize."));
};