import { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const getDonationList = (token) => {
    const [donationList, setDonationList] = useState([]);
    const [isFetchingDonationList, setIsFetchingDonationList] = useState(false);
    
    const fetch = async () => {
        setIsFetchingDonationList(true);
        try {
            const response = await axios.get(`${API_URL}/donation/organisations`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            setDonationList(response.data.organisations);
        } catch (error) {
            Toast.error("Unable to fetch donations. Please try again later or contact support if the issue persists.");
        } finally {
            setIsFetchingDonationList(false);
        }
    };

    useEffect(() => {
        fetch();
    }, [token]);

    return { donationList, setDonationList, isFetchingDonationList, fetchDonationList: fetch };
};

export default getDonationList;