import React from "react";
import Lottie from "react-lottie-player";

const LottieAnimation = (props) => {
    return (
        <div style={{ height: props.height || 120, width: props.width || 120, margin: props.margin || "25px 0 0 0" }}>
            <Lottie
                loop
                play
                animationData={props.animationJsonData}
            />
        </div>
    );
};

export default LottieAnimation;
