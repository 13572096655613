
import styled from "styled-components";

const Initials = styled.div`
    font-family: ${p => p.family || "Roboto, Helvetica, sans-serif"};
    font-size: 16px;
    font-weight: 600;
    width: 42px;
    height: 42px;
    color: ${p => p.color || "black"};
    border-radius: 50%;
    background-color: ${p => p.backgroundColor || "white"};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Initials;
