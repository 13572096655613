import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core/";
import Permission from "./PermissionType";
import { ACCOUNT_OWNER, MULTIPLE_PERMISSIONS } from "src/constants/premissionRoles";
import { IsFeatureReady } from "src/constants/features";

const RolesForm = (props) => {
    const { isClient, roles, userRole, isUserRole, clickRole, disableInput, currentSelectedRole, 
        permissions, checkIfPermissionIsRoleDefault, checkSelectedPermissionsIfMultiple,
        checkChatPermissions, disableChatPermission, hasLeadershipRole } = props;

    return (
        <>
            { 
                <form className="permission-modal-form">
                    <>
                        <hr />
                        <RolesContainer 
                            isClient={isClient} 
                            roles={roles}
                            userRole={userRole} 
                            isUserRole={isUserRole} 
                            clickRole={clickRole} 
                            disableInput={disableInput} 
                            currentSelectedRole={currentSelectedRole} 
                            hasLeadershipRole={hasLeadershipRole}
                        />
                    </>
                
                    { userRole !== ACCOUNT_OWNER && 
                        <>
                            <hr style={{ marginRight: 30 }}/>
                            <div className="custom-scroll" 
                                style={{ 
                                    display: "grid", gridTemplateColumns: "repeat(2, 1fr)"
                                }}
                            >
                                <div className="col">
                                    { permissions.app && <AppPermission 
                                        permissions={permissions}
                                        checkIfPermissionIsRoleDefault={checkIfPermissionIsRoleDefault}
                                        checkSelectedPermissionsIfMultiple={checkSelectedPermissionsIfMultiple} 
                                        disableInput={disableInput}
                                    />
                                    }
                                    <ChatPermission 
                                        permissions={permissions}
                                        checkChatPermissions={checkChatPermissions}
                                        checkSelectedPermissionsIfMultiple={checkSelectedPermissionsIfMultiple} 
                                        disableChatPermission={disableChatPermission}
                                    />
                                </div>

                                <div className="col">
                                    <WebPermission 
                                        permissions={permissions}
                                        checkIfPermissionIsRoleDefault={checkIfPermissionIsRoleDefault} 
                                        checkSelectedPermissionsIfMultiple={checkSelectedPermissionsIfMultiple}
                                        disableInput={disableInput}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </form>
            }
        </>
    );
};

const Checkbox = (props) => {
    const { item } = props;
    const [ isChecked, setIsChecked ] = useState(props.isChecked);

    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);

    const handleChange = () => {
        setIsChecked(!isChecked);
        props.checkForMultiple(!isChecked);
    };

    return (
        <div className="check-field" style={{ display: props.hidden ? "none" : "inline-block" }}>
            <input type="checkbox" className="permissions-checkbox" value={item.id}
                checked={ isChecked }
                onChange={handleChange}
                disabled={props.disabled}
            />
            <label>{ item.name }</label>
            <div className="sub-text">
                { item.description }
            </div>
        </div>
    );
};

const RolesContainer = (props) => {
    const { isClient, roles, userRole, isUserRole, clickRole, disableInput, currentSelectedRole, hasLeadershipRole } = props;

    return (
        <div className="item-row" style={{ marginRight: 30 }}>
            <div className="row-label">Roles</div>
            <div className="row" >

                { (!isClient && userRole !== ACCOUNT_OWNER) && 
                    <Grid className="grid grid-col-2" >
                        {   
                            roles && roles.map((role, index) => {
                                return (
                                // hide the Account Owner role if selected user is not an account owner
                                // also hide "Multiple Permissions" role
                                    (userRole !== ACCOUNT_OWNER && role.id === ACCOUNT_OWNER) || role.id === MULTIPLE_PERMISSIONS ? ""
                                        :
                                        <div key={index} className="radio-field">
                                            <input type="radio" name="role" value={role.id} 
                                                checked={ isUserRole(role.id) }
                                                onChange={() => clickRole(role.id)}
                                                disabled={disableInput}
                                            />
                                            <label htmlFor="">{ role.name }</label>
                                        </div>    
                                );
                            })
                        }
                    </Grid>
                }
                <div className={hasLeadershipRole && "grid grid-col-2"} >
                    <Permission role={currentSelectedRole} isClient={isClient} hasLeadershipRole={hasLeadershipRole}></Permission>
                </div>
            </div>
        </div>
    );
};

const AppPermission = (props) => {
    const { permissions, checkIfPermissionIsRoleDefault, checkSelectedPermissionsIfMultiple, disableInput } = props;

    return (
        <>
            <div className="row-label">App Permissions</div>
            {
                permissions.app && permissions.app.map((item, i) => {
                    return (
                        <Checkbox key={item.id}
                            item={item}
                            isChecked={() => checkIfPermissionIsRoleDefault(item.id)}
                            checkForMultiple={checkSelectedPermissionsIfMultiple}
                            disabled={disableInput}
                        />
                    );
                })
            }
        </>
    );
};

const ChatPermission = (props) => {
    const { permissions, checkChatPermissions, checkSelectedPermissionsIfMultiple, disableChatPermission } = props;
    return (
        <div>
            <div className="row-label">Chat Permissions (App & Web)</div>
            {
                permissions.chat && permissions.chat.map(item => {
                    return (
                        <Checkbox key={item.id}
                            item={item}
                            hidden={false}
                            isChecked={() => checkChatPermissions(item.id)}
                            checkForMultiple={checkSelectedPermissionsIfMultiple}
                            disabled={disableChatPermission(item.id)}
                        />
                    );
                })
            }
        </div>
    );
};

const WebPermission = (props) => {
    const { permissions, checkIfPermissionIsRoleDefault, checkSelectedPermissionsIfMultiple, disableInput } = props;
    return (
        <>
            <div className="row-label">Web Dashboard Permissions</div>
            {
                permissions.web && permissions.web.map(item => {
                    if (!IsFeatureReady.donations() && item.name.includes("Donations")) {
                        item.name = "Perks";
                        item.description = "Allows users to create and manage new perks for employees and clients.";
                    }
                    return (
                        <Checkbox
                            key={item.id}
                            item={item}
                            isChecked={() => checkIfPermissionIsRoleDefault(item.id)}
                            checkForMultiple={checkSelectedPermissionsIfMultiple}
                            disabled={disableInput}
                        />
                    );
                })
            }
        </>
    );
};

export default RolesForm;
