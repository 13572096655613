import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";

const useDonations = (token) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const config = {
        signal: controller.signal,
        headers: {
            Authorization: "Bearer " + token
        }
    };

    useEffect(() => {
        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, []);
    
    const [isFormSaving, setIsFormSaving] = useState(false);

    const saveDonationRequest = async (formData, updatingUuid) => {
        setIsFormSaving(true);

        try {
            if (updatingUuid) {
                const response = await axios.put(`${API_URL}/donation/company/${updatingUuid}`, formData, config);
                return response.data.organisation;
            } else {
                const response = await axios.post(`${API_URL}/donation/company`, formData, config);
                return response.data.organisation;
            }
        } catch (error) {
            if (isMountedRef.current) {
                throw error; //rethrowing error for outer try catch block
            }
        } finally {
            if (isMountedRef.current) {
                setIsFormSaving(false);
            }
        } 
    };

    async function requestS3PresignedUrl(imageType) {
        try {
            // request S3 presigned Url
            const response = await axios.get(`${API_URL}/donation/company/generateImageUrl/${imageType}`, config);
            const s3PresignedUrl = response.data.url;
            return s3PresignedUrl;
        } catch (e) {
            console.warn("Unable to request presigned URL for organisation image.", e);
            return "";
        }
    }

    async function uploadToS3(s3PresignedUrl, fileObject) {
        try {
            // upload image to S3
            await axios.put(s3PresignedUrl, fileObject, {
                headers: { "Content-Type": fileObject.type }
            });

            const storedUrl = s3PresignedUrl.substring(0, s3PresignedUrl.indexOf("?"));
            return storedUrl;
        } catch (e) {
            console.warn("Unable to upload donation organisation image.", e);
            return "";
        }
    }

    const deleteMultipleDonation = async (donationUuids) => {
        try {
            const responses = await Promise.all(
                donationUuids.map((donationUuid) => axios.delete(`${API_URL}/donation/company/${donationUuid}`, config))
            );
      
            const non200Responses = responses.filter((response) => response.status !== 200);
            const non200Count = non200Responses.length;
      
            // Handle success or failure based on non200Count
            if (non200Count === 0) {
                return "All selected donation organisations have been deleted successfully.";
            } else {
                return "Some donation organisations have not been deleted.";
            }
        } catch (error) {
            console.error("Error deleting donation organisations: ", error);
            throw error; //rethrow
        }
    };

    return { 
        isFormSaving, 
        requestS3PresignedUrl,
        uploadToS3,
        saveDonationRequest,
        deleteMultipleDonation
    };
};

export default useDonations;