import React from "react";
import { Box, Grid, Dialog, Divider } from "@material-ui/core/";
import { Button, FormField, Text } from "src/components";
import styled from "styled-components";

const StyledInput = styled(FormField)`
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    padding: 0 30px;
`;

const Label = styled.div`
    font-weight: 700;
    color: #000000;
    font-size: 14px;
    margin-bottom: 13px;
`;

const UserFormSuccessModal = (props) => {
    const { showDialog, handleClose, openSendInviteForm, user, isUserNewlyCreated } = props;

    const handlePreInvite = (user) => {
        openSendInviteForm([user]);
    };

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "56.25rem",
                        paddingBottom: "2em",
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto"
                    },
                }}
            >
                <Box sx={{ margin: "0 2.44rem 3.34375rem 3.44rem", fontFamily: "Roboto, Helvetica, sans-serif", }}>
                    { props.title ? props.title
                        :
                        <Text color="#11141A"
                            style={{ fontWeight: 700, fontSize: "2.5rem", textAlign: "center", marginBottom: "2.5rem" }}
                        >
                            New { user.isClient ? "Client" : "Employee" } Added Successfully!
                        </Text>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Label>First Name</Label>
                            <StyledInput value={user.firstName} onChange={() => ""} style={{ textTransform: "capitalize" }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Label>Last Name</Label>
                            <StyledInput value={user.lastName} onChange={() => ""} style={{ textTransform: "capitalize" }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Label>Email</Label>
                            <StyledInput value={user.email} onChange={() => ""} />
                        </Grid>
                    </Grid>
                </Box>

                {isUserNewlyCreated && 
                    <Box sx={{ 
                        margin: "0.875rem 3.5625rem 2.75rem 3.5625rem", 
                        justifyContent: "space-between", alignItems: "center"
                    }}
                    >
                        <Text size="1.875rem" weight="800" align="left" margin="0 0 0.5em 0">
                            Send App Invite?
                        </Text>

                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <Text size="1rem" align="left" margin="0">
                                    Would you like to send an app invite to this user now? Click the “Send Invite” 
                                    button to proceed. You can also send app invites later through the User Invite 
                                    column in the My User Lists section.
                                </Text>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
                                <Button backgroundColor="#006CFF" color="#fff"
                                    border="1px solid #006CFF"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                    type="submit"
                                    onClick={() => handlePreInvite(user)}
                                    className="form-btns disabled"
                                >
                                    Send Invite
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Divider />
                <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
                    <Button color="#000" weight="500" backgroundColor="#fff"
                        border="1px solid #000000" width="9.75rem"
                        style={{ borderRadius: 7 }}
                        onClick={handleClose}
                    >{ !isUserNewlyCreated ? "Done" : "Send Invite Later"}</Button>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default UserFormSuccessModal;