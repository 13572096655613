import React from "react";
import styled from "styled-components";
import {
    Button, ButtonWithIcon, FlexContainer, Text,
    mobile, desktop, tablet
} from "src/components";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import SearchImg from "src/img/new/search.svg";
import LicenseIcon from "src/img/new/license-blue.svg";
import { useState, useEffect } from "react";
import { capitalizeString } from "src/utils/helpers";
import { Forms } from "src/scenes/Setting/Setting";

const EnumLicenseStatus = {
    NOT_STARTED: "notStarted",
    ACTIVE: "active",
    EXPIRED: "expired",
    TERMINATED: "terminated",
    RENEWED: "renewed",
    INVALID_STATUS: "invalidStatus"
};

const EnumAppLicenseStatus = {
    NOT_STARTED: {
        COLOR: "#006CFF", BACKGROUND: "#EAF3FF", TEXT: "Not Started"
    },
    ACTIVE: {
        COLOR: "#26A26B", BACKGROUND: "#E6FFF8", TEXT: "Active License"
    },
    SEND_RENEWAL_LINK: {
        COLOR: "#EFE7FF", BACKGROUND: "#633FE4", TEXT: "Send Renewal Link"
    },
    TERMINATED: {
        COLOR: "#FF3B3B", BACKGROUND: "#FDE3E3", TEXT: "Renewal Not Available"
    },
    DEFAULT: {
        COLOR: "#FFF", BACKGROUND: "#000", TEXT: "Invalid Status"
    }
};

const EnumUserStatus = {
    AWAITING_INVITE: { COLOR: "#000", TEXT: "Awaiting Invite" },
    PENDING: { COLOR: "#006CFF", TEXT: "Pending" },
    ACTIVE: { COLOR: "#36BE24", TEXT: "Active" },
    EXPIRED: { COLOR: "#FF3B3B", TEXT: "Expired" },
    DEFAULT: { COLOR: "#000", TEXT: "Invalid Status" }
};

const ButtonWithIconStyled = styled(ButtonWithIcon)`
    max-width: 15.125rem;
    margin-bottom: .5em;
    margin-right: .5em;
    ${mobile`
        margin-top: 1em;
        margin-bottom: 1em;
    `};
`;

const UpperButtons = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    max-width: 13.125rem;
    width: 100%;
    color: ${p => p.color || "#FFF"};
    background-color: #006CFF;
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 700;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
    margin-bottom: .5em;
`;

const ButtonLabel = styled.button`
    height: 2rem;
    background: ${p => p.background};
    color: ${p => p.color};
    border: ${p => p.border || "none"};
    font-size: 0.75rem;
    font-weight: ${p => p.weight || 500};
    text-align: center;
    width: ${p => p.width};
    box-sizing: border-box;
    border-radius: 7px;
    cursor: ${p => p.cursor || "auto"};
`;

const Td = styled.td`
    text-align: ${p => p.align || "left"};
    padding-left: ${p => p.paddingLeft || "1.4375rem !important"};
`;

const SortableColumns = {  
    FIRST_NAME: 1,
    LAST_NAME: 2,
    EMAIL: 3,
    USER_TYPE: 4,
    LICENSE_TYPE: 5,
    APP_LICENSE: 7,
    USER_INVITES: 9,
    USER_STATUS: 11,
};

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 98.625rem;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    justify-content: normal;
    align-items: baseline;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        flex-direction: row;
    `};
`;

const SearchInput = styled.input`
    position: relative;
    background-image: url(${SearchImg});
    background-repeat: no-repeat;
    text-indent: 25px;
    background-position: 16px center;
    line-height: 100%;
    width: 95%;
    border: 1px solid #000;
    max-width: 560px;
    height: 39px;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    border-radius: 10px;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
    }

    ${tablet`
        margin-bottom: 1rem;
    `};

    ${mobile`
        margin-bottom: 1rem;
    `};

    ${desktop`
        margin-bottom: 0
    `};
`;

const ClientLicenseTable = (props) => {
    const { showPurchaseLicense, addClient, sendRenewalLink, sendInvite, updateClient, isFreeTrialLicense } = props;
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [clients, setClients] = useState([]);
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [appLicenseSortOrder, setAppLicenseSortOrder] = useState(EnumLicenseStatus.NOT_STARTED);
    const [inviteSort, setInviteSort] = useState("active");

    useEffect(() => {
        setClients(props.clientLicenseArray);
    }, [props.clientLicenseArray]);

    /** FUNCTIONS */
    const handleSearchUser = (e) => {
        const value = e.target.value;
        if (value.length) {
            const filteredClients = clients.filter(client =>
                client.firstName.toLowerCase().includes(value.toLowerCase()) || client.lastName.toLowerCase().includes(value.toLowerCase())
            );
    
            setClients(filteredClients);
        }
        else {
            setClients(props.clientLicenseArray);
        }
    };

    const handleSortColumn = (columnIndex) => {
        if (!clients.length) return;

        if ([SortableColumns.APP_LICENSE, SortableColumns.USER_STATUS].includes(columnIndex)) {
            return sortByLicenseStatus();
        }
        else if (columnIndex === SortableColumns.USER_INVITES) {
            return sortByInvite();
        }
        else {
            let fieldName;
            switch (columnIndex) {
            case SortableColumns.FIRST_NAME:
                fieldName = "firstName";
                break;
            case SortableColumns.LAST_NAME:
                fieldName = "lastName";
                break;
            case SortableColumns.EMAIL:
                fieldName = "email";
                break;
            case SortableColumns.LICENSE_TYPE:
                fieldName = "licenseType";
                break;
            default:
                fieldName = null;
                break;
            }

            if (fieldName === null) return;

            if (sortColumn === columnIndex) {
                setSortDirection(sortDirection === "asc" ? "desc" : "asc");
            } else {
                setSortColumn(columnIndex);
                setSortDirection("asc");
            }
    
            return sortFields(fieldName);
        }
    };

    const sortByInvite = () => {
        let next;
        const Status = {
            ACTIVE: "active",
            INVITE_SENT: "inviteSent",
            INVITE_NOT_SENT: "inviteNotSent",
        };

        switch (inviteSort) {
        case Status.ACTIVE:
            next = Status.INVITE_SENT;
            break;
        case Status.INVITE_SENT:
            next = Status.INVITE_NOT_SENT;
            break;
        default:
            next = Status.ACTIVE;
            break;
        }

        const sortedClients = [...clients].sort((a, b) => {
            switch (inviteSort) {
            case Status.ACTIVE: 
                return a.licenseStatus === EnumLicenseStatus.ACTIVE ? -1 : 1;
            case Status.INVITE_SENT:
                return a.hasSentInvite ? -1 : 1;
            case Status.INVITE_NOT_SENT:  
                if (!a.hasSentInvite && a.licenseStatus !== EnumLicenseStatus.ACTIVE) {
                    return -1;
                }
                return 1;
            default:
                return 0;
            }
        });
        
        setClients(sortedClients);
        setInviteSort(next);
    };

    const sortByLicenseStatus = () => {
        const { ACTIVE, EXPIRED, NOT_STARTED } = EnumLicenseStatus;
        let next;

        const sortedClients = [...clients].sort((a, b) => {
            if (a.licenseStatus === appLicenseSortOrder) return -1;
            if (b.licenseStatus === appLicenseSortOrder) return 1;
            return 0;
        });

        switch (appLicenseSortOrder) {
        case NOT_STARTED:
            next = ACTIVE;
            break;
        case ACTIVE:
            next = EXPIRED;
            break;
        default:
            next = NOT_STARTED;
            break;
        }
        setClients(sortedClients);
        setAppLicenseSortOrder(next);
    };

    const sortFields = (fieldName) => {
        const sortedClients = [...clients].sort((a, b) => {
            const valueA = a[fieldName];
            const valueB = b[fieldName];
            const comparison = valueA.localeCompare(valueB);
            return sortDirection === "asc" ? comparison : -comparison;
        });

        return setClients(sortedClients);
    };

    const headings = [
        <Checkbox readOnly checked={selectAllUsers} onChange={() => ""} />,
        "First Name", "Last Name", "Email",
        "User Type", "License Type", "End Date", "App License",
        "View/Edit User", "User Invites", "User Access Code", "User Status"
    ];

    const handleMainCheckbox = (e) => {
        const isChecked = !e.target.checked;
        if (!isChecked) {
            setSelectedUsers([]);
        }
        else {
            setSelectedUsers(clients);
        }
        setSelectAllUsers(!selectAllUsers);
    };

    const addOrRemoveClientFromSelected = (event, client) => {
        const isChecked = event.target.checked;
        return isChecked ? setSelectedUsers([...selectedUsers, client])
            : setSelectedUsers(selectedUsers.filter(obj => obj["uuid"] !== client.uuid));
    };

    const checkIfClientIsSelected = (uuid) => selectedUsers.some(obj => obj["uuid"] === uuid);
    const isASortableColumn = (index) => {
        for (const key in SortableColumns) {
            if (SortableColumns[key] === index) {
                return true;
            }
        }
        return false;
    };

    /** RENDERS */
    /**
     * 
     * @param {string} licenseStatus
     * @param {bool} hasSentInvite
     * @param {bool|string} licenseType
     * @returns <Text />
     */
    const renderUserStatus = (licenseStatus, hasSentInvite, licenseType = false) => {
        let userStatus = EnumUserStatus.DEFAULT;
        
        if ([EnumLicenseStatus.EXPIRED, EnumLicenseStatus.TERMINATED].includes(licenseStatus)) {
            userStatus = EnumUserStatus.EXPIRED;
        }
        else if (licenseStatus === EnumLicenseStatus.ACTIVE) {
            userStatus = EnumUserStatus.ACTIVE;
        }

        else if (hasSentInvite) {
            userStatus = EnumUserStatus.PENDING;
        }
        else if (licenseStatus === EnumLicenseStatus.NOT_STARTED && !hasSentInvite) {
            userStatus = EnumUserStatus.AWAITING_INVITE;
        }

        if (!licenseType) {
            // display User Status
            return <Text align="left" color={userStatus.COLOR} weight="500">
                { userStatus.TEXT }
            </Text>;
        }

        // display License Type
        if (userStatus.TEXT === EnumUserStatus.AWAITING_INVITE.TEXT) { //Awaiting & Pending uses the same `License Type` color
            return <Text align="left" color={EnumUserStatus.PENDING.COLOR} weight="500">
                { licenseType }
            </Text>;
        }

        return <Text align="left" color={userStatus.COLOR} weight="500">
            { licenseType }
        </Text>;
    };

    /**
     * 
     * @param {object} client 
     * @returns <ButtonLabel />
     */
    const renderAppLicense = (client) => {
        let status;
        let isExpired = false;
        switch (client.licenseStatus) {
        case EnumLicenseStatus.NOT_STARTED:
            status = EnumAppLicenseStatus.NOT_STARTED;
            break;
        case EnumLicenseStatus.ACTIVE:
            status = EnumAppLicenseStatus.ACTIVE;
            break;
        case EnumLicenseStatus.EXPIRED:
            status = EnumAppLicenseStatus.SEND_RENEWAL_LINK;
            isExpired = true;
            break;
        case EnumLicenseStatus.TERMINATED:
            status = EnumAppLicenseStatus.TERMINATED;
            break;
        default:
            status = EnumAppLicenseStatus.DEFAULT;
            status.TEXT = client.licenseStatus;
            break;
        }

        return <ButtonLabel background={status.BACKGROUND} 
            color={status.COLOR} width="8.625rem"
            cursor={ isExpired ? "pointer" : "auto"}
            onClick={() => isExpired ? sendRenewalLink(client) : ""}
        >
            {status.TEXT}
        </ButtonLabel>;
    };

    const renderInviteButton = (client) => {
        if ([EnumLicenseStatus.ACTIVE, EnumLicenseStatus.EXPIRED].includes(client.licenseStatus)) {
            return "-";
        }
        else {
            if (!client.hasSentInvite) {
                return <ButtonLabel cursor="pointer" background="#006CFF" color="#FFF" 
                    width="5.875rem" onClick={() => sendInvite(client)}
                >Send Invite</ButtonLabel>;
            }
            else {
                return <ButtonLabel cursor="pointer" background="#FFF" color="#006CFF" 
                    border="1px solid #8291B2" width="5.875rem"
                    onClick={() => sendInvite(client)}
                >Resend Invite</ButtonLabel>;
            }
        }
    };

    const renderHeadings = headings.map((heading, index) => {
        return (
            <th key={index}
                onClick={(e) => index > 0 ? handleSortColumn(index) : handleMainCheckbox(e)}
                style={{ 
                    textAlign: index > 0 ? "left" : "center",
                    padding: "1.0625rem 1.4375rem",
                    cursor: isASortableColumn(index) ? "pointer" : "auto"
                }}
            >
                { heading }
            </th>
        );
    });

    const renderRows = clients.map((client, index) => {
        return (
            <tr key={index}
                className={checkIfClientIsSelected(client.uuid) ? "selected" : "" }
            >
                <Td paddingLeft="0" align="center">
                    <label>
                        <Checkbox readOnly
                            checked={checkIfClientIsSelected(client.uuid) || selectAllUsers }
                            value={client.uuid}
                            onChange={(e) => addOrRemoveClientFromSelected(e, client)}
                        />
                    </label>
                </Td>
                <Td className="change-color-on-select">{ capitalizeString(client.firstName) }</Td>
                <Td className="change-color-on-select">{ capitalizeString(client.lastName) }</Td>
                <Td className="change-color-on-select">{ client.email }</Td>
                <Td className="change-color-on-select">Client License</Td>
                <Td>{ renderUserStatus(client.licenseStatus, client.hasSentInvite, client.licenseType) }</Td>
                <Td style={{ minWidth: "7.5rem" }}>
                    <Text maxWidth="6.75rem" align={client.endDate ? "left" : "center"}
                        color={checkIfClientIsSelected(client.uuid) ? "#174A84" : "#000"}
                        style={{ whiteSpace: "normal" }}
                    >
                        { client.endDate || "-"}
                    </Text>
                    
                </Td>
                <Td>{ renderAppLicense(client) }</Td>
                <Td>
                    <ButtonLabel background="#FFF" color="#000" cursor="pointer"
                        border="1px solid #000000" width="6.25rem" weight="700"
                        onClick={() => updateClient(client)}
                    >
                        View/Edit
                    </ButtonLabel>
                </Td>
                <Td align="center">{ renderInviteButton(client) }</Td>
                <Td><ButtonLabel background="#000" color="#FFF" width="6.25rem">{client.accessToken}</ButtonLabel></Td>
                <Td style={{ padding: 0 }}>
                    <div style={{ maxWidth: "3.375rem", whiteSpace: "normal" }}>
                        {renderUserStatus(client.licenseStatus, client.hasSentInvite)}
                    </div>
                </Td>
            </tr>
        );
    });

    const showEmptyRows = () => {
        const emptyRows = 10 - clients.length;
        
        if (emptyRows < 1)
            return;

        return Array.from({ length: emptyRows }, (_, i) => (
            <tr key={"empty_" + i}>
                <Td paddingLeft="0" align="center">&nbsp;</Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
                <Td className="change-color-on-select"></Td>
            </tr>
        ));
    };

    return (
        <LicenseTable 
            handleSearchUser={handleSearchUser} 
            showPurchaseLicense={showPurchaseLicense}
            addClient={addClient}
            renderHeadings={renderHeadings}
            renderRows={renderRows}
            showEmptyRows={showEmptyRows}
            isFreeTrialLicense={isFreeTrialLicense}
        />

    );
};

function LicenseTable(props) {
    const handleClick = () => {
        window.location.href = Forms.RENEWALS_AND_EARNINGS;
    };

    const { handleSearchUser, showPurchaseLicense, addClient, renderHeadings, renderRows, showEmptyRows, isFreeTrialLicense } = props;

    return (
        <div>
            <UpperControls>
                <SearchInput placeholder="Search" type="text" onChange={(e) => handleSearchUser(e)} />
                <FlexContainer direction="row" alignItems="center" className="right"
                    margin="0 2.0625rem 0 0"
                    width="100%"
                >
                    {/* T6726 - hiding until multiple invites is created */}
                    {/* change container width to 41.625rem upon activating  */}
                    {/* <UpperButtons>Send Invite</UpperButtons> */}
                    <FlexContainer alignItems="left" direction="row" margin="0" width="100%"
                    >
                        <div>
                            <Button backgroundColor="#8551AE" onClick={handleClick}
                                color="#FFF"
                                borderRadius="10px !important"
                                size="0.875rem"
                                weight="700"
                                width="100%"
                                height="2.4375rem"
                                style={{ marginLeft: "1em", maxWidth: "15.125rem", boxShadow: "0px 3px 6px #00000029", marginBottom: ".5em" }}
                            >
                                    View Renewals & Earnings
                            </Button>
                        </div>
                        <FlexContainer alignItems="left" direction="row" margin="0 0 0 1em" width="100%" justifyContent="right">
                            
                            {
                                !isFreeTrialLicense && <ButtonWithIconStyled icon={LicenseIcon}
                                    borderRadius="10px !important"
                                    iconHeight="18px" iconWidth="28px"
                                    iconMargin="0 0.625rem 0 0"
                                    backgroundColor="#EAF3FF"
                                    color="#006CFF"
                                    border="1px solid #006CFF"
                                    text="Purchase Trial Licenses"
                                    height="2.4375rem"
                                    onClick={(e) => showPurchaseLicense(true) }
                                />
                            }
                            <UpperButtons
                                padding="0 4.5rem !important"
                                onClick={(e) => addClient()}
                            >
                                Add Client
                            </UpperButtons>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </UpperControls>
            
            <div className="scrollable-table" style={{ maxWidth: "1586px", }}>
                <table>
                    <thead>
                        <tr>{ renderHeadings }</tr>
                    </thead>
                    <tbody>{ renderRows }</tbody>
                    <tbody>{ showEmptyRows() }</tbody>
                </table>
            </div>
        </div>
    );
}

export default ClientLicenseTable;