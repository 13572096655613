import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LottieLoadingIndicator, Text } from "src/components";
import { countSurveyResponseValue, generateYesNoBarGraphData } from "../Utils";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const OverviewCard = styled.div`
    width: 580px;
    height: 330px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #00000026;
    border: 1px solid #8291B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
`;

const OverviewCounterContainer = styled.div`
    width: 200px;
    height: 100%;
    margin: auto 0 4% 16px;
`;

const OverviewCounterItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 330px;
    margin-bottom: ${p => p.marginBottom};
    border: 1px solid #8291B2;
    box-shadow: 0px 5px 5px #00000026;
    border-radius: 10px;
`;

const OverviewCounterCircleIcon = styled.div`
    width: 47px;
    height: 47px;
    background: ${p => p.backgroundColor || "#000"};
    border-radius: 25px;
`;

const OverviewCounterDetails = styled.div`
    display: flex;
    width: 110px;
    flex-direction: column;
    margin-left: 16px;
    text-align: left;
`;

const SurveyYesNoCard = (props) => {
    const { surveyQuestion, responsesStats, fetchingResponseStats, numberOfUsersInTargetGroup } = props;
    const [yesCount, setYesCount] = useState(0);
    const [noCount, setNoCount] = useState(0);
    const [lineGraphData, setLineGraphData] = useState({ labels: [], dataSets: [] });

    /**
     * this is the options for the ChartJS,
     */
    let options = {
        responsive: true,
        title: {
            display: true
        },
        plugins: {
            tooltip: {
                enabled: false
            },
            title: {
                display: false,
            },
            legend: { 
                display: true,
                position: "bottom",
                fullWidth: true,
                labels: {
                    font: {
                        size: 16,
                        family: "Roboto, Helvetica, sans-serif",
                        weight: "medium"
                    }
                }
            },
            border: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    lineWidth: 3
                },
                label: {
                    display: false
                },
                ticks: {
                    display: false,
                    beginAtZero: true,
                },
                border: {
                    display: false,
                },
                max: numberOfUsersInTargetGroup,
            },
            x: {
                grid: {
                    display: false,
                },
            }
        },
    };

    useEffect(() => {
        if (responsesStats) {
            const yesCountedResult = countSurveyResponseValue(responsesStats, "yes", surveyQuestion.uuid);
            const noCountedResult = countSurveyResponseValue(responsesStats, "no", surveyQuestion.uuid);
            setYesCount(yesCountedResult);
            setNoCount(noCountedResult);
            generateYesNoBarGraphData(responsesStats, setLineGraphData, surveyQuestion.uuid);
        }
    }, [responsesStats]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <OverviewCard>
                <div style={{ zIndex: 1, width: "100%", height: "100%", display: "flex", borderRadius: "12px" }}>
                    { lineGraphData?.labels?.length > 0 &&
                        <Bar
                            options={options}
                            data={lineGraphData}
                            responsive={true}
                            aspectRatio={2}
                            maintainAspectRatio={false}
                            width={"100%"}
                            style={{ borderRadius: "10px", margin: "auto 0", height: "99%" }}
                        />
                    }
                </div>
            </OverviewCard>
            <OverviewCounterContainer>
                { fetchingResponseStats &&
                    <LottieLoadingIndicator/>
                }
                { !fetchingResponseStats &&
                    <>
                        <OverviewCounterItemContainer>
                            <div style={{ display: "flex", flexDirection: "row", padding: "62px 38px 58px 38px", height: "auto", borderBottom: "1px solid #8291B2" }}>
                                <OverviewCounterCircleIcon backgroundColor="#02BC77" >
                                    <Text margin="14px 0" color="#fff" >
                                        Yes
                                    </Text>
                                </OverviewCounterCircleIcon>
                                <OverviewCounterDetails marginBottom="30px">
                                    <Text margin="0" align="left" size="20px" color="#8291B2" weight="700">
                                        { yesCount }
                                    </Text>
                                    <Text margin="0" align="left" size="16px" color="#8291B2" weight="500">
                                        Answered &apos;Yes&apos;
                                    </Text>
                                </OverviewCounterDetails>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", margin: "auto", height: "auto" }}>
                                <OverviewCounterCircleIcon backgroundColor="#0274BC" >
                                    <Text margin="14px 0" color="#fff" >
                                        No
                                    </Text>
                                </OverviewCounterCircleIcon>
                                <OverviewCounterDetails>
                                    <Text margin="0" align="left" size="20px" color="#8291B2" weight="700">
                                        { noCount }
                                    </Text>
                                    <Text margin="0" align="left" size="16px" color="#8291B2" weight="500">
                                        Answered &apos;No&apos;
                                    </Text>
                                </OverviewCounterDetails>
                            </div>
                        </OverviewCounterItemContainer>
                            
                    </>
                }
            </OverviewCounterContainer>
        </div>
    );
};

export default SurveyYesNoCard;