import React from "react";
import { Box, Grid, Dialog, Divider } from "@material-ui/core/";
import {
    Button, Text
} from "src/components";

const AddNewUserOptionsModal = (props) => {
    const { showDialog, handleClose, openUserDetailsForm, openUploadUserListModal } = props;

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: 900,
                        height: "auto",
                        borderRadius: 15,
                        overflow: "auto",
                    },
                }}
            >
                <Box sx={{ margin: "60px 55px 0 55px" }}>
                    <Text color="#11141A" size="2.5rem" align="center" weight="700" margin="0">
                        Add New Users
                    </Text>

                    <Text color="#000000" align="center" size="0.875rem" padding="0 5rem 1.25rem 5rem">
                        Here, you have the flexibility to either manually add users one by one – be it employees or clients. 
                        Alternatively, if you have a large number of users to integrate, save time by utilising our CSV template, specifically designed to make bulk uploads seamless and efficient.
                    </Text>
                </Box>

                <Divider />

                <Grid container align="center" style={{ padding: "1.875rem" }}>
                    <Grid item xs={6} style={{ padding: "0rem 5.875rem" }}>
                        <Text color="#11141A" size="1.2500em" weight="700" margin="0">
                                Add Single User 
                        </Text>

                        <Text color="#000000" size="0.875rem" height="2.375rem">
                                Opt to add a single user, whether an employee or client.
                        </Text>
                            
                        <Button backgroundColor="#006CFF" color="#fff"
                            border="1px solid #006CFF"
                            width="140px"
                            style={{ borderRadius: "7px", boxShadow: "none" }}
                            onClick={(e) => [handleClose(), openUserDetailsForm()] }
                        >
                                Add User
                        </Button>
                    </Grid>

                    <Grid item xs={6} style={{ padding: "0rem 3.875rem" }}>
                        <Text color="#11141A" size="1.2500em" align="center" weight="700" margin="0">
                                Upload User List
                        </Text>
                            
                        <Text color="#000000" size="0.875rem" height="2.375rem">
                                Choose to upload an entire list of employees or clients 
                                with our efficient CSV file integration.
                        </Text>

                        <Button backgroundColor="#006CFF" color="#fff"
                            border="1px solid #006CFF"
                            width="180px"
                            style={{ borderRadius: "7px", boxShadow: "none" }}
                            onClick={(e) => [handleClose(), openUploadUserListModal()]}
                        >
                                Upload CSV User List
                        </Button>
                    </Grid>
                </Grid>

                <Divider />

                <Box display="flex" justifyContent="center" margin="auto">
                    <Button 
                        border="1px solid #000"
                        color="#000"
                        marginRight="15px"
                        width="140px"
                        onClick={handleClose}
                        style={{ borderRadius: "7px", boxShadow: "none", margin: "30px" }}
                        className="form-btns disabled"
                    >
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}; 

export default AddNewUserOptionsModal;