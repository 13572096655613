import React from "react";
import styled from "styled-components";
import Skeleton from "./Skeleton";
import { desktop, smallDesktop } from "src/components/Media";
import { FlexContainer } from "src/components";
import PrintStatisticsCard from "./PrintableComponents/PrintStatisticsCard";
import { IsFeatureReady } from "src/constants/features";
import PrintableMoodRatingCard from "./PrintableComponents/PrintableMoodRatingCard";
import LogoImg2 from "../../../img/new/me_logo.svg";
import moment from "moment";
import PrintableMoodDistributionCard from "./PrintableComponents/PrintableMoodDistributionCard";


const PrintCardHeader = styled.div`
  margin: 1rem 1rem 2.5rem 0;
  width: fit-content;
  height: 1.625rem;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 2.625rem Roboto;
  font-size: 2.3rem;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
`;

const PrintContentCardRows = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill);
    margin-bottom: 2rem;
    ${smallDesktop`
        grid-template-columns: auto auto;
    `}
    ${desktop`
        grid-template-columns: min-content min-content min-content;
    `}
`;

const Hr = styled.div`
    border: 1px solid #8291B2;
    margin-bottom: 35px;
`;

function getFormattedDateRange(timePeriod) {
    let startDate, endDate;

    switch (timePeriod) {
      case "Last 7 Days":
        startDate = moment().subtract(6, "days");
        endDate = moment();
        break;

      case "Last 30 Days":
        startDate = moment().subtract(29, "days");
        endDate = moment();
        break;

      case "Last 3 Months":
        startDate = moment().subtract(3, "months").startOf("month");
        endDate = moment().subtract(1, "months").endOf("month");
        break;

      case "Last 6 Months":
        startDate = moment().subtract(6, "months").startOf("month");
        endDate = moment().subtract(1, "months").endOf("month");
        break;

      case "Last Year":
        startDate = moment().subtract(1, "year").startOf("year");
        endDate = moment().subtract(1, "year").endOf("year");
        break;

      case "Lifetime":
        startDate = moment("1970-01-01");
        endDate = moment();
        break;

      default:
        return "Invalid time period";
    }
    const sameYear = startDate.year() === endDate.year();

    if (sameYear) {
        return `${startDate.format("Do MMMM")} - ${endDate.format("Do MMMM, YYYY")}`;
    } else {
        return `${startDate.format("Do MMMM, YYYY")} - ${endDate.format("Do MMMM, YYYY")}`;
    }
}

const PrintableStatistics = (props) => {
    const { 
        companyName,
        dateRange,
        users,
        fetching, 
        totalActiveEmployees, 
        totalActiveEmployeesValue, 
        fetchingLineData, 
        activeUserGraphData,
        retentionRate,
        retentionRateValue,
        retentionRateGraphData,
        appSessions,
        appSessionsGraphData,
        appSessionsValue,
        totalPostsValue,
        postViewsValue,
        postViewsRateOfChange,
        postViewsGraphData,
        interactionRateValue,
        interactionRate,
        interactionRateGraphData,
        quoteSavedValue,
        popularCategory,
        articlesReadValue,
        recordPlaylistCountValue,
        bodyAndMindValue,
        totalTeamGoalsSet,
        totalAchievedTeamGoal,
        teamGoalsAchievedLineGraphData,
        fetchingMoodTrackerData,
        moodData,
    } = props;
    
    const pageHeadingLabel = users === "Employees" ? "Employee Statistics" : "Client Statistics";
    return (
        <div id="divToPrint" style={{ position: "fixed", zIndex: -1, width: "1920px" }}>
            <FlexContainer>
                <FlexContainer>
                    <div style={{ height: "125px", backgroundColor: "black", padding: "28px 70px", display: "flex" }}>
                        <img height="50px" src={LogoImg2} alt="Me" style={{ margin: "auto 0" }}/>
                        <span style={{ color: "#FFF", margin: "auto 0 auto 16px", fontSize: "30px", fontWeight: "300" }}>Business</span>
                    </div>
                </FlexContainer>
                <FlexContainer
                    direction="row"
                    justifyContent="space-between"
                    marginBottom="2rem"
                    marginTop="2rem"
                    marginLeft="5.938rem"
                    marginRight="5.938rem"
                >
                    <div style={{ maxWidth: "45rem" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "40px", fontFamily: "Roboto", fontWeight: "700" }}>{companyName}</div>
                            <div style={{ fontSize: "40px", fontFamily: "Roboto", fontWeight: "700" }}>{pageHeadingLabel}</div>
                        </div>
                    </div>
                    <div style={{ maxWidth: "45rem" }}>
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                            <div style={{ fontSize: "40px", fontFamily: "Roboto", fontWeight: "700", textAlign: "right" }}>{dateRange}</div>
                            <div style={{ fontSize: "30px", fontFamily: "Roboto", fontWeight: "500", textAlign: "right" }}>{getFormattedDateRange(dateRange)}</div>
                        </div>
                    </div>
                </FlexContainer>
                <Hr/>
                <div style={{ padding: "0 95px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "75% 25%" }}>
                        <div> {/* left column */}
                            <PrintCardHeader>
                                {fetching ? 
                                    <Skeleton
                                        variant="Text"
                                        width="150px"
                                        height="25px"
                                        animation="wave"
                                    />
                                    :
                                    "User Engagement"
                                }
                            </PrintCardHeader>
                            <PrintContentCardRows>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header={`Total Active ${users}`}
                                    footer={`Total number of Active ${users} within a specific period.`}
                                    loading={fetching}
                                    total={`${totalActiveEmployeesValue}`}
                                    rate={Math.round(totalActiveEmployees.rateOfChange)}
                                    loadingLineGraph={fetchingLineData}
                                    graphData={activeUserGraphData}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Retention Rate"
                                    footer={`Percentage of ${users} who continue using the app over time.`}
                                    loading={fetching}
                                    total={`${`${parseFloat(retentionRateValue).toFixed()}%`}`}
                                    rate={Math.round(retentionRate.rateOfChange)}
                                    loadingLineGraph={fetchingLineData}
                                    graphData={retentionRateGraphData}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="App Sessions"
                                    footer={`Average Sessions per ${users}.`}
                                    loading={fetching}
                                    total={`${appSessionsValue}`}
                                    rate={Math.round(appSessions.rateOfChange)}
                                    loadingLineGraph={fetchingLineData}
                                    graphData={appSessionsGraphData}
                                ></PrintStatisticsCard>
                            </PrintContentCardRows>
                            <PrintCardHeader>
                                {fetching ? 
                                    <Skeleton
                                        variant="Text"
                                        width="150px"
                                        height="25px"
                                        animation="wave"
                                    />
                                    : 
                                    "Business Noticeboard"
                                }
                            </PrintCardHeader>
                            <PrintContentCardRows>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Total Posts"
                                    footer={`Number of posts by admins on the ${users} Noticeboard.`}
                                    loading={fetching}
                                    total={`${totalPostsValue}`}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Post Impressions"
                                    footer={`Total new posts viewed by all ${users}.`}
                                    loading={fetching}
                                    total={`${postViewsValue}`}
                                    rate={postViewsRateOfChange}
                                    loadingLineGraph={fetchingLineData}
                                    graphData={postViewsGraphData}
                                ></PrintStatisticsCard>
                                {IsFeatureReady.interactionRateStat() && (
                                    <PrintStatisticsCard
                                        printFormat={true}
                                        header="Interaction Rate"
                                        footer={`How many ${users} engaged with the posted content.`}
                                        loading={fetching}
                                        total={`${interactionRateValue}`}
                                        rate={Math.round(interactionRate.rateOfChange)}
                                        loadingLineGraph={fetchingLineData}
                                        graphData={interactionRateGraphData}
                                    ></PrintStatisticsCard>
                                )}
                            </PrintContentCardRows>
                            <PrintCardHeader>
                                {fetching ? 
                                    <Skeleton
                                        variant="Text"
                                        width="150px"
                                        height="25px"
                                        animation="wave"
                                    />
                                    :
                                    "Content Engagement"
                                }
                            </PrintCardHeader>
                            <PrintContentCardRows>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Quotes Saved"
                                    footer={`Number of times ${users} have saved quotes to favourites.`}
                                    loading={fetching}
                                    total={`${quoteSavedValue}`}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Most Popular Quote Category"
                                    footer={`The most popular quote category selected by ${users}.`}
                                    loading={fetching}
                                    total={`${popularCategory}`}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Articles Read"
                                    footer={`Number of times ${users} have opened and viewed an article.`}
                                    loading={fetching}
                                    total={`${articlesReadValue}`}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Business Playlist"
                                    footer={`Number of times ${users} have selected to open your business playlists.`}
                                    loading={fetching}
                                    total={`${recordPlaylistCountValue}`}
                                ></PrintStatisticsCard>
                                <PrintStatisticsCard
                                    printFormat={true}
                                    header="Body & Mind Content Saved"
                                    footer={`Number of times ${users} have saved content in the Body & Mind feature.`}
                                    loading={fetching}
                                    total={`${bodyAndMindValue}`}
                                ></PrintStatisticsCard>
                            </PrintContentCardRows>
                            { users === "Employees" ?
                                <>
                                    <PrintCardHeader>
                                        {fetching ?
                                            <Skeleton
                                                variant="Text"
                                                width="150px"
                                                height="25px"
                                                animation="wave"
                                            />
                                            :
                                            "Team Goal Tracking"
                                        }
                                    </PrintCardHeader>
                                    <PrintContentCardRows>
                                        <PrintStatisticsCard
                                            printFormat={true}
                                            header="Team Goals Set"
                                            footer="Number of team goals set by employees."
                                            loading={fetching}
                                            total={`${totalTeamGoalsSet}`}
                                            graphData={0}
                                        ></PrintStatisticsCard>
                                        <PrintStatisticsCard
                                            printFormat={true}
                                            header="Achieved Team Goals"
                                            footer="Percentage of achieved team goals."
                                            loading={fetching}
                                            total={`${totalAchievedTeamGoal}`}
                                            rate={Math.round(retentionRate.rateOfChange)}
                                            loadingLineGraph={fetchingLineData}
                                            graphData={teamGoalsAchievedLineGraphData}
                                        ></PrintStatisticsCard>
                                    </PrintContentCardRows>
                                </>
                                : <> </>}
                            
                        </div>
                        <div> {/* right column */}
                            <PrintCardHeader>
                                {fetching ?
                                    <Skeleton
                                        variant="Text"
                                        width="150px"
                                        height="25px"
                                        animation="wave"
                                    />
                                    :
                                    `${users} Mood Tracker`
                                }
                            </PrintCardHeader>
                            <PrintContentCardRows>
                                <PrintableMoodRatingCard
                                    header="Overall Mood Rating"
                                    footer={`Overall mood rating for ${users}.`}
                                    loading={fetchingMoodTrackerData}
                                    moodData={moodData}
                                    printFormat={true}
                                />
                            </PrintContentCardRows>
                            <PrintableMoodDistributionCard
                                header="Mood Distribution"
                                footer={`Mood distribution across all ${users}.`}
                                loading={fetchingMoodTrackerData}
                                cardHeight="32.875rem"
                                cardType="Mood"
                                moodData={moodData}
                                printFormat={true}
                            />
                        </div>
                    </div>
                </div>
            </FlexContainer>
        </div>
    );
};
export default PrintableStatistics;