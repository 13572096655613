import { useState, useEffect } from "react";
/**
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @param {MatrixClient} matrixClient
 * @param activeChatLists Array
 */
export const useChatListUnread = (matrixClient, activeChatLists) => {
    const [activeChatsTotalUnread, setActiveChatsTotalUnread] = useState([]);
    const matrixRooms = matrixClient ? matrixClient.store.rooms : null;
    useEffect(() => {
        const initialize = () => {
            try {
                const chatListsUnread = () => {
                    return activeChatLists.map((activeChatList) => {
                        return {
                            label: activeChatList.label,
                            totalUnreadCount: 0
                        };
                    });
                };

                const unread = chatListsUnread();
                setActiveChatsTotalUnread(unread);
            } catch (error) {
                return console.warn("Failed to initialize Chat List unread.");
            }
        };

        initialize();
    }, [activeChatLists, matrixRooms]);

    const updateActiveChatListTotalUnread = () => {
        let updatedActiveChatsTotalUnread = activeChatsTotalUnread;

        //set totalUnreadCount back to 0
        updatedActiveChatsTotalUnread = updatedActiveChatsTotalUnread.map((obj) => {
            return { ...obj, totalUnreadCount: 0 };
        });

        //find matrixRoomId's Chat Type and update that Chat Type's Total unread from this room's unredNotificationCount
        Object.keys(matrixClient.store.rooms).forEach((roomId) => {
            const room = matrixClient.getRoom(roomId);
            const totalUnreadFromSync = room.getUnreadNotificationCount("total");
            const chatListLabel = findChatListByMatrixRoomId(roomId);
            
            if (!chatListLabel)
                return;
            
            const chatListIndex = updatedActiveChatsTotalUnread.findIndex(chatList => chatList.label === chatListLabel);

            updatedActiveChatsTotalUnread = updatedActiveChatsTotalUnread.map((obj, index) => {
                if (index === chatListIndex) { 
                    return { ...obj, totalUnreadCount: obj.totalUnreadCount + totalUnreadFromSync };
                }
                return obj; // Keep other objects unchanged
            });
        });

        setActiveChatsTotalUnread(updatedActiveChatsTotalUnread);
    };

    const findChatListByMatrixRoomId = (matrixRoomId) => {
        if (!activeChatLists || !matrixRoomId) {
            console.warn("Empty chat list or roomId", activeChatLists, matrixRoomId);
            return null;
        }
      
        // Iterate through each object in the data
        for (const chatList of Object.values(activeChatLists)) {
            // Check if the object has a "data.chats" property
            if (chatList.data && chatList.data.chats) {
                // Search for the chat with matching matrixRoomId
                const matchingChat = chatList.data.chats.find(chat => chat.matrixRoomId === matrixRoomId);
                
                //console.warn("matchingChat", matchingChat, chatList.data.chats, matrixRoomId, chatList.label);
                if (matchingChat) {
                    return chatList.label; // Return the chatList's label if a match is found
                }
            }
        }
        
        return null; // Return null if no matching chatList is found
    };

    return {
        updateActiveChatListTotalUnread,
        activeChatsTotalUnread
    };
};