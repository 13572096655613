import React from "react";
import styled from "styled-components";
import { PageHeadingLabel, FlexContainer
} from "src/components";
import { TableType } from "src/constants/survey";


const InputSelector = styled.select`
    background: #51315D;
    border:0;
    text-decoration: none;
    color: white;
    padding: auto 23px;
`;


const SurveyAndPollsHeading = (props) => {
    const { handleChangeTableType, selectedTableType } = props;
    return (
        <FlexContainer justifyContent="space-between !important" mDirection="row" tDirection="row" direction="row">
            <div>
                <PageHeadingLabel size="2.5rem" margin="0 0 0 0">Surveys & Polls</PageHeadingLabel>
                <div className="row" style={{ maxWidth: "37.5rem", marginTop: "13px", marginBottom: "33px" }}>
                    <div className="description">
                    Effortlessly create short and effective surveys or polls. Send them directly to your employees&apos; or clients&apos; Me Business app. Get instant feedback and data in real-time, staying informed and in control of your survey and poll campaigns&apos; performance.
                    </div>
                </div>
            </div>
            <FlexContainer margin="2rem 4rem 0.5rem 0" justifyContent="center" mDirection="row" tDirection="row" direction="row" >
                <div className="row" style={{ margin: "20px 0 20px 23px" }}>
                    <div className="field" style={{ display: "flex" }}>
                        <label style={{ marginTop: "auto", marginBottom: "auto" }}>Select Surveys or Polls:</label>
                        <InputSelector style={{ height: "38px", width: "200px", paddingLeft: "23px", fontSize: "14px", margin: "auto" }} value={selectedTableType} onChange={(e) => handleChangeTableType(e.target.value)} >
                            <option value={TableType.Polls} style={{ fontSize: "1rem" }}>
                                Polls
                            </option>
                            <option value={TableType.Surveys} style={{ fontSize: "1rem" }}>
                                Surveys
                            </option>
                        </InputSelector>
                    </div>
                </div>
            </FlexContainer>
        </FlexContainer>
    );
};


export default SurveyAndPollsHeading;