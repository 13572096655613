import React, { useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    Toast, FlexContainer, Text, PageContainer,
    LottieLoadingIndicator,
    PageHeadingLabel
} from "src/components";
import { SurveyInfo } from "./SurveyInsightsComponents/SurveyInfo";
import SurveyQuestions from "./SurveyInsightsComponents/SurveyQuestions";
import { FormBackground, StyledButton } from "./Utils";
import { SurveyOverview } from "./SurveyInsightsComponents/SurveyOverview";
import SurveyUserSubmissionsModal from "../components/SurveyUserSubmissions";
import { SurveyStatusType } from "src/constants/survey";

const SurveyInsightPage = (props) => {
    const { showInsights, user, surveyUuid } = props;
    const [fetchingSurvey, setFetchingSurvey] = useState(true);
    const [fetchingInsight, setFetchingInsight] = useState(true);
    const [fetchingResponseStats, setFetchingResponseStats] = useState(true);
    const [isShowViewUserSubmissions, setIsShowViewUserSubmissions] = useState(false);
    const [survey, setSurvey] = useState({
        name: "...",
        surveyType: "...",
        startDate: "",
        finishDate: "",
        userGroup: "..."
    });
    
    const [insights, setInsights] = useState({
        numberOfUsersInTargetGroup: "...",
        numberOfUsersPromptedForSurvey: "...",
        numberOfTotalSurveysSubmitted: "...",
        surveyQuestions: [{}]
    });
    
    const [responsesStats, setResponsesStats] = useState({});

    useEffect(() => {
        safeToInit();
    }, []);

    useEffect(() => {
        if (survey.status === SurveyStatusType.ACTIVE) {
            const interval = setInterval(realTimeResponseStats, 10000);
            return () => clearInterval(interval);
        }
    }, [survey]);

    const safeToInit = () => {
        populateInsights();
        populateSurvey();
        populateResponseStats();
    };

    const populateSurvey = () => {
        let hasError = true;
    
        axios.get(`${API_URL}/survey/company/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(res => {
            setSurvey(res.data.survey);
            hasError = false;
        }).finally(() => {
            setFetchingSurvey(false);
            if (hasError) {
                Toast.error("An error occurred while fetching survey data.");
            }
        }).catch(error => {
            if (!error.response) {
                Toast.error(error.message);
            } else {
                Toast.error(error.response.data.error);
            }
        });
    };
    
    const populateInsights = () => {
        let hasError = true;
    
        axios.get(`${API_URL}/survey/company/insights/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(res => {
            setInsights(res.data);
            hasError = false;
        }).finally(() => {
            if (fetchingInsight) {
                // only relevant on first load of page
                setFetchingInsight(false);
            }
            if (hasError) {
                Toast.error("An error occurred while fetching insights.");
            }
        }).catch(error => {
            if (!error.response) {
                Toast.error(error.message);
            } else {
                Toast.error(error.response.data.error);
            }
        });
    };
    
    const populateResponseStats = () => {
        let hasError = true;
    
        axios.get(`${API_URL}/survey/company/responsesStats/${surveyUuid}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(res => {
            if (!ifResponseStatsAreEqual(responsesStats, res.data)) {
                setResponsesStats(res.data);
            }
            hasError = false;
        }).finally(() => {
            if (fetchingResponseStats) {
                // only relevant on first load for the page
                setFetchingResponseStats(false);
            }
            if (hasError) {
                Toast.error("An error occurred while fetching response statistics.");
            }
        }).catch(error => {
            if (!error.response) {
                Toast.error(error.message);
            } else {
                Toast.error(error.response.data.error);
            }
        });
    };

    const ifResponseStatsAreEqual = (objectA, objectB) => {
        if (objectA.length !== objectB.length) return false;
    
        return objectA.every((itemA, index) => {
            const itemB = objectB[index];
            return (
                itemA.surveyQuestionUuid === itemB.surveyQuestionUuid &&
                itemA.answerChosen === itemB.answerChosen
            );
        });
    };
    
    const realTimeResponseStats = () => {
        populateResponseStats();
        populateInsights();
    };
    
    const showSurveyUserSubmissionsModal = (surveyUuid) => {
        setIsShowViewUserSubmissions(true);
        setSurvey(prevSurvey => ({ ...prevSurvey, surveyUuid }));
    };
    
    const hideSurveyUserSubmissionsModal = () => {
        setIsShowViewUserSubmissions(false);
    };

    return (
        <PageContainer>
            <PageHeadingLabel color="#23262D" size="40px" weight="500" align="left" margin="1em 0 0 0">
                Survey Insights
            </PageHeadingLabel>
            <div style={{ maxWidth: "94.5rem" }}>
                <FlexContainer direction="row" justifyContent="space-between" marginBottom="27px">
                    <Text color="#808080" size="14px" align="justify" maxWidth="44.5rem">
                        Unlock a comprehensive overview of your survey results with our Survey Insights. Tailored for admin users, this feature provides a visual representation of survey data, helping you decipher trends, feedback, and patterns at a glance. Analyse, understand, and make informed decisions with precision, all within one intuitive interface.
                    </Text>
                    <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "150px", height: "40px", borderRadius: "7px", weight: "bold", marginBlockStart: "auto" }}
                        onClick={() => { showInsights(false); }} >
                        Close
                    </StyledButton>
                </FlexContainer>
            </div>

            <FormBackground style={{ padding: "2.5rem", width: "100%", overflowX: "auto" }}>
                { fetchingInsight && <LottieLoadingIndicator />}

                { !fetchingInsight &&
                    <div style={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "nowrap", overflowX: "auto" }}>
                        <div style={{ width: "17rem", textAlign: "left" }}>
                            <SurveyOverview
                                showInsights={showInsights}
                                survey={survey}
                                insights={insights}
                                surveyUuid={survey.surveyUuid}
                                showSurveyUserSubmissions={showSurveyUserSubmissionsModal}
                            />
                        </div>
                        <div style={{ borderLeft: "1px solid #EFEFEF", marginLeft: "1.5rem", paddingLeft: "1.5rem", marginBottom: "2rem" }}>
                            <SurveyInfo showInsights={showInsights} survey={survey} fetchingSurvey={fetchingSurvey} />
                            {!fetchingResponseStats && <SurveyQuestions
                                survey={survey}
                                insights={insights}
                                surveyType={survey.surveyType}
                                responsesStats={responsesStats}
                                fetchingResponseStats={fetchingResponseStats}
                                user={user}
                                realTimeResponseStats={realTimeResponseStats}
                            />}
                        </div>
                    </div>
                }
            </FormBackground>
            
            { isShowViewUserSubmissions &&
                <SurveyUserSubmissionsModal 
                    showDialog={isShowViewUserSubmissions}
                    handleClose={hideSurveyUserSubmissionsModal}
                    insights={insights}
                    survey={survey}
                />
            }

        </PageContainer>
    );
};

export default SurveyInsightPage;